import React, { useMemo } from "react";
import { Layout } from "antd";
import { toAbsoluteUrl } from "../../_helpers";
import { Footer } from "./footer/Footer";
import { AnimateLoading } from "../../_partials/controls";
import { useHtmlClassService } from "../_core/Layout";
import objectPath from "object-path";
import { SubHeader } from "./subheader/SubHeader";
import { Link } from "react-router-dom";

const { Header, Content } = Layout;

const PublicLayout = ({ children }) => {
  const uiService = useHtmlClassService();
  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, "self.layout"),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
      desktopHeaderDisplay: objectPath.get(
        uiService.config,
        "header.self.fixed.desktop"
      ),
      contentCssClasses: uiService.getClasses("content", true),
      contentContainerClasses: uiService.getClasses("content_container", true),
      contentExtended: objectPath.get(uiService.config, "content.extended"),
    };
  }, [uiService]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          background: "#FFFFFF", // Default Ant Design header background
          display: "flex",
          justifyContent: "space-between", // Space between logo and any potential right-side content
          alignItems: "center",
          height: 64, // Default Ant Design header height
          padding: "0 16px",
        }}
      >
        <Link to="">
          <img
            alt="logo"
            src={toAbsoluteUrl("/media/logos/bluebix-logo.png")}
            style={{ width: "180px" }}
          />
        </Link>
      </Header>

      {!layoutProps.contentExtended && <div>{children}</div>}
      {layoutProps.contentExtended && { children }}
      <Footer />
      <AnimateLoading />
    </Layout>
  );
};

export default PublicLayout;
