import React from 'react';
import MonsterSearch from '../../../../monster';

export default function MonsterCandidates({ type = 'admin' }) {
  return (
    <div>
      <MonsterSearch type={type} />
    </div>
  );
}
