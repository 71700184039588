import React from "react";
import { Link } from "react-router-dom";

// Routing configuration based on role
const getRoutePaths = (role) => {
  const baseRoutes = {
    admin: {
      path1: "/admin/ClientsList",
      path2: "/current-openings",
      path3: "/user/list/all",
      path4: "/user/list/active",
    },
    company: {
      path1: "/company/job-openings",
      path2: "/company/job-openings",
      path3: "/company/job-openings",
      path4: "/company/job-openings",
    },
    candidate: {
      path1: "/candidate/assign-jobs",
      path2: "/candidate/userWiseCandidateSubmission/all",
      path3: "/candidate/userWiseCandidateSubmission/submit",
      path4: "/candidate/userWiseCandidateSubmission/interview",
    },
    bdm: {
      path1: "/bdm/assign-jobs",
      path2: "/bdm/userWiseCandidateSubmission/all",
      path3: "/bdm/userWiseCandidateSubmission/submit",
      path4: "/bdm/userWiseCandidateSubmission/interview",
    },
    recruiter: {
      path1: "/recruiter/assign-jobs",
      path2: "/recruiter/userWiseCandidateSubmission/all",
      path3: "/recruiter/userWiseCandidateSubmission/submit",
      path4: "/recruiter/userWiseCandidateSubmission/interview",
    },
  };

  return baseRoutes[role] || baseRoutes.admin;
};

// Color configurations for different stat blocks
const COLOR_CONFIGS = [
  { bgColor: "bg-warning", textColor: "text-light" },
  { bgColor: "bg-primary", textColor: "text-light" },
  { bgColor: "bg-danger", textColor: "text-light" },
  { bgColor: "bg-success", textColor: "text-light" },
];

export const MixedWidget15 = React.memo(
  ({ className = "", role, dashboard_statistics_count = [] }) => {
    // Get route paths based on user role
    // const { path1, path2, path3, path4 } = getRoutePaths(role);
    const allPaths = getRoutePaths(role);

    // Render stat block
    // const renderStatBlock = (item, index) => {
    //   if (!item) return null;

    //   const { bgColor, textColor } = COLOR_CONFIGS[index] || COLOR_CONFIGS[0];

    //   return (
    //     <div
    //       key={index}
    //       className={`col  ${bgColor} px-6 py-8 rounded-xl ${
    //         index % 2 === 0 ? "mr-7" : "mr-7"
    //       } mb-7`}
    //       style={{
    //         width: "50%",
    //         minWidth: "200px",
    //         maxWidth: "50%",
    //       }}
    //     >
    //       <span className={`svg-icon svg-icon-3x ${textColor} d-block my-2`}>
    //         <span className={`${textColor} font-size-h2`}>
    //           {item.count || "N/A"}
    //         </span>
    //       </span>
    //       <Link
    //         className={`${textColor} font-weight-bold font-size-h5`}
    //         to={allPaths[`path${index + 1}`] || "#"}
    //       >
    //         {item.title || "Stat"}
    //       </Link>
    //     </div>
    //   );
    // };
    const renderStatBlock = (item, index) => {
      if (!item) return null;

      const { bgColor, textColor } = COLOR_CONFIGS[index] || COLOR_CONFIGS[0];

      return (
        <div key={index} className={`stat-card ${bgColor} ${textColor}`}>
          <div className="stat-card-circle stat-card-circle-1" />
          <div className="stat-card-circle stat-card-circle-2" />

          <span className="stat-card-icon">
            <span className="stat-card-count">{item.count || "N/A"}</span>
          </span>
          <Link
            className="stat-card-link"
            to={allPaths[`path${index + 1}`] || "#"}
          >
            {item.title || "Stat"}
          </Link>
        </div>
      );
    };

    return (
      <div className={`card card-custom bg-gray-100 ${className}`}>
        {/* Header */}
        <div className="card-header border-0 bg-danger py-5">
          <h3 className="card-title font-weight-bolder text-white">My Stats</h3>
        </div>

        <div className="card-body p-0 position-relative overflow-hidden">
          <div className="card-spacer stat-card-container">
            {/* 
            it will render dynamic stats block based on the data provided from the backend
            */}
            {dashboard_statistics_count.map((item, index) => {
              return renderStatBlock(item, index);
            })}
          </div>
        </div>
      </div>
    );
  }
);
