import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { Table, Avatar, Typography, Button, Drawer, Switch, Space } from 'antd';
import { EyeOutlined, GlobalOutlined } from '@ant-design/icons';
import ExpandableContent from '../../../../../../../others/utils/ExpandableContent';


const LinkedinCandidateTable = ({ data, onViewDetails, pagination }) => {
  const defaultColumns = [
    'first_name', 'role', 'current_location', 'total_work_exp_year', 'meta', 'profile_summary', 'candidate_qualifications', 'employees', 'action'
  ];

  const [visibleColumns, setVisibleColumns] = useState(() => {
    const savedColumns = localStorage.getItem('visibleColumns');
    return savedColumns ? JSON.parse(savedColumns) : defaultColumns;
  });
  const [isColumnDrawerVisible, setIsColumnDrawerVisible] = useState(false);
  useEffect(() => {
    const ignoreResizeObserverErrors = () => {
      const resizeObserverError = Error('ResizeObserver loop limit exceeded');
      
      const originalError = window.console.error;
      window.console.error = (...args) => {
        if (args[0] === resizeObserverError.toString()) return;
        originalError.call(window.console, ...args);
      };
      
      return () => {
        window.console.error = originalError;
      };
    };

    return ignoreResizeObserverErrors();
  }, []);

  useEffect(() => {
    localStorage.setItem('visibleColumns', JSON.stringify(visibleColumns));
  }, [visibleColumns]);


  const debouncedColumnToggle = useCallback(
    debounce((column, prevColumns) => {
      const newColumns = prevColumns.includes(column)
        ? prevColumns.filter((col) => col !== column)
        : [...prevColumns, column];
      
      setVisibleColumns(newColumns);
      localStorage.setItem('visibleColumns', JSON.stringify(newColumns));
    }, 100), 
    []
  );

  const handleColumnToggle = (column) => {
    debouncedColumnToggle(column, visibleColumns);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'first_name',
      key: 'first_name',
      width: 150,
      fixed: 'left', 
      render: (text, record) => (
        <Space>
          <Avatar 
            src={record?.profile_image} // Add this line to show profile image
            style={{ backgroundColor: '#ed7206', marginRight: '8px' }}
          >
            {!record?.profile_image && (record?.first_name?.charAt(0)?.toUpperCase() || '?')}
          </Avatar>
          <div 
            onClick={() => onViewDetails(record)} 
            style={{ cursor: 'pointer' }}
          >
            {`${record?.first_name || ''} ${record?.last_name || ''}`}
          </div>
          {record?.meta?.linkedin && (
            <Typography.Link
              href={record?.meta?.linkedin}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <GlobalOutlined style={{ fontSize: "16px", color: "#0077B5" }} />
            </Typography.Link>
          )}
        </Space>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: 200,
      render: (text) => (
        <ExpandableContent
          content={text || '-'}
          maxHeight={24}
          showMoreText="more"
          showLessText="less"
        />
      ),
    },
    {
      title: 'Location',
      dataIndex: 'current_location',
      key: 'current_location',
      width: 150,
      render: (text) => (
        <ExpandableContent
          content={text || '-'}
          maxHeight={24}
          showMoreText="more"
          showLessText="less"
        />
      ),
    },
    {
      title: 'Experience',
      dataIndex: 'total_work_exp_year',
      key: 'total_work_exp_year',
      width: 120,
      render: (text, record) => (
        <ExpandableContent
          content={`${record?.total_work_exp_year || 0} Year(s) ${record?.total_work_exp_month || 0} Month(s)`}
          maxHeight={24}
          showMoreText="more"
          showLessText="less"
        />
      ),
    },
    {
      title: 'LinkedIn',
      dataIndex: 'meta',
      key: 'meta',
      width: 100,
      render: (text) => text?.['Linkedin Connections'] || '-',
    },
    {
      title: 'Summary',
      dataIndex: 'profile_summary',
      key: 'profile_summary',
      width: 250,
      render: (text) => (
        <ExpandableContent
          content={text || '-'}
          maxHeight={48}
          maxCharacters={100}
          showMoreText="more"
          showLessText="less"
        />
      ),
    },
    {
      title: 'Qualifications',
      dataIndex: 'candidate_qualifications',
      key: 'candidate_qualifications',
      width: 250,
      render: (qualifications) => (
        <ExpandableContent
          content={
            qualifications?.map(qual => 
              `${qual?.course || ''} ${qual?.university ? `from ${qual.university}` : ''} ${qual?.passing_year ? `(${qual.passing_year})` : ''}`
            ).join('; ') || '-'
          }
          maxHeight={48}
          maxCharacters={100}
          showMoreText="more"
          showLessText="less"
        />
      ),
    },
    {
      title: 'Employment',
      dataIndex: 'employees',
      key: 'employees',
      width: 250,
      render: (employees) => (
        <ExpandableContent
          content={
            employees?.map(job => 
              `${job?.designation || ''} at ${job?.organization || ''} ${job?.location ? `(${job.location})` : ''}`
            ).join('; ') || '-'
          }
          maxHeight={48}
          maxCharacters={100}
          showMoreText="more"
          showLessText="less"
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: 250, 
     
      style: { whiteSpace: 'nowrap', width:'240px' }, 
      render: (text, record) => (
        <div 
  
        > 
          <Button
            type="primary"
            icon={<EyeOutlined />}
            onClick={(e) => {
              e.stopPropagation(); 
              onViewDetails(record);
            }}
            size="middle" 
          >
            View
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Button onClick={() => setIsColumnDrawerVisible(true)}>Toggle Columns</Button>
      <Table 
      
        columns={columns.filter(column => visibleColumns.includes(column.key))} 
        dataSource={data} 
        rowKey="_id" 
        scroll={{ x: true }}
        pagination={pagination}
        size="middle"
        sticky={{ offsetHeader: 64 }} 
        tableLayout="fixed"
        className="smooth-table-transitions"
      />
      <style>
        {`
          .smooth-table-transitions {
            transition: all 0.2s ease-in-out;
          }
          .smooth-table-transitions .ant-table-cell {
            transition: width 0.2s ease-in-out, padding 0.2s ease-in-out !important;
          }
          .smooth-table-transitions .ant-table-row {
            transition: all 0.2s ease-in-out;
          }
          .ant-table-wrapper table {
            table-layout: fixed;
          }
          .ant-table-wrapper .ant-table-body {
            overflow-x: auto;
          }
        `}
      </style>
      <Drawer
        title="Toggle Columns"
        placement="right"
        onClose={() => setIsColumnDrawerVisible(false)}
        visible={isColumnDrawerVisible}
      >
        {columns.map((column) => (
          <div key={column.key} style={{ marginBottom: '8px' }}>
            <Typography.Text>{column.title}</Typography.Text>
            <Switch
              checked={visibleColumns.includes(column.key)}
              onChange={() => handleColumnToggle(column.key)}
              style={{ marginLeft: '8px' }}
            />
          </div>
        ))}
      </Drawer>
    </>
  );
};

export default LinkedinCandidateTable;