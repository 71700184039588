import { Button } from "antd";
import React from "react";
import SVG from "react-inlinesvg";
import { Link, NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";

export const AdvanceTablesWidget6 = React.memo(
  ({ className, role, latest_five_candidate }) => {
    const renderCandidates = () => {
      const createNewClientPath =
        role === "admin"
          ? "admin"
          : role === "recruitmentmanager"
          ? "recruitment-manager"
          : role === "bdmmanager"
          ? "bdm-manager"
          : role;
      return latest_five_candidate
        ? latest_five_candidate.map((item, index) => (
            <tr key={index.toString()} className="candidate-row">
              <td className="candidate-number">{index + 1}</td>
              <td className="candidate-info">
                <div className="candidate-details">
                  <div className="candidate-avatar">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}
                    />
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: `/${createNewClientPath}/candidateProfileDetail`,
                        state: { item },
                      }}
                      className="candidate-name"
                    >
                      {item?.name?.toUpperCase()}
                    </Link>
                  </div>
                </div>
              </td>
              <td className="candidate-job-title">
                {item?.desired_job_type || "-"}
              </td>
              <td className="candidate-ctc">
                $
                {item.current_ctc
                  ? item.current_ctc > 1000
                    ? `${item.current_ctc / 1000}K`
                    : item.current_ctc
                  : "0.00"}
              </td>
              <td className="candidate-location">
                {item?.desired_location || "-"}
              </td>
              <td className="candidate-status">
                <span
                  className={`status-badge ${item.status?.toLowerCase() ||
                    "default"}`}
                >
                  {item.status || "N/A"}
                </span>
              </td>
              <td className="candidate-actions">
                <NavLink
                  to={{
                    pathname: `/${createNewClientPath}/candidateProfileDetail`,
                    state: { item },
                  }}
                >
                  <Button type="primary" className="view-profile-btn">
                    View Profile
                  </Button>
                </NavLink>
              </td>
            </tr>
          ))
        : null;
    };

    return (
      <div className={`card card-custom ${className}`}>
        <div className="card-header">
          <h3 className="card-title">
            {["bdm", "recruiter"].includes(role)
              ? "Latest Profile Submitted"
              : "Latest Profiles"}
          </h3>
          {["company"].includes(role) && (
            <div className="card-toolbar">
              <NavLink to="/company/add-opening">
                <Button type="primary" className="post-job-btn">
                  Post New Job
                </Button>
              </NavLink>
            </div>
          )}
        </div>
        <div className="card-body">
          <div className="table-container">
            <table className="styled-table">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Candidate Name</th>
                  <th>Job Title</th>
                  <th>Current CTC</th>
                  <th>Location</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{renderCandidates()}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
);
