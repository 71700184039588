import { Button, Col, Input, Row, Select } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { toAbsoluteUrl } from "../../../_theme_parts/_helpers";
import { CANDIDATE_CITY_LIST } from "../../../ApiUrl";
import { Link } from "react-router-dom";
const { Option } = Select;

const PublicJobSearchPage = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const [state, setState] = useState({
    locationList: [],
    location: [],
    title: "",
    companyId: searchParams.get("company_id") || "",
    loading: false,
    parameters: "",
  });

  const getLocationList = () => {
    axios.get(CANDIDATE_CITY_LIST).then((res) => {
      setState((prevState) => ({
        ...prevState,
        locationList: res.data.data.city,
      }));
    });
  };

  const handleLocationChange = (value) => {
    setState((prevState) => ({ ...prevState, location: value }));
  };

  const handleTitle = (value) => {
    setState((prevState) => ({ ...prevState, title: value }));
  };

  useEffect(() => {
    getLocationList();
  }, []);

  const queryParameters = new URLSearchParams();
  useEffect(() => {
    if (state.title) {
      queryParameters.set("search", state.title);
    } else {
      queryParameters.delete("search");
    }
    if (state.location.length) {
      state.location.forEach((location) => {
        queryParameters.append("location", location);
      });
    } else {
      queryParameters.delete("location");
    }
    if (state.companyId) {
      queryParameters.append("company_id", state.companyId);
    } else {
      queryParameters.delete("company_id");
    }
    setState((prevState) => ({
      ...prevState,
      parameters: queryParameters.toString(),
    }));
  }, [state.title, state.location, state.companyId]);
  return (
    <div className="jobSearchInputContainer">
      <Input
        value={state.title}
        onChange={(e) => handleTitle(e.target.value)}
        placeholder="Skills, Designations"
        className="jobSearchInput"
        size="large"
      />
      <Select
        showSearch
        size="large"
        mode="multiple"
        value={state.location}
        onChange={handleLocationChange}
        style={{ width: "100%" }}
        placeholder="Select Location"
        filterOption={(input, option) =>
          option?.children.toLowerCase().includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
      >
        {state.locationList.map((location, index) => (
          <Option key={index.toString()} value={location.code}>
            {location.city}
          </Option>
        ))}
      </Select>
      <Button size="large" type="primary" style={{ width: "100%" }}>
        <Link
          to={{
            pathname: "/openings",
            search: `?${state.parameters}`,
          }}
        >
          Search
        </Link>
      </Button>
    </div>
  );
};

export default PublicJobSearchPage;
