import React, { useState } from 'react'
import CandidateCard from './CandidateCard'
import { Card, Checkbox, Select, Row, Spin, Typography } from 'antd'


const { Text } = Typography;
export default function CandidatesList({ results = [], loading, sortOption, onSortChange }) {
  // const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState([]);

  if (!Array.isArray(results)) {
    console.error('Results is not an array:', results);
    return null;
  }
  const sortingOptions = [
    { label: 'Relevancy', value: '-RELV' },
    { label: 'Activity', value: '-MODIFIEDINT' },
  ];
  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };
  const onCheckAllChange = (e) => {
    if (e.target.checked) {
      setSelectedCandidates(results.map(candidate => candidate.ResumeID[0]));
    } else {
      setSelectedCandidates([]);
    }
  };
  const onCandidateChange = (resumeId, checked) => {
    if (checked) {
      setSelectedCandidates([...selectedCandidates, resumeId]);
    } else {
      setSelectedCandidates(selectedCandidates.filter(id => id !== resumeId));
    }
  };

  return (
    // <Spin spinning={loading}>
    <Card extra={
      <Row align="middle" gutter={8}>
        {/* <Checkbox
          indeterminate={selectedCandidates.length > 0 && selectedCandidates.length < results.length}
          onChange={onCheckAllChange}
          checked={selectedCandidates.length === results.length}
        >
          Select All
        </Checkbox> */}
        <Select
          value={sortOption}
          onChange={onSortChange}
          options={sortingOptions}
          style={{ width: 120 }}
        />
      </Row>
    }>
      {selectedCandidates.length > 1 && (
        <div>
          {/* Banner content for selected candidates */}
          <Text strong>{selectedCandidates.length} candidates selected</Text>
        </div>
      )}
      {
        !loading && results.map((candidate, index) => (
          <Row key={candidate?.ResumeID?.[0] || index}>
            <CandidateCard
              candidate={candidate}
              checked={selectedCandidates.includes(candidate.ResumeID[0])}
              onChange={checked => onCandidateChange(candidate.ResumeID[0], checked)}
            />
          </Row>
        ))
      }
    </Card>
    // </Spin>
  )
}
