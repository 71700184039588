import React, { useState } from 'react'
import { Card, Checkbox, Button, Space, Typography, Dropdown, Menu, Row, Col, Badge, Skeleton } from 'antd'
import { HeartOutlined, MoreOutlined } from '@ant-design/icons'
import CandidateDetails from './CandidateDetails'

const { Text } = Typography
const CheckboxGroup = Checkbox.Group;

export default function CandidateCard({ candidate, checked, onChange }) {
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  if (!candidate) return null;
  //making utils to convert data to days ago format
  //   LastActivity
  //   :
  //   ["10/14/2024 10:42:41 AM"]
  //   0
  // :
  //   "10/14/2024 10:42:41 AM"

  const formatTimeAgo = (date) => {
    if (!date) return 'N/A';
    const now = new Date();
    const then = new Date(date);
    const diff = now - then;

    // Convert to different units
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const weeks = days / 7;
    const months = days / 30;
    const years = days / 365;

    // Return appropriate format based on time difference
    if (days === 0) return 'Today';
    if (days === 1) return 'Yesterday';

    // Handle days (for < 7 days)
    if (days < 7) return `${days}d ago`;

    // Handle weeks (for < 4 weeks)
    if (weeks < 4) {
      return `${Math.round(weeks)}w ago`;
    }

    // Handle months (for < 12 months)
    if (months < 12) {
      return `${Math.round(months)}m ago`;
    }

    // Handle years
    return `${Math.round(years)}y ago`;
  }
  const {
    ContactName = [''],
    HomeLocation = [''],
    JobTitle = [''],
    RecentEmployer = [''],
    ResumeID = [''],
    LastActivity = [''],
    skills = []
  } = candidate ?? {};

  const moreMenu = (
    <Menu items={[
      { key: '1', label: 'Forward' },
      { key: '2', label: 'Save to List' },
      { key: '3', label: 'Download' },
      { key: '4', label: 'Add Note' },
      { key: '5', label: 'Hide' },
      { key: '6', label: 'Report Profile' },
    ]} />
  )

  return (
    <Card
      className="candidate-card"
      bodyStyle={{ padding: '16px' }}
      bordered={false}
    >
      <Row justify="space-between" align="top" 
      gutter={[16, 0]}
      >
        <Col flex="auto">
          <Row gutter={[12, 12]}>
            {/* <Col> */}
              {/* <Checkbox
                checked={checked}
                onChange={(e) => onChange(e.target.checked)}
              /> */}
            {/* </Col> */}
            <Col flex="auto">
              <Row justify="space-between" align="middle">
                <Col>
                  <Space direction="vertical" size={0}>
                    <Space>
                      <Text strong>{ContactName?.[0] ?? 'N/A'}</Text>
                      <Badge
                        size='small'
                        status='default'
                        count={'active ' + formatTimeAgo(LastActivity?.[0])}
                      />
                    </Space>
                    <Text>
                      {HomeLocation?.[0] ?? 'N/A'} • {JobTitle?.[0] ?? 'N/A'} at {RecentEmployer?.[0] ?? 'N/A'}
                    </Text>
                  </Space>
                </Col>
                {/* <Col>
                  <Space> */}
                    {/* <Button type="text" icon={<HeartOutlined />} /> */}
                    {/* <Dropdown overlay={moreMenu} trigger={['click']} placement="bottomRight">
                      <Button type="text" icon={<MoreOutlined />} />
                    </Dropdown> */}
                  {/* </Space>
                </Col> */}
              </Row>
              <Row gutter={[8, 8]} style={{ marginTop: 12 }}>
                {/* {skills.slice(0, 5).map((skill) => (
                  <Col key={skill}>
                    <Tag>{skill}</Tag>
                  </Col>
                ))}
                {skills.length - 5 > 0 && (
                  <Col>
                    <Tag>+{skills.length - 5} more</Tag>
                  </Col>
                )} */}
              </Row>
              {candidate?.ActionType[0] === "VIEWED" && (
                <Text type="secondary" style={{ marginTop: 8, display: 'block' }}>
                  Viewed by you
                </Text>
              )}
            </Col>
          </Row>
        </Col>
        {/* <Col > */}
        <Col>
            {/* <Button type="primary">Message</Button> */}
            <Button onClick={() => setDrawerVisible(true)}>View Details</Button>
            <CandidateDetails
              resumeId={ResumeID?.[0]}
              visible={drawerVisible}
              onClose={() => setDrawerVisible(false)}
            />
            {loading && <Skeleton active />}
          </Col>
        {/* </Col> */}
      </Row>
    </Card>
  )
}