import React, { useState, useEffect } from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Card, Button, Empty } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { CANDIDATE_RESUME_URL } from '../../../../ApiUrl';

const DocumentViewer = ({ attachments }) => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (attachments) {
      setDocuments([
        {
        uri: CANDIDATE_RESUME_URL + attachments,
        fileType: attachments.split('.').pop().toLowerCase()
      },
    // {
    //   uri:'https://calibre-ebook.com/downloads/demos/demo.docx',
    //   fileType:'docx'
    // },
    // {
    //   uri:'https://www.sample-videos.com/doc/Sample-doc-file-100kb.doc',
    //   fileType:'doc'
    // }
  ]);
    }
  }, [attachments]);

  if (!attachments) {
    return (
      <Card style={{ margin: "0 auto", width: "100%", height: '100vh' }}>
        <Empty description="No attachments available" />
      </Card>
    );
  }

  return (
    <Card
      extra={
        <Button 
          icon={<DownloadOutlined />} 
          type="primary"
          onClick={() => {
            if (documents.length > 0) {
              const link = document.createElement('a');
              link.href = documents[0].uri;
              link.download = documents[0].uri.split('/').pop() || 'document';
              link.click();
            }
          }}
        >
          Download
        </Button>
      }
      style={{ margin: "0 auto", width: "100%", height: '100vh' }}
    >
      <div style={{ height: 'calc(90vh - 110px)' }}> 
        <DocViewer 
          documents={documents}
          pluginRenderers={DocViewerRenderers} 
          style={{ height: '100%', width: '100%' }}
        />
      </div>
    </Card>
  );
};

export default DocumentViewer;

