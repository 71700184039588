import { Typography } from "antd";
import React, { useLayoutEffect, useRef, useState } from "react";

// Memoize the component to prevent unnecessary re-renders
const ExpandableContent = React.memo(function ExpandableContent({
  content,
  maxHeight = 48,
  maxCharacters = 100,
  showMoreText = "Show more",
  showLessText = "Show less",
}) {
  const [showButton, setShowButton] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef(null);

  useLayoutEffect(() => {
    if (contentRef.current) {
      const shouldShowButton =
        contentRef.current.scrollHeight > maxHeight ||
        (typeof content === "string" && content.length > maxCharacters);
      setShowButton(shouldShowButton);
    }
  }, [content, maxHeight, maxCharacters]);

  if (!content) {
    return (
      <Typography.Paragraph style={{ color: "grey" }}>
        Empty
      </Typography.Paragraph>
    );
  }

  const renderContent = () => {
    if (typeof content === "string") {
      if (!isExpanded && content.length > maxCharacters) {
        return `${content.slice(0, maxCharacters)}...`;
      }
      return content;
    }
    return content;
  };

  return (
    <div>
      <div
        ref={contentRef}
        style={{
          maxHeight: isExpanded ? "none" : `${maxHeight}px`,
          overflow: "hidden",
          transition: "max-height 0.3s ease-out",
        }}
      >
        {renderContent()}
      </div>
      {showButton && (
        <Typography.Link onClick={() => setIsExpanded(prev => !prev)}>
          {isExpanded ? showLessText : showMoreText}
        </Typography.Link>
      )}
    </div>
  );
});

export default ExpandableContent;