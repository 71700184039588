import React, { useState, useEffect } from 'react';
import { Input, Button, Skeleton, Progress, message, Drawer, Row, Col, Avatar, Tag, Timeline, Card, Space, Typography, Empty, Switch, Pagination } from 'antd';
import { LinkedinOutlined } from '@ant-design/icons';
import Filters from '../../../../filter';
import { CandidateDetailsCard } from './_components/CandidateDetailsCard';
import axios from 'axios';
import { CandidatesStatusList } from '../../../../../pages/constant/constant';
import { useSelector } from 'react-redux';
import { CANDIDATE_LINKEDIN_LIST, CANDIDATE_LINKEDIN_DETAILS } from '../../../../../../ApiUrl';
import LinkedinCandidateTable from './_components/LinkedinCandidateTable';
import { DrawerContent } from './_components/DrawerDetails';


// Helper function to calculate total experience
function calculateTotalExperience(workExperience) {
    let totalMonths = 0;
    workExperience.forEach(job => {
        const startYear = job.startDateOn?.year || 0;
        const startMonth = job.startDateOn?.month || 0;
        const endYear = job.endDateOn?.year || new Date().getFullYear();
        const endMonth = job.endDateOn?.month || new Date().getMonth() + 1;
        totalMonths += (endYear - startYear) * 12 + (endMonth - startMonth);
    });
    return {
        years: Math.floor(totalMonths / 12),
        months: totalMonths % 12
    };
}

// Helper function to process the initial list of candidates
function processBriefCandidateList(apiResponse) {
    if (!apiResponse || !Array.isArray(apiResponse.elements)) {
        return [];
    }

    return apiResponse.elements.map(candidate => {
        const profile = candidate.linkedInMemberProfileUrnResolutionResult || {};
        const currentPosition = profile.currentPositions?.[0] || {};
        
        // New profile image handling
        let profileImageUrl = '';
        if (profile.vectorProfilePicture) {
            const artifacts = profile.vectorProfilePicture.artifacts || [];
            // Get the highest resolution image (artifacts are sorted by size)
            const highestResArtifact = artifacts[artifacts.length - 1];
            if (highestResArtifact) {
                profileImageUrl = `${profile.vectorProfilePicture.rootUrl}${highestResArtifact.fileIdentifyingUrlPathSegment}`;
            }
        }
        
        return {
            tags: ['linkedin'], 
            first_name: profile.firstName || '',
            last_name: profile.lastName || '',
            email: 'Not Available', // Email is not available in LinkedIn data
            mobile: null,
            status: 'Active',
            total_work_exp_year: calculateTotalExperience(profile.workExperience || []).years.toString(),
            total_work_exp_month: calculateTotalExperience(profile.workExperience || []).months.toString(),
            profile_summary: currentPosition.description || '',
            current_location: profile.location?.displayName || '',
            permanent_address: profile.location?.displayName || '',
            role: profile.headline || '',
            profile_image: profileImageUrl,
            meta: {
                linkedin: profile.publicProfileUrl || '',
                'Linkedin Connections': profile.numConnections || 0
            },
            // Additional LinkedIn specific data
            workExperience: profile.workExperience || [],
            educations: profile.educations || [],
            industryName: profile.industryName || '',
            employees: [{
                organization: currentPosition.companyName || '',
                designation: currentPosition.title || '',
                is_current_company: true,
                location: currentPosition.location?.displayName || ''
            }],
            candidate_qualifications: (profile.educations || []).map(edu => ({
                course: edu.degreeName || '',
                university: edu.schoolName || '',
                passing_year: edu.endDateOn?.year || ''
            }))
        };
    });
}

export default function LinkedInCandidates() {
  const [searchTerm, setSearchTerm] = useState('');
  const [booleanSearch, setBooleanSearch] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0); 
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const users = useSelector((state) => state.users);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [isListView, setIsListView] = useState(false);

  useEffect(() => {
    const handleResizeObserverError = () => {
      window.addEventListener('error', (e) => {
        if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
          e.stopImmediatePropagation();
        }
      });
    };

    handleResizeObserverError();
  }, []);

  const handleSearch = () => {
    if (!searchTerm && !booleanSearch) {
      message.warning('Please enter either a search term or boolean search criteria');
      return;
    }
    setCurrentPage(1);
    handleFilter();
  };

  const handleFilter = (params = {}) => {
    setLoading(true);
    setProgress(30); 
    axios.post(CANDIDATE_LINKEDIN_LIST, {
      searchParams: searchTerm,
      booleanSearch,
      currentPage,
      pageSize: 25, 
      ...params,
    }, {
      headers: { Authorization: users.token },
    })
    .then((res) => {
      const processedResults = processBriefCandidateList(res.data.data);
      setFilteredResults(processedResults);
      setTotalResults(res.data?.paging?.total); 
      setProgress(100); 
      setLoading(false);
    })
    .catch((error) => {
      console.error(error);
      message.error('Failed to fetch data');
      setLoading(false);
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    handleFilter({ start: (page - 1) * 25 }); // Adjust the start parameter for pagination
  };

  const handleViewDetails = async (candidate) => {
    setSelectedCandidate(candidate);
    setDrawerVisible(true);
    try {
      const candidateData = {
        first_name: candidate.first_name,
        last_name: candidate.last_name,
        profile_summary: candidate.profile_summary,
        permanent_address: candidate.permanent_address,
        current_location: candidate.current_location,
        total_work_exp_year: candidate.total_work_exp_year,
        total_work_exp_month: candidate.total_work_exp_month,
        role: candidate.role,
        profile_image: candidate.profile_image,
        meta: candidate.meta,
        tags: candidate.tags,
        key_skills: candidate.tags,
        employees: candidate.employees,
        candidate_qualifications: candidate.candidate_qualifications,
        work_experience: candidate.workExperience, 
        educations: candidate.educations, 
        industry_name: candidate.industryName
      };

      await axios.post(CANDIDATE_LINKEDIN_DETAILS, candidateData, {
        headers: { Authorization: users.token },
      });
      message.success('Candidate details saved successfully');
    } catch (error) {
      console.error('Error saving candidate:', error);
      message.error('Failed to save candidate details');
    }
  };

  const statusObject = CandidatesStatusList;

  const handleChangeView = () => {
    setIsListView(prev => !prev);
  };

  return (
    <>
      <Card style={{ marginBottom: '24px' }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={10} lg={10}>
            <Input
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by keyword..."
              onPressEnter={handleSearch}
            />
          </Col>
          <Col xs={24} sm={24} md={10} lg={10}>
            <Input
              value={booleanSearch}
              onChange={(e) => setBooleanSearch(e.target.value)}
              placeholder="Boolean Search (e.g. software AND engineer NOT junior)"
              onPressEnter={handleSearch}
            />
          </Col>
          <Col xs={24} sm={24} md={4} lg={4}>
            <Button 
              type="primary" 
              onClick={handleSearch} 
              loading={loading}
              block
              disabled={!searchTerm && !booleanSearch}
            >
              Search
            </Button>
          </Col>
          {filteredResults.length > 0 && (
            <Col xs={24} sm={24} md={4} lg={4}>
              <Switch 
                checkedChildren="List View" 
                unCheckedChildren="Card View" 
                checked={isListView} 
                onChange={handleChangeView} 
              />
            </Col>
          )}
        </Row>
      </Card>

      {loading ? (
        <Skeleton active />
      ) : filteredResults.length > 0 ? (
        <>
          {isListView ? (
            <LinkedinCandidateTable 
              data={filteredResults} 
              onViewDetails={handleViewDetails}
              pagination={{
                current: currentPage,
                pageSize: 25,
                total: totalResults,
                onChange: handlePageChange
              }}
            />
          ) : (
            <>
              {filteredResults.map((data) => (
                <CandidateDetailsCard
                  key={data._id}
                  candidate={data}
                  employment={data.employees}
                  qualifications={data.candidate_qualifications}
                  onViewDetails={() => handleViewDetails(data)}
                  IMAGE_CANDIDATE_URL=""
                />
              ))}
              {totalResults > 25 && (
                <div style={{ marginTop: '20px', textAlign: 'right' }}>
                  <Pagination
                    current={currentPage}
                    pageSize={25}
                    total={totalResults}
                    onChange={handlePageChange}
                  />
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <Empty 
          description={
            <span>
              {searchTerm || booleanSearch ? 
                'No results found. Try different search terms.' : 
                'Enter search terms to find candidates'}
            </span>
          }
        />
      )}

      <Drawer
        title="Candidate Details"
        placement="right"
        width={720}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
      >
        {selectedCandidate && <DrawerContent candidate={selectedCandidate} />}
      </Drawer>
    </>
  );
}