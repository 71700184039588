import React, { useState, useEffect } from 'react';
import { Input, Button, Skeleton, Progress, message, Drawer, Row, Col, Avatar, Tag, Timeline, Card, Space, Typography, Empty, Switch } from 'antd';
import { LinkedinOutlined } from '@ant-design/icons';

export const DrawerContent = ({ candidate }) => {
    const getCompanyInfo = (exp) => {
        const companyInfo = exp?.companyResolutionResult || {};
        return companyInfo;
    };
    return (
        <div style={{ padding: '20px' }}>
            <Row gutter={[16, 24]}>
                <Col span={24}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        <Avatar 
                            size={100} 
                            src={candidate?.profile_image}
                            style={{ backgroundColor: '#ed7206' }}
                        >
                            {!candidate?.profile_image && (candidate?.first_name?.charAt(0)?.toUpperCase() || '?')}
                        </Avatar>
                        <div>
                            <h2 style={{ margin: 0 }}>{candidate?.first_name} {candidate?.last_name}</h2>
                            <p style={{ margin: '4px 0' }}>{candidate?.role}</p>
                            <Space size={8}>
                                <Tag color="blue">{candidate?.industryName}</Tag>
                                <Tag color="green">{`${candidate?.meta?.['Linkedin Connections'] || 0} connections`}</Tag>
                            </Space>
                        </div>
                    </div>
                </Col>

                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Card size="small" title="Location">
                                <p>{candidate?.current_location}</p>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card size="small" title="Total Experience">
                                <p>{`${candidate?.total_work_exp_year || 0} Year(s) ${candidate?.total_work_exp_month || 0} Month(s)`}</p>
                            </Card>
                        </Col>
                    </Row>
                </Col>

                {candidate?.profile_summary && (
                    <Col span={24}>
                        <Card size="small" title="Professional Summary">
                            <p>{candidate.profile_summary}</p>
                        </Card>
                    </Col>
                )}

                <Col span={24}>
                    <Card size="small" title="Work Experience">
                        <Timeline>
                            {candidate?.workExperience?.map((exp, index) => {
                                const companyInfo = getCompanyInfo(exp);
                                return (
                                    <Timeline.Item key={index}>
                                        <h4>{exp?.title}</h4>
                                        <div style={{ marginBottom: '8px' }}>
                                            <strong>{exp?.companyName}</strong>
                                            {companyInfo?.industries?.length > 0 && (
                                                <Tag color="cyan" style={{ marginLeft: '8px' }}>
                                                    {companyInfo.industries[0]}
                                                </Tag>
                                            )}
                                        </div>
                                        <p>{`${exp?.startDateOn?.month || ''}/${exp?.startDateOn?.year || ''} - ${
                                            exp?.endDateOn ? `${exp.endDateOn?.month || ''}/${exp.endDateOn?.year || ''}` : 'Present'
                                        }`}</p>
                                        {companyInfo?.description && (
                                            <Typography.Paragraph type="secondary" ellipsis={{ rows: 2 }}>
                                                {companyInfo.description}
                                            </Typography.Paragraph>
                                        )}
                                    </Timeline.Item>
                                );
                            })}
                        </Timeline>
                    </Card>
                </Col>

                <Col span={24}>
                    <Card size="small" title="Education">
                        <Timeline>
                            {candidate?.educations?.map((edu, index) => {
                                const schoolInfo = edu?.organizationUrnResolutionResult || {};
                                return (
                                    <Timeline.Item key={index}>
                                        <h4>{edu?.schoolName}</h4>
                                        <p><strong>{edu?.degreeName}</strong></p>
                                        <p>{`${edu?.startDateOn?.year || ''} - ${edu?.endDateOn?.year || ''}`}</p>
                                        {schoolInfo?.description && (
                                            <Typography.Paragraph type="secondary" ellipsis={{ rows: 2 }}>
                                                {schoolInfo.description}
                                            </Typography.Paragraph>
                                        )}
                                        {schoolInfo?.headquartersLocation && (
                                            <Tag color="blue">{schoolInfo.headquartersLocation}</Tag>
                                        )}
                                    </Timeline.Item>
                                );
                            })}
                        </Timeline>
                    </Card>
                </Col>

                <Col span={24}>
                    <Button 
                        type="primary" 
                        icon={<LinkedinOutlined />} 
                        href={candidate?.meta?.linkedin} 
                        target="_blank"
                        block
                    >
                        View LinkedIn Profile
                    </Button>
                </Col>
            </Row>
        </div>
    );
};
