import {
	Avatar,
	Card,
	Checkbox,
	Divider,
	Tag,
	Typography,
	Button,
	Space,
	Row,
	Col,
	Radio,
	Timeline,
	Tabs,
	Image,
} from "antd";
import {
	EyeOutlined,
	SendOutlined,
	DeleteOutlined,
	EditOutlined,
	LinkedinOutlined,
	GlobalOutlined,
	DownloadOutlined,
} from "@ant-design/icons";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";



import ExpandableContent from "../../../../../../../others/utils/ExpandableContent";
import { useEffect } from "react";


export const CandidateDetailsCard = ({
	candidate = {},
	employment = [],
	qualifications = [],
	IMAGE_CANDIDATE_URL,
	onViewDetails,
}) => {
	useEffect(() => {
		const handleResizeObserverError = () => {
			window.addEventListener('error', (e) => {
				if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
					e.stopImmediatePropagation();
				}
			});
		};

		handleResizeObserverError();
	}, []);

	return (
		<Card style={{ maxWidth: "100%" }}>
			<Row>
				<Col
					span={24}
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Col
						style={{
							display: "flex",
							gap: "16px",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						 
							  <Avatar 
													size={100} 
													src={candidate?.profile_image}
													style={{ backgroundColor: '#ed7206' }}
												>
													{!candidate?.profile_image && (candidate?.first_name?.charAt(0)?.toUpperCase() || '?')}
												</Avatar>
						<Col>
							<Space
								style={{ display: "flex" }}
								direction="horizontal"
								size="middle"
							>
								<Typography.Text
									style={{
										margin: 0,
										fontSize: "16px",
										fontWeight: "bold",
										cursor: "pointer",
									}}
									onClick={() => onViewDetails(candidate)}
								>
									{candidate?.first_name} {candidate?.last_name}
								</Typography.Text>
								{candidate?.meta?.linkedin && (
									<Typography.Link
										href={candidate?.meta?.linkedin}
										target="_blank"
										rel="noreferrer"
										onClick={(e) => e.stopPropagation()}
									>
										<GlobalOutlined style={{ fontSize: "16px", color: "#0077B5" }} />
									</Typography.Link>
								)}
							</Space>

							<Typography.Text className="mb-5" type="secondary">
								{employment?.[0]?.is_current_company
									? `${employment?.[0]?.designation} at ${employment?.[0]?.organization}`
									: "-"}
							</Typography.Text>
							<Typography.Text
								type="secondary"
								style={{
									display: "block",
									marginBottom: "8px",
								}}
							>
								{candidate?.current_location}
							</Typography.Text>

							<Space direction="horizontal" size={12}>
								{candidate?.meta?.linkedin && (
									<Typography.Link
										href={candidate?.meta?.linkedin}
										target="_blank"
										rel="noreferrer"
									>
										<LinkedinOutlined
											style={{
												fontSize: "20px",
												color: "#0077B5",
											}}
										/>
										<Typography.Text style={{ marginLeft: '4px' }}>
											{candidate?.meta?.['Linkedin Connections']} connections
										</Typography.Text>
									</Typography.Link>
								)}
							</Space>
						</Col>
					</Col>
					{/* actions */}
					<Button type="primary" icon={<EyeOutlined />} onClick={() => onViewDetails(candidate)}>
                        View Details
                    </Button>
				</Col>
			</Row>
			<Divider></Divider>
			<Row>
				<Col span={4}>
					<Typography.Text>Experience</Typography.Text>
				</Col>

				<Col
					span={20}
					className=""
					style={{ display: "flex", alignItems: "center" }}
				>
					<BusinessCenterIcon style={{ color: "#000", marginRight: "3px" }} />
					<Typography.Text>
						 {`${candidate?.total_work_exp_year ?? 0} Year(s) ${candidate?.total_work_exp_month ?? 0} Month(s)`}
					</Typography.Text>
				</Col>
			</Row>

			{candidate?.profile_summary && (
				<Row style={{ marginTop: '16px' }}>
					<Col span={4}>
						<Typography.Text>Summary</Typography.Text>
					</Col>
					<Col span={20}>
						<ExpandableContent
							content={<Typography.Paragraph>{candidate?.profile_summary}</Typography.Paragraph>}
							maxHeight={28}
							showMoreText="Read more"
							showLessText="Read less"
						/>
					</Col>
				</Row>
			)}

			<Col span={24}>
				<Tabs
					defaultActiveKey="1"
					tabPosition="left"
					items={[
						{
							label: "Qualification",
							key: "1",
							children: (
								<Col span={12}>
									<ExpandableContent
										content={
											<Timeline className="p-1">
												{qualifications?.length > 0 ? qualifications.map((qual, index) => (
													<Timeline.Item key={`qualification-${index}`}>
														<strong>{qual?.course}</strong>
														{qual?.university && (
															<span>
																{" "}
																from <strong>{qual?.university}</strong>
															</span>
														)}
														<br />
														{qual?.passing_year && <span>({qual?.passing_year})</span>}
													</Timeline.Item>
												)) : <Typography.Text>No qualifications available</Typography.Text>}
											</Timeline>
										}
										maxHeight={58}
										showMoreText="Show more"
										showLessText="Show fewer"
									/>
								</Col>
							),
						},
						{
							label: "Employment",
							key: "2",
							children: (
								<Col span={12}>
									<ExpandableContent
										content={
											<Timeline className="p-1">
												{employment?.length > 0 ? employment.map((job, index) => (
													<Timeline.Item key={index}>
														<strong>{job?.designation}</strong> at{" "}
														<strong>{job?.organization}</strong>
														<br />
														{job?.work_since_from_year} -{" "}
														{job?.is_current_company
															? "Present"
															: job?.work_since_to_year || "N/A"}
														<br />
														{job?.location}
													</Timeline.Item>
												)) : <Typography.Text>No employment history available</Typography.Text>}
											</Timeline>
										}
										maxHeight={58}
										showMoreText="Show more"
										showLessText="Show fewer"
									/>
								</Col>
							),
						},
					]}
				/>
			</Col>
		</Card>
	);
};
