import React, { useEffect, useState } from 'react'
import { Drawer, Tabs, Button, Typography, Space, Divider, Skeleton, Row, Col } from 'antd'
import {
  LeftOutlined,
  RightOutlined,
  HeartOutlined,
  MailOutlined,
  PhoneOutlined,
  MoreOutlined
} from '@ant-design/icons'
import axios from 'axios'
import { CB_CANDIDATE_SAVE, CB_SEARCH, CB_SEARCH_DETAILS } from '../../../ApiUrl'
import { requestHeader } from '../../../others/header'
import DOMPurify from 'dompurify'

const { Title, Text } = Typography
const { TabPane } = Tabs

// Utility function to parse and clean resume HTML
const parseResumeHtml = (htmlString) => {
  if (!htmlString) return '';

  // First decode HTML entities
  const decoded = htmlString
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&')
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/&nbsp;/g, ' ');

  // Sanitize the decoded HTML
  const clean = DOMPurify.sanitize(decoded, {
    ALLOWED_TAGS: ['div', 'p', 'span', 'b', 'i', 'u', 'a', 'br', 'ul', 'li'],
    ALLOWED_ATTR: ['style', 'href', 'class'],
  });

  return clean;
};

const ProfileSection = ({ title, children }) => (
  <div style={{ marginBottom: '24px' }}>
    <Title level={5}>{title}</Title>
    {children}
  </div>
);

const WorkHistoryItem = ({ company, title, tenure }) => (
  <div style={{ marginBottom: '16px' }}>
    <Text strong>{title}</Text>
    <br />
    <Text type="secondary">{company} • {tenure}</Text>
  </div>
);

const EducationItem = ({ school, major, degree, graduationDate }) => (
  <div style={{ marginBottom: '16px' }}>
    <Text strong>{school}</Text>
    <br />
    <Text>{major}</Text>
    <Text type="secondary">{degree} • {graduationDate}</Text>
  </div>
);

export default function CandidateDetails({
  visible = false,
  onClose = () => { },
  resumeId
}) {
  // console.log('resumeId', resumeId)
  const [loading, setLoading] = useState(false)
  const [candidateDetails, setCandidateDetails] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchCandidateDetails = async () => {
      if (!resumeId || !visible) return;

      setLoading(true);
      try {
        const response = await axios.post(
          `${CB_SEARCH_DETAILS}/${resumeId}`,
          {},
          requestHeader()
        );
        const data = response?.data?.data;
        if (data) {
          // if (data.ResumeHTML?.[0]) {
          //   const isHtml = data.ResumeHTML[0].includes('<html>');
          //   data.format = isHtml ? 'html' : 'pdf';
          // }
          setCandidateDetails(response.data.data);
          // Only save if we have valid data
          // await axios.post(
          //   `${CB_CANDIDATE_SAVE}`, 
          //   { candidateData: response.data },
          //   requestHeader()
          // );
        }
      } catch (err) {
        setError(err?.message || 'Failed to fetch details');
        console.error('Error fetching candidate details:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchCandidateDetails();
  }, [resumeId, visible]);

  if (loading) {
    return (
      <Drawer
        open={visible}
        onClose={onClose}
        size='large'
        width={800}
      >
        <Skeleton active />
      </Drawer>
    );
  }

  if (error) {
    return <Text type="danger">{error}</Text>;
  }

  const ResumeViewer = ({ content }) => {
    if (!content) return <Text>No resume content available</Text>;


    const pdfUrl = `data:application/pdf;base64,${content}`;

    return (
      <div style={{ width: '100%', height: '800px', position: 'relative' }}>

        <object
          data={pdfUrl}
          type="application/pdf"
          width="100%"
          height="100%"
          style={{ border: 'none' }}
        >

          <iframe
            src={pdfUrl}
            width="100%"
            height="100%"
            style={{ border: 'none' }}
          >

            <div className="pdf-fallback">
              <p>Unable to display PDF.
                <a
                  href={pdfUrl}
                  download="resume.pdf"
                  className="download-link"
                >
                  Download PDF
                </a>
              </p>
            </div>
          </iframe>
        </object>
      </div>
    );
  };
  const renderResumeContent = () => {
    if (!candidateDetails?.ResumeHTML?.[0]) {
      return <Text>No resume content available</Text>;
    }

    return <ResumeViewer content={candidateDetails.ResumeHTML[0]} />;
  };


  return (
    <Drawer
      open={visible}
      onClose={onClose}


      // width={}
      size='large'
      width={800}
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Space>
            {/* <Button
              icon={<LeftOutlined />}
              onClick={handlePrevious}
            >
              Previous Candidate
            </Button>
            <Text>Candidate {candidateDetails?.currentPosition} of {candidateDetails?.totalCandidates}</Text>
            <Button
              icon={<RightOutlined />}
              onClick={handleNext}
            >
              Next Candidate
            </Button> */}
          </Space>
        </div>
      }
      extra={
        <Space>
          {/* <Button type="primary" icon={<MailOutlined />}>
            Message
          </Button> */}
          {/* <Button icon={<HeartOutlined />} /> */}
          {/* <Button icon={<MoreOutlined />} /> */}
        </Space>
      }
    >
      <div style={{ padding: '0 24px' }}>
        {/* Header Section */}
        <div style={{ marginBottom: '24px' }}>
          <Title level={4}>{candidateDetails?.ContactName?.[0] ?? 'N/A'}</Title>
          <Text>{candidateDetails?.HomeLocation?.[0]?.City ?? 'N/A'} , {candidateDetails?.HomeLocation?.[0]?.State ?? 'N/A'}</Text>
          <div style={{ marginTop: '8px' }}>
            <Text>{candidateDetails?.ResumeTitle?.[0] ?? 'N/A'} • {candidateDetails?.company ?? 'N/A'}</Text>
          </div>
          <div style={{ marginTop: '8px' }}>
            <Space direction="vertical">
              <Text>
                <MailOutlined style={{ marginRight: '8px' }} />
                Email: {candidateDetails?.ContactEmail?.[0] ?? 'N/A'}
              </Text>
              <Text>
                <PhoneOutlined style={{ marginRight: '8px' }} />
                Phone: {candidateDetails?.ContactPhone?.[0] ?? 'N/A'}
              </Text>
            </Space>
          </div>
        </div>

        {/* Tabs Section */}
        <Row gutter={24}>
          <Col span={24}>
            <Tabs defaultActiveKey="resume">

              <TabPane tab="Full Resume" key="fullResume">
                {renderResumeContent()}
              </TabPane>
              <TabPane tab="Profile" key="profile">
                <div>
                  {/* Work History Section */}
                  <ProfileSection title="Work History & Experience">
                    {candidateDetails?.WorkHistory?.[0]?.ExtCompany?.map((work, index) => (
                      <WorkHistoryItem
                        key={index}
                        company={work?.CompanyName?.[0] ?? 'N/A'}
                        title={work?.JobTitle?.[0] ?? 'N/A'}
                        tenure={work?.Tenure?.[0] ?? 'N/A'}
                      />
                    )) ?? <Text type="secondary">No work history available</Text>}
                  </ProfileSection>

                  <Divider />

                  {/* Education Section */}
                  <ProfileSection title="Education">
                    {candidateDetails?.EducationHistory?.[0]?.ExtSchool?.length > 0 ? (
                      candidateDetails.EducationHistory[0].ExtSchool.map((edu, index) => (
                        <EducationItem
                          key={index}
                          school={edu.SchoolName[0]}
                          major={edu.Major[0]}
                          degree={edu.Degree[0]}
                          graduationDate={edu.GraduationDate[0]?.replace(/^1/, '')} // Remove leading '1' from year
                        />
                      ))
                    ) : (
                      <Text type="secondary">No education history available</Text>
                    )}
                  </ProfileSection>

                  <Divider />

                  {/* Skills Section */}
                  <ProfileSection title="Skills & Expertise">
                    <Space direction="vertical" size="small">
                      {candidateDetails?.Interests?.[0]?.ExtInterest?.map((interest, index) => (
                        <Text key={index}>{interest.Interest[0]}</Text>
                      ))}
                    </Space>
                  </ProfileSection>

                  <Divider />

                  {/* Additional Information */}
                  <ProfileSection title="Additional Information">
                    <Space direction="vertical">
                      <Text>Experience: {candidateDetails?.ExperienceMonths?.[0]
                        ? `${Math.floor(candidateDetails.ExperienceMonths[0] / 12)} years ${candidateDetails.ExperienceMonths[0] % 12} months`
                        : 'Not specified'}</Text>
                      <Text>Highest Degree: {candidateDetails?.HighestDegree?.[0] || 'Not specified'}</Text>
                      <Text>Willing to Relocate: {candidateDetails?.SFWillingToRelocate?.[0] === 'true' ? 'Yes' : 'No'}</Text>
                      <Text>Current Employment: {candidateDetails?.CurrentlyEmployed?.[0] || 'Not specified'}</Text>
                    </Space>
                  </ProfileSection>

                  <Divider />

                  {/* Action History */}
                  <ProfileSection title="Action History">
                    <Text>
                      {candidateDetails?.ActionType?.[0] === 'VIEWED'
                        ? `You Viewed Profile • ${new Date(candidateDetails?.LastActivity?.[0]).toLocaleDateString()}`
                        : 'No recent activity'}
                    </Text>
                  </ProfileSection>
                </div>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
    </Drawer>
  )
}

// Add these styles to your CSS
const styles = `
.resume-content div {
  position: static !important;
  margin-bottom: 8px;
}

.resume-content {
  font-family: 'Times New Roman', serif;
  line-height: 1.5;
}

.resume-content b {
  font-weight: 600;
}
`;

// Add these styles to your component or CSS file
const resumeStyles = {
  resumeContent: {
    // padding: '20px',
    fontFamily: 'Calibri, Arial, sans-serif',
    lineHeight: '1.6',
    fontSize: '14px',
  },
  heading: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '12px',
  },
  section: {
    marginBottom: '20px',
  },
  listItem: {
    marginBottom: '8px',
  },
};