import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Card, Row, Col, List, Spin } from "antd";
import { Table } from "ant-table-extensions";
import { CANDIDATE_REPORT_JOBS } from "../../../../../../ApiUrl";
import axios from "axios";
import { useSelector } from "react-redux";
import { statusList } from "../../../../../pages/constant/constant";
import { logoutHandler } from "../../../../../../others/logout";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useReportData from "../../../../../../hooks/useReportData";

function CandidateReport(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [chartStatus, setChartStatus] = useState("");
  const common = useSelector(({ common }) => common);
  const categoryList = common.category;

  const columns = [
    {
      title: "Display Name",
      dataIndex: ["candidate", "0", "first_name"],
      key: "name",
      render: (text, record) => {
        return record?.candidate?.display_name;
      },
    },
    {
      title: "Current Location",
      dataIndex: ["candidate", "0", "current_location"],
      key: "current_location",
      render:(text, record)=>{
        return record.candidate?.current_location;
      }
    },
    {
      title: "Job Category",
      dataIndex: ["jobopening", "0", "category"],
      key: "job_category",
      render: (text, record) =>{

        // (category ? getCategory(category)?.name : ""),
        const category =record?.jobopening?.category;
        // console.log('category', category);
        // console.log('get category', getCategory(category)?.name);
        return category ? getCategory(category)?.name : "";


      }
    },
    {
      title: "Submission status",
      dataIndex: "submission_status",
      key: "status",
      render: (status) => (status ? statusList?.find((el) => el?.value === status)?.label : ""),
    },
  ];

  const initialParams = {
    dateRange: props.arrayDateRange,
    categories: [],
    status: props.statusLabal,
    opening_id: "",
    company_id: "",
    current_page: currentPage,
    per_page: '10',
    sort_order: 'desc',
    filter_value: '',
    order: 'created_at',
  };

  const { data: openings, loading, error, updateParams } = useReportData(CANDIDATE_REPORT_JOBS, initialParams);
  // console.log('openings', openings?.candidate_submission_listing);
  // useEffect(() => {
  //   props.setCandidateTabSelected(true);
  //   return () => props.setCandidateTabSelected(false);
  // }, []);

  useEffect(() => {
    updateParams({
      current_page: currentPage,
      status: chartStatus ? statusList.find(status => status.label === chartStatus)?.value : props.statusLabal,
    });
  }, [currentPage, chartStatus, props.statusLabal]);

  const getCategory = (value) => {
    return categoryList.find((item) => item.code === value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const newSeries = [];
  const newLabals = [];
  const data1 = 44;

  // hold: 4
  // null: 4
  // oh: 1
  // re-submission: 1
  // reject: 3
  // ri: 1
  // submission: 130
  // submit: 51
  // submitted: 1

  if (props && props.candidateJobCount) {
    if (props.candidateJobCount.Active) {
      newSeries.push(props.candidateJobCount.Active);
      newLabals.push("Active");
    }
    if (props.candidateJobCount.reject) {
      newSeries.push(props.candidateJobCount.reject);
      newLabals.push("Rejected");
    }
    if (props.candidateJobCount.I) {
      newSeries.push(props.candidateJobCount.I);
      newLabals.push("Interview");
    }
    if (props.candidateJobCount.oh) {
      newSeries.push(props.candidateJobCount.oh);
      newLabals.push("On Hold");
    }
    if (props.candidateJobCount.ri) {
      newSeries.push(props.candidateJobCount.ri);
      newLabals.push("Closed");
    }
    if (props.candidateJobCount.submit) {
      newSeries.push(props.candidateJobCount.submit);
      newLabals.push("Submit");
    }
    if (props.candidateJobCount.submitted) {
      newSeries.push(props.candidateJobCount.submitted);
      newLabals.push("Submitted");
    }
    // if (props.candidateJobCount.submission) {
    //   newSeries.push(props.candidateJobCount.submission);
    //   newLabals.push("Submission");
    // }
  }


  const data = {
    series: newSeries,
    options: {
      chart: {
        loading: loading,
        type: "donut",
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const submissionStatus = statusList.find((status) => {
              return (
                status.label === config.w.config.labels[config.dataPointIndex]
              );
            });

            setChartStatus(
              submissionStatus
                ? submissionStatus.label
                : config.w.config.labels[config.dataPointIndex]
            );
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      labels: newLabals,
    },
  };

  const DoughnutChart = () => (
    <Spin spinning={loading}>
      <Card title={"All Candidates"}>
        <Row gutter={24}>
          <Col span={12}>
            <div id="chart">
              <Chart
                options={data.options}
                series={data.series}
                type="donut"
                width="400"
              />
            </div>
          </Col>
          <Col span={12}>
            <List
              dataSource={newLabals}
              itemLayout={"horizontal"}
              bordered={false}
              renderItem={(item, index) => (
                <List.Item
                  style={{ "borderBottom": 0, padding: "5px 0" }}
                  key={index.toString()}
                >
                  <Col span={18} style={{ textAlign: "right" }}>
                    {item} :
                  </Col>
                  <Col span={6}>{newSeries[index]}</Col>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Card>
      <br />
    </Spin>
  );

  const fields = {
    display_name: {
      header: "Display Name",
      formatter: (_, record) => record.candidate?.display_name || '',
    },
    current_location: {
      header: "Current Location",
      formatter: (_, record) => record.candidate?.current_location || '',
    },
    job_category: {
      header: "Job Category",
      formatter: (_, record) => {
        const category = record.jobopening?.category;
        return category ? getCategory(category)?.name : "";
      },
    },
    status: {
      header: "Submission status",
      formatter: (_, record) => {
        const status = record.submission_status;
        return status ? statusList.find((el) => el.value === status)?.label : "";
      },
    },
  };

  const candidatesData = () => (
    <>
      <Card title="Candidates Data" bordered={false} className="px-0 py-0">
        <Table
          loading={loading}
          pagination={{
            current: currentPage,
            total: openings?.totalRecords || 0,
            pageSize: 10,
            onChange: handlePageChange,
          }}
          dataSource={openings?.candidate_submission_listing || []}
          columns={columns}
          exportableProps={{ fields, fileName: "Candidate Data" }}
        />
      </Card>
    </>
  );

  if (error) return <div>Error: {error.message}</div>;
  if (loading) return <Spin spinning={loading}></Spin>;


  return (
    <>
      {DoughnutChart()} {candidatesData()}
    </>
  );
}

export default CandidateReport;
