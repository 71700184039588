import { Button, Empty, Modal } from "antd";
import { Table } from "ant-table-extensions";
import axios from "axios";
import { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../_theme_parts/_helpers";
import { CANDIDATE_CANDIDATE_LIST_BY_JOB_OPENING_ID } from "../../../ApiUrl";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";

const TotalApplicantsModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  user,
  openingId,
}) => {
  const [totalApplicants, setTotalApplicants] = useState([]);
  const [pagination, setPagination] = useState({ pageSize: 10, page: 1 });

  useEffect(() => {
    if (!isModalOpen) return;

    (async () => {
      const params = {
        per_page: pagination.pageSize,
        current_page: pagination.page,
        job_opening_id: openingId,
      };
      try {
        const response = await axios.post(
          CANDIDATE_CANDIDATE_LIST_BY_JOB_OPENING_ID,
          params,
          {
            headers: { Authorization: user.token },
          }
        );
        setTotalApplicants(response.data.data.candidate_listing || []);
      } catch (error) {
        console.error("Error fetching applicants: ", error);
      }
    })();
  }, [isModalOpen, pagination.page]);

  const columns = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Candidate Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div className="candidate-details">
          <div className="candidate-avatar">
            <SVG src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")} />
          </div>
          <div>{record.first_name + " " + record.last_name}</div>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => text || "-",
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
      render: (text) => text || "-",
    },
    {
      title: "Experience",
      dataIndex: "experience",
      key: "experience",
      render: (text, record) =>
        record.total_work_exp_year && record.total_work_exp_month
          ? `${record.total_work_exp_year ||
              "0"} Yrs ${record.total_work_exp_month || "0"} Month`
          : "-",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <NavLink
          to={{
            pathname: `/${createNewClientPath}/candidateProfileDetail`,
            state: {
              item: {
                ...record,
                opening_id: openingId,
                candidate_id: record._id,
              },
            },
          }}
        >
          <Button type="primary" className="view-profile-btn">
            View Profile
          </Button>
        </NavLink>
      ),
    },
  ];

  const createNewClientPath =
    user.role === "admin"
      ? "admin"
      : user.role === "recruitmentmanager"
      ? "recruitment-manager"
      : user.role === "bdmmanager"
      ? "bdm-manager"
      : user.role;

  return (
    <Modal
      title="Applicants"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
    >
      {totalApplicants.length > 0 ? (
        <Table
          pagination={{
            total: totalApplicants.length,
            showSizeChanger: false,
            pageSize: pagination.pageSize,
            current: pagination.page,
            onChange(current) {
              setPagination({ ...pagination, page: current });
              window.history.pushState(
                { page: current },
                "",
                `?page=${current}`
              );
            },
          }}
          scroll={{ x: "auto", y: 350 }}
          dataSource={totalApplicants}
          columns={columns}
          rowKey={(record) => record._id}
          className="applicants-table"
          rowClassName="applicants-row"
          exportableProps={{
            fields: {
              first_name: {
                header: "Candidate Name",
                formatter: (_, record) =>
                  record?.first_name + " " + record?.last_name,
              },
              email: {
                header: "Email",
                formatter: (_, record) => record?.email,
              },
              mobile: {
                header: "Phone",
                formatter: (_, record) => record?.mobile,
              },
              experience: {
                header: "Experience",
                formatter: (_, record) =>
                  record.total_work_exp_year && record.total_work_exp_month
                    ? `${record.total_work_exp_year ||
                        "0"} Yrs ${record.total_work_exp_month || "0"} Month`
                    : "-",
              },
            },
          }}
        />
      ) : (
        <Empty description="No Applicants found" />
      )}
    </Modal>
  );
};

export default TotalApplicantsModal;
