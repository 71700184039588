import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Radio,
  Row,
  Select,
  Tag,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import { setRole, setToken, setUser } from "../../../redux/actions/users";
import { getBase64, showError } from "../../pages/utils/helpers";
import {
  CANDIDATE,
  CANDIDATE_DELETE_EDUCATION,
  CANDIDATE_DELETE_EMPLOYMENT,
  CANDIDATE_UPDATE,
  CANDIDATE_UPDATE_EDUCATION,
  CANDIDATE_UPDATE_EMPLOYMENT,
} from "../../../ApiUrl";
import {
  Courses,
  EducationList,
  months,
  monthsOfExpirance,
  noticePeriod,
  payTypes,
  Universities,
  yearsOfExpirance,
} from "../../pages/constant/constant";
import { requestHeader } from "../../../others/header";
import { useLocation, NavLink, useHistory } from "react-router-dom";
import { logoutHandler } from "../../../others/logout";
import moment from "moment";
import { DeleteOutlined, DiffOutlined, EditOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import TextArea from "antd/lib/input/TextArea";
import { useCallAPI } from "../../../hooks/useCallAPI";

const { Option } = Select;
const { Text } = Typography;
const FormItem = Form.Item;

function EditCandidate(props) {
  const [form] = Form.useForm();
  const UpdateEmployment = useCallAPI(CANDIDATE_UPDATE_EMPLOYMENT);
  const DeleteEmployment = useCallAPI(CANDIDATE_DELETE_EMPLOYMENT);
  const UpdateEducation = useCallAPI(CANDIDATE_UPDATE_EDUCATION);
  const DeleteEducation = useCallAPI(CANDIDATE_DELETE_EDUCATION);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [msgError, setmsgError] = useState("");
  const [selectedFileList, setSelectedFileList] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [isMounted, setIsMounted] = useState(true);
  const [fileError, setFileError] = useState(false);
  const history = useHistory();

  const [editVisible, setEditVisible] = useState(false);
  const formRef = React.useRef();
  const educationFormRef = React.useRef();
  const [selectedPosition, setSelectedposition] = useState(-1);
  const [isCurrentCompany, setCurrentCompany] = useState(false);
  const [employmentArray, setEmploymentArray] = useState([]); // Initialize with an empty array
  const [selectedQualification, setSelectedQualification] = useState(-1);
  const [educationArray, setEducationArray] = useState([]); // Initialize with an empty array
  const [visible, setVisible] = useState(false);

  const [tags, setTags] = useState([]);
  // const api = useCallApi()
  const handleChange = (value) => {
    setTags(value);
  };

  const showEducationDrawer = (position) => {
    if (position == -1) {
      educationFormRef?.current?.resetFields();
    }
    setVisible(true);
    setSelectedQualification(position);

    setTimeout(() => {
      if (educationFormRef?.current) {
        const selectedValues = educationArray[position];
        if (selectedValues) {
          educationFormRef?.current?.setFieldsValue({
            qualification: parseInt(selectedValues.qualification),
            course: parseInt(selectedValues.course),
            university: selectedValues.university,
            course_type: selectedValues.course_type,
            passing_year: selectedValues.passing_year,
          });
        }
      }
    }, 0);
  };

  const onEducationModalClose = () => {
    setVisible(false);
    setSelectedQualification(-1);
  };

  const generateArrayOfYears = () => {
    var max = new Date().getFullYear();
    var min = max - 30;
    var years = [];

    for (var i = max; i >= min; i--) {
      const object = { label: i, value: i };
      years.push(object);
    }
    return years;
  };

  const showDrawer = (position) => {
    if (position == -1) {
      formRef?.current?.resetFields();
    }
    setEditVisible(true);
    setSelectedposition(position);

    // Use setTimeout to delay setting form values, ensuring the form is rendered
    setTimeout(() => {
      if (formRef?.current) {
        const selectedValues = employmentArray[position];
        if (selectedValues) {
          formRef.current.setFieldsValue({
            designation: selectedValues.designation,
            organization: selectedValues.organization,
            work_since_from_month: selectedValues.work_since_from_month,
            work_since_from_year: selectedValues.work_since_from_year,
            notice_period: selectedValues.notice_period,
            is_current_company: selectedValues.is_current_company,
            work_since_to_year: selectedValues.work_since_to_year,
            work_since_to_month: selectedValues.work_since_to_month,
            annual_salary: selectedValues.annual_salary,
            location: selectedValues.location,
            description_job_profile: selectedValues.description_job_profile,
          });
        }
      }
    }, 0); // Add a slight delay to ensure formRef is set
  };

  const propsUpload = {
    onRemove: (file) => {
      const index = selectedFileList.indexOf(file);
      const newFileList = selectedFileList.slice();
      newFileList.splice(index, 1);
      setSelectedFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (file.size > 5000000) {
        setError(true);
        setmsgError("File should be less than 5 MB.");
        // setDefaultState();
        setDefaultStateForFile();
        return false;
      }

      if (
        file?.type !==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
        file?.type !== "application/msword" &&
        file?.type !== "application/pdf" &&
        file?.type !== "text/csv"
      ) {
        setError(true);
        setmsgError("Not a supported file format.");
        // setDefaultState();
        setDefaultStateForFile();
        return false;
      } else {
        setSelectedFileList([...selectedFileList, file]);
        let idCardBase64 = ""; //vnd.openxmlformats-officedocument.wordprocessingml.document
        //msword
        getBase64(file, (result) => {
          idCardBase64 = result.replace(
            "vnd.openxmlformats-officedocument.wordprocessingml.document",
            "msword"
          );
          setSelectedFile(idCardBase64);
          const values = {
            attachments: idCardBase64,
            // profile_summary: userProfileDetails.profile_summary,
          };
          // postEditSummary(values);
        });
        setFileError(false);
      }
      return false;
    },
    selectedFileList,
  };

  let location = useLocation();
  let { _id } = location.state.item;
  // console.log(location.state.item, "location state in edit component");

  // const getCandidateDetail = (candidateId) => {
  // 	axios
  // 		.get(`${CANDIDATE}/${candidateId}`, requestHeader())
  // 		.then((res) => {
  // 			if (res.data.statusCode == 200) {
  // 				form.current.setFieldsValue(res.data.data[0]);

  // 				setSelectedFileList(res.data.data[0].attachments);
  // 				setSelectedFile(res.data.data[0].attachments || "");
  // 			}
  // 		})
  // 		.catch((error) => {
  // 			if (error?.response?.status == 401) {
  // 				logoutHandler(history);
  // 			}
  // 		});
  // };
  // value={
  //   form.getFieldValue("passing_year")
  //     ? // Ensure strict parsing
  //     : null
  // }

  const onFinishEmploymentHistory = async (values) => {
    // Convert annual salary to string
    values.annual_salary = values.annual_salary
      ? values.annual_salary.toString()
      : "";
    values.notice_period = values.notice_period ? values.notice_period : " ";
    values.description = values.description ? values.description : " ";
    values.annual_salary_currency_type =
      values.annual_salary_currency_type || "Dollars"; // Set default if not provided

    if (selectedPosition !== -1) {
      const updatedArray = [...employmentArray];
      const employmentId = updatedArray[selectedPosition]._id; // Assuming _id is in the employment array

      updatedArray[selectedPosition] = {
        ...updatedArray[selectedPosition],
        ...values,
      };

      try {
        UpdateEmployment.post({
          employmentId, // Send employmentId for updating existing record
          updatedData: updatedArray[selectedPosition],
          candidateId: _id,
        });
      } catch (error) {
        console.error("Error updating employment:", error);
      }

      setEmploymentArray(updatedArray);
      setSelectedposition(-1);
    } else {
      // If adding new entry
      values.work_since_to_present = "no"; // Set default value for new entries

      // API call to create new employment
      try {
        UpdateEmployment.post({
          updatedData: {
            ...values,
          },
          candidateId: _id,
        });
      } catch (error) {
        console.error("Error creating new employment:", error);
      }

      setEmploymentArray((prevArray) => [...prevArray, values]);
    }

    // Reset form fields
    setEditVisible(false);
    formRef.current.resetFields(); // Clear form after submission
  };

  const getQualificationData = () => {
    return educationArray.map((qualification, index) => {
      const course = Courses.find((item) => {
        return parseInt(item.value) === parseInt(qualification.course);
      });
      return (
        <Row key={index} gutter={24} className="mb-5">
          <Col span={24} className="mb-10">
            <Title level={5} className="mb-0">
              {course ? course.label : ""}
              <Tooltip className="ml-3" placement="top" title="Edit Details">
                <EditOutlined
                  style={{ fontSize: "15px", color: "#372727" }}
                  onClick={() => {
                    showEducationDrawer(index);
                  }}
                />
              </Tooltip>
              <Tooltip className="ml-3" placement="top" title="Delete">
                <Popconfirm
                  title="Are you sure you want to delete？"
                  onConfirm={() => deleteEducationItem(index)}
                  icon={<DeleteOutlined style={{ color: "red" }} />}
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Tooltip>
            </Title>

            <Text type="secondary">{qualification.university}</Text>
            <br />
            <Text type="secondary">
              {qualification.passing_year} ({qualification.course_type})
            </Text>
            <br />
          </Col>
        </Row>
      );
    });
  };

  const onFinishEducation = async (values) => {
    // Convert values to strings if necessary
    values.passing_year = values.passing_year
      ? values.passing_year.toString()
      : "";
    values.course_type = values.course_type || "fullTime"; // Default to 'fullTime'
    values.qualification = values.qualification || "";
    values.course = values.course || "";
    values.university = values.university || "";

    if (selectedQualification !== -1) {
      // If editing an existing entry
      const updatedArray = [...educationArray];
      const educationId = updatedArray[selectedQualification]._id; // Assuming _id exists in educationArray

      updatedArray[selectedQualification] = {
        ...updatedArray[selectedQualification],
        ...values, // Merge updated values
      };

      try {
        await UpdateEducation.post({
          educationId, // Send educationId to update the existing record
          updatedData: updatedArray[selectedQualification],
          candidateId: _id, // Assuming _id is available for the candidate
        });
      } catch (error) {
        console.error("Error updating education:", error);
      }

      setEducationArray(updatedArray);
      setSelectedQualification(-1); // Reset the selected index
    } else {
      // If adding a new entry
      try {
        await UpdateEducation.post({
          updatedData: {
            ...values,
          },
          candidateId: _id,
        });
      } catch (error) {
        console.error("Error creating new education:", error);
      }

      setEducationArray((prevArray) => [...prevArray, values]); // Add the new entry
    }

    // Reset form fields and close the modal
    setVisible(false);
    educationFormRef.current.resetFields();
  };

  const editEducationModal = () => {
    return (
      <Drawer
        title="Edit Educational Details"
        width={620}
        onClose={onEducationModalClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: "right" }}>
            <Button onClick={onEducationModalClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button
              form="editEducation"
              htmlType="submit"
              key="submit"
              type="primary"
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          ref={educationFormRef}
          id="editEducation"
          layout="vertical"
          onFinish={onFinishEducation}
        >
          <Card>
            <Col span={16}>
              <FormItem label="Education" name="qualification">
                <Select>
                  <Option value="">Select</Option>
                  {EducationList.map((item, index) => (
                    <Option value={item.value} key={index}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>

            <Col span={16}>
              <FormItem label="Course" name="course">
                <Select>
                  <Option value="">Select</Option>
                  {Courses.map((item, index) => (
                    <Option value={item.value} key={index}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>

            <Col span={16}>
              <FormItem
                label="University/Institute"
                name="university"
                rules={[
                  {
                    required: true,
                    message: "Your University/Institute is required.",
                  },
                ]}
              >
                <Input placeholder="University Institute" />
              </FormItem>
            </Col>

            <Col span={24}>
              <FormItem
                label="Course Type"
                name="course_type"
                rules={[
                  { required: true, message: "Please select Course Type." },
                ]}
              >
                <Radio.Group>
                  <Radio value="fullTime">Full Time</Radio>
                  <Radio value="partTime">Part Time</Radio>
                  <Radio value="distanceLearning">
                    Correspondence/Distance learning
                  </Radio>
                </Radio.Group>
              </FormItem>
            </Col>

            <Col span={16}>
              <FormItem
                label="Passing out Year"
                name="passing_year"
                rules={[
                  { required: true, message: "Passing Year is required." },
                ]}
              >
                <Input placeholder="Passing Year" />
              </FormItem>
            </Col>
          </Card>
        </Form>
      </Drawer>
    );
  };
  const deleteItem = async (position) => {
    const employment = employmentArray[position];

    if (employment && employment._id) {
      const employmentId = employment._id;

      try {
        DeleteEmployment.post({
          employmentId: employmentId,
        });
        const updatedArray = employmentArray.filter(
          (_, index) => index !== position
        );
        setEmploymentArray(updatedArray); // Update the state with the new array
      } catch (error) {
        console.error("Error deleting employment:", error);
      }
    } else {
      console.error("Employment not found for the given position.");
    }
  };
  const deleteEducationItem = async (position) => {
    const education = educationArray[position];

    if (education && education._id) {
      const educationId = education._id;

      try {
        DeleteEducation.post({
          educationId: educationId,
        });
        const updatedArray = educationArray.filter(
          (_, index) => index !== position
        );
        setEducationArray(updatedArray); // Update the state with the new array
      } catch (error) {
        console.error("Error deleting employment:", error);
      }
    } else {
      console.error("Employment not found for the given position.");
    }
  };

  const employeedata = (employers) => {
    return employmentArray.map((employer, index) => {
      // console.log(employer);
      const notice_period_elm = noticePeriod.filter(
        (el) => el.value === employer.notice_period
      );
      const months_from_elm = months.filter(
        (el) => el.value === employer.work_since_from_month
      );
      const months_to_elm = months.filter(
        (el) => el.value === employer.work_since_to_month
      );

      return (
        <Row key={index} gutter={24} className="mb-5">
          <Col span={24} className="mb-10">
            <Title level={5} className="mb-0">
              {employer.designation}
              <Tooltip className="ml-3" placement="top" title="Edit Details">
                <EditOutlined
                  style={{ fontSize: "15px", color: "#372727" }}
                  onClick={() => showDrawer(index)}
                />
              </Tooltip>
              <Tooltip className="ml-3" placement="top" title="Delete">
                <Popconfirm
                  title="Are you sure you want to delete？"
                  onConfirm={() => deleteItem(index)}
                  icon={<DeleteOutlined style={{ color: "red" }} />}
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Tooltip>
            </Title>
            <Text type="secondary">{employer.organization}</Text>
            <br />
            <Text type="secondary">
              {(months_from_elm[0]?.label || "") +
                "/" +
                employer.work_since_from_year}{" "}
              to
              {!employer.is_current_company
                ? (months_to_elm[0]?.label || "") +
                  "/" +
                  employer.work_since_to_year
                : "Present"}
            </Text>
            <br />
            <Text type="secondary">
              {notice_period_elm[0]?.label
                ? `Available to join in ${notice_period_elm[0].label}`
                : ""}
            </Text>
            <br />
            <Text type="secondary">{employer.description_job_profile}</Text>
          </Col>
        </Row>
      );
    });
  };
  // console.log(form.getFieldsValue(), "testing..");
  const getCandidateDetail = (candidateId) => {
    axios
      .get(`${CANDIDATE}/${candidateId}`, requestHeader())
      .then((res) => {
        if (res.data.statusCode == 200) {
          const candidate = res.data.data[0];
          const qualifications = candidate?.candidate_qualifications;
          const employees = candidate?.employees;
          form.current.setFieldsValue({
            ...candidate,
          });

          setEmploymentArray(employees);
          setEducationArray(qualifications);

          const attachments = res.data.data[0].attachments;
          if (Array.isArray(attachments)) {
            const formattedFileList = attachments.map((fileName, index) => ({
  uid: `${index}`, // Unique ID for each file (you can also use a more unique identifier if available)
  name: fileName,  // File name
  status: 'done',  // Indicates upload is complete (adjust as needed)
}));
            setSelectedFileList(formattedFileList);
          } else {
            const fileNamesFromBackend = [attachments];
            const formattedFileList = fileNamesFromBackend.map((fileName, index) => ({
  uid: `${index}`, // Unique ID for each file (you can also use a more unique identifier if available)
  name: fileName,  // File name
  status: 'done',  // Indicates upload is complete (adjust as needed)
}));
            setSelectedFileList(formattedFileList);
          }
          // setSelectedFile(res.data.data[0].attachments || "");
        }
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          logoutHandler(history);
        }
      });
  };

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  const updateCandidate = () => {
    setLoading(true);
    const formData = new FormData();
    const date = form.current.getFieldValue("passing_year");
    const year = date ? date.format("YYYY") : null;
  
    // Create a qualification object
    // Append the qualification object as a JSON string
    // formData.append("qualification", JSON.stringify(qualification));
  
    // Check for selected files and append them
    if (selectedFileList && selectedFileList.length > 0 && selectedFile) {
      // console.log("inside the check....");
      // console.log(selectedFileList, selectedFile, "files...");
      formData.append("attachments", selectedFile);
    }
  
    // Append other fields, excluding fields that need to be handled differently
    Object.keys(form.current.getFieldsValue()).forEach((key) => {
      const value = form.current.getFieldValue(key);
  
      // Skip fields that are undefined, null, or empty
      if (
        value !== undefined &&
        value !== null &&
        value !== "" &&
        !["attachments", "qualification", "university", "course", "passing_year"].includes(key)
      ) {
        formData.append(key, value);
      }
    });
  
    axios
      .put(`${CANDIDATE_UPDATE}/${_id}`, formData, {
        ...requestHeader(),
        "Content-Type": "multipart/form-data",
      })
      .then((response) => {
        setLoading(false);
        setSuccess(true);
        setMsgSuccess(response?.data?.message);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
        setmsgError(error?.data?.message);
        if (error?.response?.status === 401) {
          logoutHandler(history);
        }
      })
      .finally(() => {
        if (isMounted) {
          setTimeout(() => {
            setSuccess(false);
            setLoading(false);
            setError(false);
            setMsgSuccess("");
            setmsgError("");
          }, 3000);
        }
      });
  };
  
  useEffect(() => {
    if (_id) {
      getCandidateDetail(_id);
    }
  }, []);
  const handleFileUpload = (info) => {
    if (info.file.status === "removed") {
      setSelectedFile("");
      setSelectedFileList([]);
    } else {
      setSelectedFileList([info.file]);
      getBase64(info.file, (result) => {
        setSelectedFile(result);
      });
    }
  };

  const onClose = () => {
    setEditVisible(false);
    setSelectedposition(-1);
  };

  const onChangeCompany = (e) => {
    setCurrentCompany(e.target.value);
    editModal();
  };
  const editModal = () => {
    return (
      <Drawer
        title="Edit Employment Details"
        width={620}
        onClose={() => onClose()}
        visible={editVisible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={() => onClose()} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button
              form="editEmployment"
              htmlType="submit"
              key="submit"
              type="primary"
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          ref={formRef}
          id="editEmployment"
          layout="vertical"
          onFinish={onFinishEmploymentHistory}
        >
          <Card>
            {/* {showError(success, msgSuccess, error, msgError)} */}
            <Col span={16}>
              <FormItem
                label="Your Designation"
                name={"designation"}
                rules={[
                  {
                    required: true,
                    message: "Your Designation is required.",
                  },
                ]}
              >
                <Input placeholder="Your Designation" />
              </FormItem>
            </Col>
            <Col span={16}>
              <FormItem
                label="Your Organization"
                name={"organization"}
                rules={[
                  {
                    required: true,
                    message: "Your Organization is required.",
                  },
                ]}
              >
                <Input placeholder="Organization" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Is this your current company?"
                name={"is_current_company"}
                rules={[
                  {
                    required: true,
                    message: "Please select current company.",
                  },
                ]}
              >
                <Radio.Group onChange={onChangeCompany}>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
            <Col span={16}>
              <FormItem label="Started Working From">
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem
                      label="Years"
                      name={"work_since_from_year"}
                      rules={[
                        {
                          required: true,
                          message: "Please Select Years",
                        },
                      ]}
                    >
                      <Select>
                        <Option value={""}>{"Select"}</Option>
                        {generateArrayOfYears().map((user, index) => {
                          return (
                            <Option value={user.value} key={index}>
                              {user.label + (index < 2 ? " Year" : " Years")}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label="Months"
                      name={"work_since_from_month"}
                      rules={[
                        {
                          required: true,
                          message: "Please Select Months",
                        },
                      ]}
                    >
                      <Select>
                        <Option value={""}>{"Select"}</Option>
                        {months.map((user, index) => {
                          return (
                            <Option value={user.value} key={index}>
                              {user.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
            </Col>

            {!isCurrentCompany ? (
              <Col span={16}>
                <FormItem label="Worked Till">
                  <Row gutter={24}>
                    <Col span={12}>
                      <FormItem
                        label="Years"
                        name={"work_since_to_year"}
                        rules={[
                          {
                            required: true,
                            message: "Please Select Years",
                          },
                        ]}
                      >
                        <Select>
                          <Option value={""}>{"Select"}</Option>
                          {generateArrayOfYears().map((user, index) => {
                            return (
                              <Option value={user.value} key={index}>
                                {user.label + (index < 2 ? " Year" : " Years")}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem
                        label="Months"
                        name={"work_since_to_month"}
                        rules={[
                          {
                            required: true,
                            message: "Please Select Months",
                          },
                        ]}
                      >
                        <Select>
                          <Option value={""}>{"Select"}</Option>
                          {months.map((user, index) => {
                            return (
                              <Option value={user.value} key={index}>
                                {user.label}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                  </Row>
                </FormItem>
              </Col>
            ) : null}

            <Col span={24}>
              <FormItem label="Annual Salary">
                <Row gutter={24}>
                  <Col span={16}>
                    <FormItem
                      label=""
                      name={"annual_salary"}
                      rules={[
                        {
                          required: true,
                          message: "This field is required.",
                        },
                      ]}
                    >
                      <Input
                        prefix={"$"}
                        placeholder="Salary"
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
            </Col>

            <Col span={16}>
              <FormItem
                label="Describe your Job Profile"
                name={"description_job_profile"}
                rules={[
                  {
                    required: true,
                    message: "Job Profile is required.",
                  },
                ]}
              >
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 8 }}
                  placeholder="Describe your Job Profile"
                />
              </FormItem>
            </Col>
            <Col span={16}>
              <FormItem
                label="Your Location"
                name={"location"}
                rules={[
                  {
                    required: true,
                    message: "Your Location is required.",
                  },
                ]}
              >
                <Input placeholder="Location" />
              </FormItem>
            </Col>
            <Col span={16}>
              <FormItem label="Notice Period" name={"notice_period"}>
                <Select>
                  <Option value={""}>{"Select"}</Option>
                  {noticePeriod.map((user, index) => {
                    return (
                      <Option value={user.value} key={index}>
                        {user.label}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
          </Card>
        </Form>
      </Drawer>
    );
  };

  return (
    <Form
    ref={form}
    id="AddCandidate"
    onFinish={updateCandidate}
    layout="vertical"
  >
    {showError(success, msgSuccess, error, msgError)}
  
    <Card title={"Edit Candidate"}>
      <Row gutter={24}>
        <Col span={8}>
          <FormItem
            label="First name"
            name="first_name"
            rules={[{ required: true, message: "First name is required." }]}
          >
            <Input placeholder="First name" value="tarek" />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label="Middle name" name="middle_name">
            <Input placeholder="Middle name" />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label="Last name"
            name="last_name"
            rules={[{ required: true, message: "Last name is required." }]}
          >
            <Input placeholder="Last name" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <FormItem
            label="Email"
            name="email"
            rules={[
              { type: "email", message: "The input is not valid E-mail!" },
              { required: true, message: "Email Address is required." },
            ]}
          >
            <Input placeholder="Email" />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="Mobile"
            name="mobile"
            rules={[{ required: true, message: "Mobile is required." }]}
          >
            <Input placeholder="Mobile" />
          </FormItem>
        </Col>
      </Row>
  
      {/* New Fields */}
      <Row gutter={24}>
        <Col span={12}>
          <FormItem
            label="Gender"
            name="gender"
            rules={[{ required: true, message: "Please select your gender." }]}
          >
            <Select placeholder="Select Gender">
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
              <Option value="other">Other</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="Location"
            name="current_location"
            rules={[{ required: true, message: "Location is required." }]}
          >
            <Input placeholder="Location" />
          </FormItem>
        </Col>
      </Row>
  
      <Row gutter={24}>
        <Col span={24}>
          <FormItem
            label="Profile Summary"
            name="profile_summary"
            rules={[{ required: true, message: "Profile summary is required." }]}
          >
            <Input.TextArea rows={4} placeholder="Write a brief profile summary" />
          </FormItem>
        </Col>
      </Row>
  
      {/* Existing Total Experience Section */}
      <Row gutter={24}>
        <Col span={24}>
          <FormItem label="Total Experience">
            <Row gutter={24}>
              <Col span={12}>
                <FormItem label="Years" name="total_work_exp_year">
                  <Select>
                    {yearsOfExpirance.map((user, index) => (
                      <Option value={user.value} key={index}>
                        {user.label + (index < 2 ? " Year" : " Years")}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Months" name="total_work_exp_month">
                  <Select>
                    {monthsOfExpirance.map((user, index) => (
                      <Option value={user.value} key={index}>
                        {user.label + (index < 2 ? " Month" : " Months")}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
  
              <Col span={12}>
                <FormItem label="Input tags" name="tags">
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="input tags"
                    onChange={handleChange}
                    value={tags}
                  >
                    {tags.map((tag) => (
                      <Option key={tag} value={tag}>
                        <Tag color="blue">{tag}</Tag>
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
  
              <Col span={12}>
                <FormItem label="Pay Type" name="pay_type">
                  <Select>
                    {payTypes.map((type, index) => (
                      <Option value={type} key={index.toString()}>
                        {type}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Notice Period" name="notice_period">
                  <Select>
                    {noticePeriod.map((period, index) => (
                      <Option value={period.value} key={index.toString()}>
                        {period.label}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
            </Row>
          </FormItem>
        </Col>
      </Row>
  
      <Col span={24}>
        <FormItem
          label={
            <span>
              <span style={{ color: "#ff4d4f" }}>*</span> Upload Document
              (only doc, docx, pdf, csv)
            </span>
          }
          name="attachments"
        >
          <Upload
            {...propsUpload}
            accept=".pdf, .doc, .docx , .csv"
            fileList={selectedFileList.length ? selectedFileList : []}
            onChange={handleFileUpload}
          >
            <Button>UPLOAD RESUME</Button>
          </Upload>
          <Text type="secondary">
            Supported Formats: doc, docx, pdf, csv, up to 5 MB
          </Text>
          {fileError && (
            <p style={{ color: "#ff4d4f" }}>Please upload a document</p>
          )}
        </FormItem>
      </Col>
    </Card>
  
    <br />
    <Card title="Employment" extra={<Tooltip placement="top" title="Add Your Employment Details"><DiffOutlined style={{ fontSize: "20px", color: "#372727" }} onClick={() => showDrawer(-1)} /></Tooltip>}>
      {employeedata(employmentArray)}
      {editModal()}
    </Card>
  
    <Card title="Education" extra={<Tooltip placement="top" title="Add Your Education Details"><DiffOutlined style={{ fontSize: "20px", color: "#372727" }} onClick={() => showEducationDrawer(-1)} /></Tooltip>}>
      {getQualificationData()}
      {editEducationModal()}
    </Card>
  
    <Card>
      <Row gutter={24}>
        <Col span={24}>
          {loading ? (
            <Button type="primary" size="large" loading>
              Saving . . .
            </Button>
          ) : (
            <Button type="primary" size="large" htmlType="submit">
              Update
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  </Form>
  
  );
}

const mapDispatchToProps = (dispatch) => ({
  setRole: (data) => {
    dispatch(setRole(data));
  },
  setUser: (data) => {
    dispatch(setUser(data));
  },
  setToken: (data) => {
    dispatch(setToken(data));
  },
});

export default connect(null, mapDispatchToProps)(EditCandidate);
