import { WorkOutlineOutlined } from "@material-ui/icons";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import RoomIcon from "@material-ui/icons/Room";
import {
  Button,
  Card,
  Col,
  Empty,
  Pagination,
  Row,
  Select,
  Typography,
} from "antd";

import axios from "axios";
import $, { get } from "jquery";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  CANDIDATE_CATEGORY_LIST,
  CANDIDATE_CITY_LIST,
  CANDIDATE_JOB_OPENING_LIST_PUBLIC,
  CANDIDATE_STATE_LIST,
  HAS_WEB_ACCESS,
} from "../../../ApiUrl";

import { IdcardOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  optionEmploymentType,
  salaryRange,
  yearsOfExpirance,
} from "../../pages/constant/constant";
const { Option } = Select;
const { Text, Title } = Typography;

const PublicJobOpenings = () => {
  const { search } = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(search);

  const [state, setState] = useState({
    candidate_list_details: [],
    categoryList: [],
    cityList: [],
    stateList: [],
    current_page: 1,
    pageSize: 10,
    totalPages: 50,
    totalRecords: 0,
    search: urlParams.get("search") || "",
    categories: urlParams.getAll("category") || [],
    salary: urlParams.getAll("salary") || [],
    location: urlParams.get("location") || "",
    city: urlParams.getAll("city").map((l) => parseInt(l)) || [],
    state: urlParams.getAll("state").map((l) => parseInt(l)) || [],
    experiance: urlParams.get("experiance") || "",
    employment_type: urlParams.get("employment_type") || "",
    loading: false,
    companyID: urlParams.get("company_id"),
    rdt: urlParams.get("rdt") || null, // rdt = referrer domain token
  });
  const [hasWebAccess, setHasWebAcess] = useState(false);
  const fetchUserData = () => {
    const params = {
      current_page: state.current_page,
      per_page: state.pageSize,
      search: state.search,
      salary: state.salary,
      location: state.location,
      city: state.city,
      state: state.state,
      categories: state.categories,
      experience: state.experiance,
      employment_type: state.employment_type,
      order_direction: "desc",
    };

    setState((prevState) => ({ ...prevState, loading: true }));
    axios
      .post(
        CANDIDATE_JOB_OPENING_LIST_PUBLIC +
          `${state.companyID ? `?company_id=${state.companyID}` : ""}`,
        params
      )
      .then((resp) => {
        setState((prevState) => ({
          ...prevState,
          candidate_list_details: resp.data.data.job_opening_listing,
          totalPages: resp.data.data.totalPages,
          totalRecords: resp.data.data.totalRecords,
          loading: false,
        }));
      })
      .catch(() => {
        setState((prevState) => ({ ...prevState, loading: false }));
      });
  };

  const getCategoriesList = () => {
    axios.get(CANDIDATE_CATEGORY_LIST).then((res) => {
      setState((prevState) => ({
        ...prevState,
        categoryList: res.data.data.category,
      }));
    });
  };

  const getcityList = () => {
    axios.get(CANDIDATE_CITY_LIST).then((res) => {
      setState((prevState) => ({
        ...prevState,
        cityList: res.data.data.city,
      }));
    });
  };
  const getStateList = () => {
    axios.get(CANDIDATE_STATE_LIST).then((res) => {
      setState((prevState) => ({
        ...prevState,
        stateList: res.data.data.state,
      }));
    });
  };
  const onPageChange = (page) => {
    setState((prevState) => ({ ...prevState, current_page: page }));
  };

  const handleSearch = (value) => {
    setState((prevState) => ({ ...prevState, search: value }));
    if (value) {
      urlParams.set("search", value);
    } else {
      urlParams.delete("search");
    }
    history.push({
      search: urlParams.toString(),
    });
  };

  const handleCategoryChange = (value) => {
    setState((prevState) => ({ ...prevState, categories: value }));
    if (value.length > 0) {
      urlParams.delete("category");
      value.forEach((category) => {
        urlParams.append("category", category);
      });
    } else {
      urlParams.delete("category");
    }
    history.push({
      search: urlParams.toString(),
    });
  };

  const handleSalaryChange = (value) => {
    setState((prevState) => ({ ...prevState, salary: value }));
    if (value.length > 0) {
      urlParams.delete("salary");
      value.forEach((salary) => {
        urlParams.append("salary", salary);
      });
    } else {
      urlParams.delete("salary");
    }
    history.push({
      search: urlParams.toString(),
    });
  };

  const handlecityChange = (value) => {
    setState((prevState) => ({ ...prevState, city: value }));
    if (value.length > 0) {
      urlParams.delete("location");
      setState((prevState) => ({ ...prevState, location: "" }));
      urlParams.delete("city");
      value.forEach((city) => {
        urlParams.append("city", city);
      });
    } else {
      urlParams.delete("city");
    }

    history.push({
      search: urlParams.toString(),
    });
  };

  const handleStateChange = (value) => {
    setState((prevState) => ({ ...prevState, state: value }));
    if (value.length > 0) {
      urlParams.delete("location");
      setState((prevState) => ({ ...prevState, location: "" }));
      urlParams.delete("state");
      value.forEach((state) => {
        urlParams.append("state", state);
      });
    } else {
      urlParams.delete("state");
    }
    history.push({
      search: urlParams.toString(),
    });
  };

  const handleExperianceChange = (value) => {
    setState((prevState) => ({ ...prevState, experiance: value }));
    if (value) {
      urlParams.set("experiance", value);
    } else {
      urlParams.delete("experiance");
    }
    history.push({
      search: urlParams.toString(),
    });
  };
  const handleJobTypeChange = (value) => {
    setState((prevState) => ({ ...prevState, employment_type: value }));
    if (value) {
      urlParams.set("employment_type", value);
    } else {
      urlParams.delete("employment_type");
    }
    history.push({
      search: urlParams.toString(),
    });
  };

  const resetFilters = () => {
    if (
      state.categories.length === 0 &&
      state.salary.length === 0 &&
      state.city.length === 0 &&
      state.state.length === 0 &&
      state.experiance === "" &&
      state.search === "" &&
      state.employment_type === ""
    )
      return;

    setState((prevState) => ({
      ...prevState,
      categories: [],
      salary: [],
      city: [],
      state: [],
      experiance: "",
      search: "",
      employment_type: "",
      location: "",
    }));
    urlParams.delete("location");
    urlParams.delete("category");
    urlParams.delete("salary");
    urlParams.delete("city");
    urlParams.delete("state");
    urlParams.delete("experiance");
    urlParams.delete("search");
    urlParams.delete("employment_type");
    history.push({ search: urlParams.toString() });
  };

  useEffect(() => {
    // if (!hasWebAccess) return;
    getcityList();
    getStateList();
    getCategoriesList();
  }, [hasWebAccess]);

  useEffect(() => {
    // if (!hasWebAccess) return;
    const debounceFetch = setTimeout(() => {
      fetchUserData();
    }, 1000);

    return () => clearTimeout(debounceFetch);
  }, [
    hasWebAccess,
    state.current_page,
    state.search,
    state.salary,
    state.city,
    state.location,
    state.state,
    state.categories,
    state.experiance,
    state.employment_type,
    state.companyID,
  ]);

  const checkHasAccess = async () => {
    try {
      const { data } = await axios.post(
        HAS_WEB_ACCESS,
        {
          rdt: state.rdt,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );
      if (data.error) {
        setHasWebAcess(false);
      } else {
        setHasWebAcess(true);
      }
    } catch (error) {
      setHasWebAcess(false);
    }
  };
  // useEffect(() => {
  //   if (window.top !== window.self) {
  //     console.log("Checking access");
  //     if (!state.rdt) {
  //       setHasWebAcess(false);
  //       return;
  //     }
  //     checkHasAccess();
  //   } else {
  //     setHasWebAcess(true);
  //   }
  // }, []);

  // if (!hasWebAccess) {
  //   return (
  //     <div className="empty-container">
  //       <Empty description="You don't have access to this page" />
  //     </div>
  //   );
  // }
  return (
    <div className="pr-lg-5">
      <div className="public-job-openings">
        <div className="bg-white public-job-openings-sidebar">
          <div className="mb-5 search-container">
            <input
              key="random1"
              className={"text-muted"}
              value={state.search}
              placeholder={"Skills, Designations"}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
            />
            <Button
              onClick={fetchUserData}
              type="primary"
              size="large"
              className="search-btn"
            >
              Search
            </Button>
          </div>
          <Card
            title={"Filters"}
            extra={
              <Button onClick={resetFilters} type="primary" size="small" danger>
                Clear Filters
              </Button>
            }
            bordered={false}
          >
            <Row gutter={24} className="filter-container">
              <Row xs={24} sm={16} md={12} lg={6} xl={6}>
                <label htmlFor="by_source" className="" title="Date Range">
                  Salary :
                </label>
                <br />
                <Select
                  mode="multiple"
                  value={state.salary}
                  onChange={handleSalaryChange}
                  style={{ width: "100%" }}
                  placeholder="Select Salary Range"
                >
                  {salaryRange !== undefined &&
                    salaryRange.map((salaryRange, index) => (
                      <Option key={index.toString()} value={salaryRange.value}>
                        {"$ " + salaryRange.label + "  "}
                      </Option>
                    ))}
                </Select>
              </Row>
              <Row xs={24} sm={16} md={12} lg={8} xl={8}>
                <label htmlFor="by_source" className="" title="Category">
                  Category :
                </label>
                <br />
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option ? option.children.toLowerCase().includes(input) : ""
                  }
                  mode="multiple"
                  onChange={handleCategoryChange}
                  value={state.categories}
                  style={{ width: "100%" }}
                  placeholder="Select Category"
                >
                  {state.categoryList !== undefined &&
                    state.categoryList.map((category, index) => (
                      <Option key={index.toString()} value={category.code}>
                        {category.name}
                      </Option>
                    ))}
                </Select>
              </Row>
              <Row xs={24} sm={16} md={12} lg={6} xl={6}>
                <label htmlFor="by_source" className="" title="Status">
                  City :
                </label>
                <br />
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option ? option.children.toLowerCase().includes(input) : ""
                  }
                  mode="multiple"
                  value={state.city}
                  onChange={handlecityChange}
                  style={{ width: "100%" }}
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  placeholder="Select city"
                >
                  {state.cityList.map((city, index) => (
                    <Option key={index.toString()} value={city.code}>
                      {city.city}
                    </Option>
                  ))}
                </Select>
              </Row>
              <Row xs={24} sm={16} md={12} lg={6} xl={6}>
                <label htmlFor="by_source" className="" title="Status">
                  State :
                </label>
                <br />
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option ? option.children.toLowerCase().includes(input) : ""
                  }
                  mode="multiple"
                  value={state.state}
                  onChange={handleStateChange}
                  style={{ width: "100%" }}
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  placeholder="Select State"
                >
                  {state.stateList?.map((state, index) => (
                    <Option key={index.toString()} value={state.code}>
                      {state.state}
                    </Option>
                  ))}
                </Select>
              </Row>
              <Row xs={24} sm={16} md={12} lg={3} xl={3}>
                <label htmlFor="by_source" className="" title="Status">
                  Experience :
                </label>
                <br />
                <Select
                  value={state.experiance}
                  onChange={handleExperianceChange}
                  style={{ width: "100%" }}
                >
                  <Option value={""}>{"Select"}</Option>
                  {yearsOfExpirance !== undefined &&
                    yearsOfExpirance.map((option, index) => (
                      <Option key={index.toString()} value={option.value}>
                        {option.label +
                          (option.label === "0"
                            ? ""
                            : option.label === "1"
                            ? " year"
                            : " years")}
                      </Option>
                    ))}
                </Select>
              </Row>
              <Row xs={24} sm={16} md={12} lg={3} xl={3}>
                <label htmlFor="by_source" className="" title="Status">
                  Job Type :
                </label>
                <br />
                <Select
                  value={state.employment_type}
                  onChange={handleJobTypeChange}
                  style={{ width: "100%" }}
                >
                  <Option value={""}>{"Select"}</Option>
                  {optionEmploymentType !== undefined &&
                    optionEmploymentType.map((option, index) => (
                      <Option key={index.toString()} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                </Select>
              </Row>
            </Row>
          </Card>
        </div>
        <div className="right-container px-5 px-lg-0">
          {state.loading ? (
            <div className="loading-container">
              <LoadingOutlined style={{ fontSize: 40 }} spin />
            </div>
          ) : state.candidate_list_details.length > 0 ? (
            <div className="job-listing">
              {state.candidate_list_details?.map((job, index) => {
                const desc = $(job.job_description).text();

                const location = `${job?.city?.city || ""}${
                  job?.city?.city && job?.state?.state ? ", " : ""
                }${job?.state?.state || ""}`;

                return (
                  <Col key={index} className="job-card">
                    <Card
                      hoverable
                      title={<Title level={4}>{job?.opening_title}</Title>}
                      style={{
                        textTransform: "capitalize",
                      }}
                      extra={
                        <Text type="secondary">
                          {moment(job?.created_at).fromNow()}
                        </Text>
                      }
                    >
                      <Title level={5}>{job?.category?.name}</Title>

                      <Row
                        style={{
                          gap: "10px",
                        }}
                      >
                        <p>
                          <WorkOutlineOutlined />{" "}
                          {optionEmploymentType
                            .find((type) => type.value === job?.employment_type)
                            ?.label.toLowerCase() || "Full-time"}
                        </p>
                        <p>
                          <WorkOutlineOutlined />{" "}
                          {job.required_experience > 0
                            ? job.required_experience + " Yrs Experience"
                            : "Freshers Can Apply"}
                        </p>
                        {Boolean(location) && (
                          <p>
                            <RoomIcon /> {location}
                          </p>
                        )}
                        <p>
                          <AccountBalanceWalletIcon />{" "}
                          {`$${job.salary_range_from} - $${job.salary_range_to}`}
                        </p>
                      </Row>
                      <br />
                      <p>
                        <b>Description: </b>{" "}
                        {desc.length > 300
                          ? desc.substring(0, 300) + "..."
                          : desc}
                      </p>
                      <p>
                        <b>Skills: </b>
                        {job?.required_skills.join(", ")}
                      </p>
                      <Link
                        target="_blank"
                        to={{
                          pathname: `/openings/${job.opening_id}`,
                        }}
                      >
                        <Button type="primary" size="small">
                          View Details
                        </Button>
                      </Link>
                    </Card>
                  </Col>
                );
              })}
              <Row justify="center" className="mt-5">
                <Pagination
                  current={state.current_page}
                  pageSize={state.pageSize}
                  total={state.totalRecords}
                  onChange={onPageChange}
                  showSizeChanger={false}
                />
              </Row>
            </div>
          ) : (
            <div className="empty-container">
              <Empty description="No Job Openings Found" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PublicJobOpenings;
