import React from 'react';
import { JobOpenings } from '../../../../../pages/bdm/AutoSource';
// import { JobOpenings } from '../../../../pages/bdm/AutoSource';

export default function AutoSourceCandidates({ type = 'admin' }) {
  return (
    <div>
      <JobOpenings type={type} />
    </div>
  );
}
