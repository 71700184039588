import React, { useEffect, useState } from "react";
import { Switch, Spin, Empty, Button, Modal, Input, Alert } from "antd";
import axios from "axios";
import {
  GET_ALL_WEB_ACCESS,
  WEB_ACCESS_CREATE,
  WEB_ACCESS_DELETE,
  WEB_ACCESS_UPDATE,
} from "../../../ApiUrl";
import { DeleteOutlined } from "@ant-design/icons";

const DEFAULT_VALUES = {
  title: "",
  url: "",
};

const WebAccess = () => {
  const [webAccessList, setWebAccessList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteWebsiteId, setDeleteWebsiteId] = useState(null);
  const [addNewWebSiteValues, setAddNewWebSiteValues] = useState(
    DEFAULT_VALUES
  );
  const [addNewWebSiteLoading, setAddNewWebSiteLoading] = useState(false);

  const handleAddNewWebSiteChange = (e) => {
    setError(null);
    setAddNewWebSiteValues((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
  };

  const updateStatus = async (id, checkedValue) => {
    setWebAccessList((pre) => [
      ...pre.map((item) =>
        item._id === id ? { ...item, isActive: checkedValue } : item
      ),
    ]);
    try {
      await axios.put(
        `${WEB_ACCESS_UPDATE}/${id}`,
        {
          isActive: checkedValue,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      console.log(`Error while updating status: ${error.message}`);
      setWebAccessList((pre) => [
        ...pre.map((item) =>
          item._id === id ? { ...item, isActive: !checkedValue } : item
        ),
      ]);
    }
  };

  const handleAddNewWebsite = async () => {
    if (!addNewWebSiteValues.title || !addNewWebSiteValues.url) {
      return setError("Please fill all fields");
    }
    setAddNewWebSiteLoading(true);
    try {
      const { data } = await axios.post(
        WEB_ACCESS_CREATE,
        addNewWebSiteValues,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );
      if (!data.error) {
        setCreateModalOpen(false);
        setAddNewWebSiteValues(DEFAULT_VALUES);
        setWebAccessList((pre) => [...pre, data.data]);
      } else {
        throw new Error(Object.values(data.errors).join(", "));
      }
    } catch (error) {
      console.log(`Error while adding new website: ${error.message}`);
      setError(
        Object.values(error.response.data.errors).join(", ") ||
          "Something went wrong"
      );
    } finally {
      setAddNewWebSiteLoading(false);
    }
  };

  const handleDeleteWebsite = async () => {
    let deletedData = webAccessList.find(
      (item) => item._id === deleteWebsiteId
    );
    let index = webAccessList.findIndex((item) => item._id === deleteWebsiteId);
    setWebAccessList((pre) =>
      pre.filter((item) => item._id !== deletedData._id)
    );
    setDeleteModalOpen(false);
    try {
      await axios.delete(`${WEB_ACCESS_DELETE}/${deleteWebsiteId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      });
    } catch (error) {
      console.log(error);
      setWebAccessList((pre) => {
        pre.splice(index, 0, deletedData);
        return pre;
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(GET_ALL_WEB_ACCESS, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        });
        if (!data.error) {
          setWebAccessList(data.data);
        }
      } catch (error) {
        setError(`Error while fetching web access list: ${error.message}`);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <div className="card card-custom gutter-b">
        <div className="card-header align-items-center">
          <div className="card-title">
            <h3 className="card-label">Web Access Configuration</h3>
          </div>
          <div>
            <Button type="primary" onClick={() => setCreateModalOpen(true)}>
              Add Website
            </Button>
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <div className="loading-container d-flex justify-content-center">
              <Spin size="large" />
            </div>
          ) : webAccessList && webAccessList.length > 0 ? (
            webAccessList.map((item) => (
              <div className="form-group row align-items-center" key={item._id}>
                <label className="col-form-label col-2">
                  {item.title}
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <span className="text-info d-block">{item.url}</span>
                  </a>
                </label>
                <div className="col-6">
                  <Switch
                    checked={item.isActive}
                    onChange={(c) => updateStatus(item._id, c)}
                  />
                  <DeleteOutlined
                    style={{
                      marginLeft: 20,
                      color: "red",
                      cursor: "pointer",
                      fontSize: 20,
                      verticalAlign: "middle",
                    }}
                    onClick={() => {
                      setDeleteWebsiteId(item._id);
                      setDeleteModalOpen(true);
                    }}
                  />
                </div>
              </div>
            ))
          ) : (
            <Empty description="No websites available. Please add a website." />
          )}
        </div>
      </div>
      <Modal
        title="Add Website"
        open={createModalOpen}
        onCancel={() => setCreateModalOpen(false)}
        onOk={handleAddNewWebsite}
        okText="Add"
        okButtonProps={{ loading: addNewWebSiteLoading }}
      >
        <div>
          {error && <Alert message={error} type="error" showIcon />}
          <div className="form-group">
            <label>Title</label>
            <Input
              placeholder="Enter website title"
              onChange={(v) =>
                handleAddNewWebSiteChange({
                  target: { name: "title", value: v.target.value },
                })
              }
              value={addNewWebSiteValues.title}
            />
          </div>
          <div className="form-group">
            <label>URL</label>
            <Input
              placeholder="Enter website URL"
              allowClear
              onChange={(v) =>
                handleAddNewWebSiteChange({
                  target: { name: "url", value: v.target.value },
                })
              }
              value={addNewWebSiteValues.url}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title="Delete Website"
        open={deleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onOk={handleDeleteWebsite}
        okText="Delete"
        okButtonProps={{ danger: true }}
      >
        <p>Are you sure you want to delete this website?</p>
      </Modal>
    </>
  );
};

export default WebAccess;
