import React, { Component, useState } from "react";
import axios from "axios";
import { US_STATE_LIST, US_CITY_LIST, JOB_BDM_LIST } from "../../../ApiUrl";
import { Form, Input, Row, Col, Card, Select, Button, Switch } from "antd";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
const { Option } = Select;
const FormItem = Form.Item;
// const [form] = Form.useForm();
export function CreateContact({ getContact }) {
  const [contact, setContact] = useState({
    first_name: "",
    last_name: "",
    display_name: "",
    email: "",
    alternative_email: "",
    mobile: "",
    phone: "",
  });
  // const [render, setRender] = useState(true);
  // const [first_name, set_first_name] = useState("");
  // const [last_name, set_last_name] = useState("");
  // const [display_name, set_fdisplay_name] = useState("");
  // const [email, set_email] = useState("");
  // const [alternative_email, set_alternative_email] = useState("");
  // const [mobile, set_mobile] = useState("");
  // const [phone, set_phone] = useState("");
  // const [first_name, set_first_name] = useState("")
  const [, setDummyState] = useState(0);
  const [form] = Form.useForm();
  const forceRerender = () => {
    setDummyState((prev) => prev + 1);
  };

  const handleInput = (e) => {
    const { id, value } = e.target;
    setContact((prevContact) => ({
      ...prevContact,
      [id]: value,
    }));
  };

  const handleSubmit = () => {
    getContact(contact);
    // setContact({})
    setContact({
      first_name: "",
      last_name: "",
      display_name: "",
      email: "",
      alternative_email: "",
      mobile: "",
      phone: "",
    });
    // set_first_name("");
    // setRender(!render)
    forceRerender();
    form.resetFields();
  };
  // console.log("redning");
  return (
    <Form
      id="AddContact"
      // ref={this.formRef}
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      // initialValues={contact}
    >
      <Card
        title={false ? "Edit Contact" : "Add Contact"}
        extra={
          <NavLink
            to={
              {
                // pathname: "/contact/ContactList",
              }
            }
          ></NavLink>
        }
      >
        <Row gutter={24}>
          <Col span={6}>
            <FormItem
              label="First name"
              name={"first_name"}
              // rules={[
              //   {
              //     required: true,
              //     message: "First name is required.",
              //   },
              // ]}
            >
              <Input
                placeholder="First name"
                onChange={handleInput}
                value={contact.first_name}
              />
            </FormItem>
          </Col>

          <Col span={6}>
            <FormItem
              label="Last name"
              name={"last_name"}
              // rules={[
              //   {
              //     required: true,
              //     message: "Last name is required.",
              //   },
              // ]}
            >
              <Input
                placeholder="Last name"
                onChange={handleInput}
                value={contact.last_name}
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem
              label="Display name"
              name={"display_name"}
              rules={[
                {
                  required: true,
                  message: "Display name is required.",
                },
              ]}
            >
              <Input
                placeholder="Display name"
                onChange={handleInput}
                value={contact.display_name}
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={9}>
            <FormItem
              label="Email"
              name={"email"}
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Email Address is required.",
                },
              ]}
            >
              <Input
                placeholder="Email"
                onChange={handleInput}
                value={contact.email}
                type="email"
              />
            </FormItem>
          </Col>
          <Col span={9}>
            <FormItem
              label="Alternate Email"
              name={"alternative_email"}
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
              ]}
            >
              <Input
                placeholder="Alternate Email"
                onChange={handleInput}
                value={contact.alternative_email}
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <FormItem
              label="Mobile"
              name={"mobile"}
              // rules={[
              //   {
              //     required: true,
              //     message: "Mobile is required.",
              //   },
              // ]}
            >
              <Input
                placeholder="Mobile"
                onChange={handleInput}
                value={contact.mobile}
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="Phone (Home)" name={"phone"}>
              <Input
                placeholder="Phone (Home)"
                onChange={handleInput}
                value={contact.phone}
              />
            </FormItem>
          </Col>
        </Row>
        <Button
          // onClick={handleSubmit}
          // onSubmit={sub}
          htmlType="submit"
          type="Secondary"
          style={{ margin: "auto", textAlign: "center" }}
        >
          Add Contact
        </Button>
      </Card>
    </Form>
  );
}

export const ContactView = ({ contact, removeContact }) => {
  return (
    <Form
      style={{ paddingTop: "10px" }}
      id="contactView"
      layout="vertical"
      initialValues={contact}
    >
      <Card
        extra={
          <NavLink
            to={
              {
                // pathname: "/contact/ContactList",
              }
            }
          >
            <Button
              onClick={removeContact}
              type="Danger"
              style={{ color: "red", border: "1px solid red" }}
            >
              Delete
            </Button>
          </NavLink>
        }
      >
        <Row gutter={24}>
          <Col span={6}>
            <FormItem label="First name" name={"first_name"}>
              <Input placeholder="First name" disabled />
            </FormItem>
          </Col>

          <Col span={6}>
            <FormItem label="Last name" name={"last_name"}>
              <Input
                placeholder="Last name"
                // onChange={handleInput}
                disabled
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="Display name" name={"display_name"}>
              <Input placeholder="Display name" disabled />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={9}>
            <FormItem label="Email" name={"email"}>
              <Input placeholder="Email" disabled />
            </FormItem>
          </Col>
          <Col span={9}>
            <FormItem label="Alternate Email" name={"alternative_email"}>
              <Input placeholder="Alternate Email" disabled />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <FormItem label="Mobile" name={"mobile"}>
              <Input placeholder="Mobile" disabled />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="Phone (Home)" name={"phone"}>
              <Input placeholder="Phone (Home)" disabled />
            </FormItem>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};
