import { ClockCircleOutlined } from "@ant-design/icons";
import { Badge, Button, Empty, Tooltip } from "antd";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";

export const ListsWidget15 = React.memo(
  ({ className, role, company_job_data = [] }) => {
    const { role: userRole } = useSelector(({ users }) => users);

    const renderJobItem = (item, index) => {
      const detailPath = `/${userRole}/opening-detail`;

      return company_job_data ? (
        company_job_data.map((item, index) => {
          return (
            <tr key={index.toString()} className="candidate-row">
              <td className="candidate-number">{index + 1}</td>
              <td>
                <Link
                  to={{
                    pathname: `${detailPath}/${item._id}`,
                    state: { record: { _id: item._id } },
                  }}
                  className="candidate-name"
                >
                  {item?.opening_title || "-"}
                </Link>
              </td>
              <td className="candidate-job-title">{item?.time || "-"}</td>

              <td className="candidate-job-title">
                {item?.skills.slice(0, 5).join(", ") || "-"}{" "}
                {item?.skills.length > 5 && "..."}
              </td>
              <td>
                <p className="candidate-job-description">
                  {item.description?.slice(0, 199) || "-"}{" "}
                  {item.description?.length > 200 && "..."}
                </p>
              </td>
              <td className="candidate-actions">
                <NavLink
                  to={{
                    pathname: `${detailPath}/${item._id}`,
                    state: { record: { _id: item._id } },
                  }}
                >
                  <Button type="primary" className="view-profile-btn">
                    View Job
                  </Button>
                </NavLink>
              </td>
            </tr>
          );
        })
      ) : (
        <Empty />
      );
    };

    const getRoleHeader = useMemo(() => {
      const headers = {
        admin: "Latest Posted Job",
        company: "Latest Posted Job",
        candidate: "Latest Available Job",
        bdm: "Latest Assigned Job",
        recruiter: "Latest Assigned Job",
        default: "Job Listings",
      };

      return headers[role] || headers.default;
    }, [role]);

    return (
      <div className={`card card-custom ${className}`}>
        <div className="card-header">
          <h3 className="card-title">Latest Jobs</h3>
        </div>
        <div className="card-body">
          <div className="table-container">
            <table className="styled-table">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Opening Title</th>
                  <th>Time</th>
                  <th>Skills</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{renderJobItem()}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
);
