export const degreeOptions = [
  { label: "Bachelor's Degree", value: "Bachelor_Degree" },
  { label: "High School", value: "high_school" },
  { label: "Master's Degree", value: "Master_Degree" },
  { label: "Associate Degree", value: "Associate_Degree" },
  { label: "Doctorate", value: "Doctorate" },
  { label: "Vocational", value: "Vocational" },
  { label: "Higher National Diploma", value: "Higher_National_Diploma" },
  { label: "Diploma", value: "Diploma" },
  { label: "B.tech/b.e", value: "B.tech/b.e" },
  { label: "Ms", value: "Ms" },
];

export const freshnessOptions = [
  { label: "Last 24 Hours", value: 1 },
  { label: "Last 7 Days", value: 7 },
  { label: "Last 30 Days", value: 30 },
  { label: "Last 3 Months", value: 90 },
  { label: "Last 6 Months", value: 180 },
  { label: "Last Year", value: 365 }
];

export const workStatusOptions = [
  { value: "CTNS", label: "Not specified" },
  { value: "CTCT", label: "US Citizen" },
  { value: "CTAY", label: "Can work for any employer" },
  { value: "CTGR", label: "Green Card Holder" },
  { value: "EAEA", label: "Employment Authorization Document" },
  { value: "CTNO", label: "Need H1 Visa Sponsor" },
  { value: "CTEM", label: "Have H1 Visa" },
  { value: "EATN", label: "TN Permit Holder" },
];

export const skillsToFilter = [
  "Management",
  "Customer Service",
  "Sales",
  "Microsoft Excel",
  "Administration",
  "Leadership",
  "Analysis",
  "Microsoft Office",
  "Training",
  "Operations",
];

export const ProfileOptions = [
  { label: "Resume", value: "Resume" },
  { label: "Social", value: "Social" },
];

export const optionSource = [
  { label: "CareerBuilder", value: "CareerBuilder" },
  { label: "Social Aggregator", value: "Social Aggregator" },
  { label: "Google", value: "Google" },
  { label: "Facebook", value: "GitHub" },
  { label: "Gravatar", value: "Gravatar" },
  { label: "Twitter", value: "Twitter" },
  { label: "Pinterest", value: "Pinterest" },
  { label: "Oil & Gas", value: "Oil & Gas" },
  { label: "Licensing Agency", value: "Licensing Agency" },
];

export const statesList = [
  "Texas, United States",
  "California, United States",
  "Florida, United States",
  "Georgia, United States",
  "Illinois, United States",
  "New York, United States",
  "North Carolina, United States",
  "New Jersey, United States",
  "Virginia, United States",
  "Pennsylvania, United States",
  "Alabama, United States",
  "Alaska, United States",
  "Arizona, United States",
  "Arkansas, United States",
  "Colorado, United States",
  "Connecticut, United States",
  "Delaware, United States",
  "Hawaii, United States",
  "Idaho, United States",
  "Illinois, United States",
  "Indiana, United States",
  "Iowa, United States",
  "Kansas, United States",
  "Kentucky, United States",
  "Louisiana, United States",
  "Maine, United States",
  "Maryland, United States",
  "Massachusetts, United States",
  "Michigan, United States",
  "Minnesota, United States",
  "Mississippi, United States",
  "Missouri, United States",
  "Montana, United States",
  "Nebraska, United States",
  "Nevada, United States",
  "New Hampshire, United States",
  "New Mexico, United States",
  "New York, United States",
  "North Carolina, United States",
  "North Dakota, United States",
  "Ohio, United States",
  "Oklahoma, United States",
  "Oregon, United States",
  "Pennsylvania, United States",
  "Rhode Island, United States",
  "South Carolina, United States",
  "South Dakota, United States",
  "Tennessee, United States",
  "Texas, United States",
  "Utah, United States",
  "Vermont, United States",
  "Virginia, United States",
  "Washington, United States",
  "West Virginia, United States",
  "Wisconsin, United States",
  "Wyoming, United States",
];

export const stateAbbreviationToName = {
  TX: "Texas",
  CA: "California",
  FL: "Florida",
  GA: "Georgia",
  IL: "Illinois",
  NY: "New York",
  NC: "North Carolina",
  NJ: "New Jersey",
  VA: "Virginia",
  PA: "Pennsylvania",
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  HI: "Hawaii",
  ID: "Idaho",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NM: "New Mexico",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  UT: "Utah",
  VT: "Vermont",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const Countries = {
  AF: "Afghanistan",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AG: "Antigua and Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BA: "Bosnia and Herzegovina",
  BW: "Botswana",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  VG: "British Virgin Islands",
  BN: "Brunei",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  CV: "Cabo Verde",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo (Congo-Brazzaville)",
  CD: "Congo (Congo-Kinshasa)",
  CK: "Cook Islands",
  CR: "Costa Rica",
  CI: "Côte d'Ivoire",
  HR: "Croatia",
  CU: "Cuba",
  CW: "Curaçao",
  CY: "Cyprus",
  CZ: "Czechia",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  SZ: "Eswatini",
  ET: "Ethiopia",
  FK: "Falkland Islands",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island and McDonald Islands",
  VA: "Vatican City",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KP: "North Korea",
  KR: "South Korea",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Laos",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macau",
  MK: "North Macedonia",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar (formerly Burma)",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestine State",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PN: "Pitcairn Islands",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Romania",
  RU: "Russia",
  RW: "Rwanda",
  BL: "Saint Barthélemy",
  SH: "Saint Helena",
  KN: "Saint Kitts and Nevis",
  LC: "Saint Lucia",
  MF: "Saint Martin",
  PM: "Saint Pierre and Miquelon",
  VC: "Saint Vincent and the Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SX: "Sint Maarten",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia and the South Sandwich Islands",
  SS: "South Sudan",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SX: "Sint Maarten",
  SZ: "Swaziland",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syria",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad and Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks and Caicos Islands",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Vietnam",
  VG: "British Virgin Islands",
  WF: "Wallis and Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};

export const industries = [
  "Computer Systems Design Services",
  "Custom Computer Programming Services,",
  "Commercial Banking",
  "Colleges, Universities, And Professional Schools",
  "Unclassified Establishments",
  "Administrative Management And General Management Consulting Services",
  "Elementary And Secondary Schools",
  "All Other Support Services",
  "General Medical And Surgical Hospitals",
  "Software Publishers",
  "Temporary Help Services",
  "Offices Of Physicians (except Mental Health Specialist)",
];

export const academicDiscipline = [
  "Business Administration and Management, General",
  "Accounting",
  "Education, General",
  "Psychology, General",
  "General Studies",
  "Engineering, General",
  "Law",
  "Computer Engineering, General",
  "Computer Science",
  "Communication, General",
];

export const securityClearances = ["Yes", "No"];

export const Availability = [
  { value: "0", label: "Full-Time Employee" },
  { value: "1", label: "Contractor" },
  { value: "2", label: "Part-Time Employee" },
  { value: "3", label: "Temporary/Seasonal" },
  { value: "4", label: "Intern" },
];

export const contactInfo = ["Email", "Phone"];


export const minimumTravelRequirements = [
  {
    value: "RTNONE", label: "None"
  },
  {
    value: "RT3210", label: "Negligible"
  },
  {
    value: "RT321", label: "Up to 25%"
  }
  ,
  {
    value: "RT32", label: "Up to 50%"
  },
  {
    value: "RT3", label: "Road Warrior"
  }
]

export const languagesSpokenOptions = [
  { value: "LAEN", label: "English" },
  { value: "LACA", label: "Catalán" },
  { value: "LAZH", label: "Chinese-Mandarin" },
  { value: "LACS", label: "Czech" },
  { value: "LADA", label: "Danish" },
  { value: "LANL", label: "Dutch" },
  { value: "LAET", label: "Estonian" },
  { value: "LAEU", label: "Euskera" },
  { value: "LAFI", label: "Finnish" },
  { value: "LAFR", label: "French" },
  { value: "LADE", label: "German" },
  { value: "LAEL", label: "Greek" },
  { value: "LAHE", label: "Hebrew" },
  { value: "LAHU", label: "Hungarian" },
  { value: "LAIS", label: "Icelandic" },
  { value: "LAIT", label: "Italian" },
  { value: "LAJA", label: "Japanese" },
  { value: "LAKO", label: "Korean" },
  { value: "LALV", label: "Latvian" },
  { value: "LALT", label: "Lithuanian" },
  { value: "LANO", label: "Norwegian" },
  { value: "LAPL", label: "Polish" },
  { value: "LAES", label: "Spanish" },
  { value: "LAPT", label: "Portuguese" },
  { value: "LARO", label: "Romanian" },
  { value: "LARU", label: "Russian" },
  { value: "LASV", label: "Swedish" },
  { value: "LAAR", label: "Arabic" },
  { value: "LABN", label: "Bengali" },
  { value: "LAHK", label: "Chinese-Cantonese" },
  { value: "LATW", label: "Chinese-Taiwanese" },
  { value: "LAHI", label: "Hindi" },
  { value: "LAUR", label: "Urdu" },
  { value: "LAHY", label: "Armenian" },
  { value: "LAAS", label: "Assamese" },
  { value: "LAFO", label: "Farsi" },
  { value: "LAGU", label: "Gujarati" },
  { value: "LAKN", label: "Kannada" },
  { value: "LAKS", label: "Kashmiri" },
  { value: "LAML", label: "Malayalam" },
  { value: "LAOR", label: "Oriya" },
  { value: "LAPS", label: "Pashto" },
  { value: "LAPA", label: "Punjabi" },
  { value: "LASA", label: "Sanskrit" },
  { value: "LASD", label: "Sindhi" },
  { value: "LATA", label: "Tamil" },
  { value: "LATE", label: "Telugu" },
  { value: "LATR", label: "Turkish" },
  { value: "LAUZ", label: "Uzbek" },
  { value: "LAFJ", label: "Indonesian" },
  { value: "LAVA", label: "Vasco" },
  { value: "LABU", label: "Bulgarian" },
  { value: "LACR", label: "Croatian" },
  { value: "LAMC", label: "Macedonian" },
  { value: "LASB", label: "Serbian" },
  { value: "LASQ", label: "Albanian" },
  { value: "LACM", label: "Cambodian" },
  { value: "LACH", label: "Chinese-Chinois" },
  { value: "LAKR", label: "Ukranian" },
  { value: "LAVT", label: "Vietnamese" }
];


export const orderByOptions = [
  { value: "+RECENTYEARLYPAY", label: "Most Recent Pay (ascending)" },
  { value: "-RECENTYEARLYPAY", label: "Most Recent Pay (descending)" },
  { value: "+MODIFIEDINT", label: "Freshness (ascending)" },
  { value: "-MODIFIEDINT", label: "Freshness (descending)" },
  { value: "+RELV", label: "Relevance (ascending)" },
  { value: "-RELV", label: "Relevance (descending)" }
];


export const militaryExperienceOptions = [
  { value: "M1", label: "Active Duty" },
  { value: "M2", label: "Reservist-Drilling" },
  { value: "M3", label: "National Guard-Drilling" },
  { value: "M4", label: "Inactive Reserve" },
  { value: "M5", label: "Inactive National Guard" },
  { value: "M6", label: "Retired Military" },
  { value: "M7", label: "Veteran" }
];
export const nicheInclusionOptions = [
  { value: "CC", label: "CareerRookie" },
  { value: "SF", label: "StaffNurse" },
  { value: "SG", label: "Sologig" },
  { value: "UG", label: "CareerBuilder & Sologig, combined" },
  { value: "WM", label: "Miracle Workers" },
  { value: "WR", label: "Work In Retail" },
  { value: "WW", label: "Wounded Warrior" },
  { value: "RX", label: "Recrulex" },
  { value: "LJ", label: "Les Jeudis" },
  { value: "CE", label: "CAO Emplois" },
  { value: "ER", label: "E-recrut" },
  { value: "JS", label: "Job Scout" },
  { value: "CY", label: "Cyprus" }
];

export const searchRadiusInKilometersOptions = [
  { value: "0", label: "0 kilometer" },
  { value: "1", label: "1 kilometer" },
  { value: "2", label: "2 kilometers" },
  { value: "3", label: "3 kilometers" },
  { value: "4", label: "4 kilometers" },
  { value: "5", label: "5 kilometers" },
  { value: "10", label: "10 kilometers" },
  { value: "15", label: "15 kilometers" },
  { value: "20", label: "20 kilometers" },
  { value: "25", label: "25 kilometers" },
  { value: "30", label: "30 kilometers" },
  { value: "40", label: "40 kilometers" },
  { value: "50", label: "50 kilometers" },
  { value: "75", label: "75 kilometers" },
  { value: "100", label: "100 kilometers" },
  { value: "125", label: "125 kilometers" },
  { value: "150", label: "150 kilometers" }
];

export const searchRadiusInMilesOptions = [
  { value: "0", label: "0 mile" },
  { value: "1", label: "1 mile" },
  { value: "2", label: "2 miles" },
  { value: "3", label: "3 miles" },
  { value: "4", label: "4 miles" },
  { value: "5", label: "5 miles" },
  { value: "10", label: "10 miles" },
  { value: "15", label: "15 miles" },
  { value: "20", label: "20 miles" },
  { value: "25", label: "25 miles" },
  { value: "30", label: "30 miles" },
  { value: "40", label: "40 miles" },
  { value: "50", label: "50 miles" },
  { value: "75", label: "75 miles" },
  { value: "100", label: "100 miles" },
  { value: "125", label: "125 miles" },
  { value: "150", label: "150 miles" }
];
export const relocationFilterOptions = [
  { value: "RS", label: "Willing to relocate to state specified in search" },
  { value: "RC", label: "Willing to relocate to city specified in search" },
  { value: "RN", label: "Willing to relocate to nation specified in search" },
  { value: "RA", label: "Willing to relocate to all of the above" }
];
export const freshnessInDaysOptions = [
  { value: "0", label: "Midnight EST-Present" },
  { value: "1", label: "Since yesterday" },
  { value: "7", label: "Last 7 days" },
  { value: "30", label: "Last 30 days" },
  { value: "90", label: "Last 90 days" },
  { value: "180", label: "Last 180 days" },
  { value: "270", label: "Last 270 days" },
  { value: "365", label: "Last year" }
];
export const employmentTypeOptions = [
  { value: "All", label: "All" },
  { value: "ETFE", label: "Full-Time Employee" },
  { value: "ETPE", label: "Part-Time Employee" },
  { value: "ETCT", label: "Contractor" },
  { value: "ETIN", label: "Intern" }
];
