import axios from "axios";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useCallback, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import {
  ListsWidget15,
  MixedWidget16,
} from "../../_theme_parts/_partials/widgets";
import { AdvanceTablesWidget6 } from "../../_theme_parts/_partials/widgets/advance-tables/AdvanceTablesWidget6";
import { CATEGORY_LIST, DASHBOARD_USER } from "../../ApiUrl";
import {
  candidateChartOptions,
  getDashboardChartData,
} from "../../assets/data/candidateChart.data";
import { setCategory } from "../../redux/actions/common";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController
);

export function DashboardPage(props) {
  const [dashBoardData, setDashBoardData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [chartData, setChartData] = useState({
    candidates: [],
    clients: [],
    jobs: [],
  });

  const users = useSelector(({ users }) => users);
  const common = useSelector(({ common }) => common);
  const role = users.role;
  const dispatch = useDispatch();

  const fetchDashboardData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const [categoriesResponse, dashboardResponse] = await Promise.all([
        axios.get(CATEGORY_LIST, {
          headers: { Authorization: users.token },
        }),
        axios.post(
          DASHBOARD_USER,
          {
            role: role,
            id: role !== "admin" ? users.user._id : "",
          },
          {
            headers: { Authorization: users.token },
          }
        ),
      ]);

      // Handle categories
      if (categoriesResponse.data) {
        dispatch(setCategory(categoriesResponse.data.data));
      }

      // Handle dashboard data
      if (dashboardResponse.data && !dashboardResponse.data.error) {
        setDashBoardData(dashboardResponse.data.data);
        setChartData({
          candidates: dashboardResponse.data.data.chart_data.candidates,
          clients: dashboardResponse.data.data.chart_data.clients,
          jobs: dashboardResponse.data.data.chart_data.jobs,
        });
      } else {
        setError("Failed to fetch dashboard data");
      }
    } catch (err) {
      console.error("Dashboard data fetch error:", err);

      // Handle unauthorized access
      if (err.response && err.response.status === 401) {
        props.history.push("/logout");
      } else {
        setError("An error occurred while fetching dashboard data");
      }
    } finally {
      setLoading(false);
    }
  }, [users, role, dispatch, props.history]);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  // Compute derived data with null checks
  const dashboard_statistics_count =
    dashBoardData?.dashboard_statistics_count || [];
  const company_job_data = dashBoardData
    ? role === "freelancerecruiter"
      ? dashBoardData.latest_five_job_approved
      : dashBoardData.dashboard_statistics_data
    : [];
  const latest_five_candidate = dashBoardData?.latest_five_candidate || [];

  // Render loading state
  if (loading) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "50vh" }}
      >
        <div className="spinner spinner-primary"></div>
      </div>
    );
  }
  return (
    <section className="v2_dasboard_container">
      <div className="v2_dasboard_container_chart_container">
        <div className="left_container">
          <div className="big_chart">
            <div className="big_chart_header">
              <h3>Overview</h3>
              {/* Custom Legends */}
              <div className="custom_legend">
                {getDashboardChartData().datasets.map((dataset, index) => (
                  <div key={index} className="legend_item">
                    <span
                      style={{ backgroundColor: dataset.borderColor }}
                    ></span>
                    <p>{dataset.label}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="chandidateChartDiv">
              <Line
                options={candidateChartOptions}
                data={getDashboardChartData(
                  chartData.candidates,
                  chartData.clients,
                  chartData.jobs
                )}
              />
            </div>
          </div>
          {role && role !== "candidate" && latest_five_candidate.length > 0 && (
            <div className="mt-6">
              <AdvanceTablesWidget6
                role={role}
                latest_five_candidate={latest_five_candidate.slice(0, 5)}
                className="card-stretch gutter-b"
              />
            </div>
          )}
          {company_job_data && company_job_data.length > 0 && (
            <div className="mt-6">
              <ListsWidget15
                role={role}
                company_job_data={company_job_data}
                className="card-stretch gutter-b"
              />
            </div>
          )}
        </div>
        <div className="right_container">
          <MixedWidget16
            role={role}
            dashboard_statistics_count={dashboard_statistics_count}
          />
        </div>
      </div>
    </section>
  );
}

export default DashboardPage;
