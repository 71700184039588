import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Card, Row, Col, List, Spin } from "antd";
import { Table } from "ant-table-extensions";
import { RECRUITER_REPORT_JOBS } from "../../../../../../ApiUrl";

import { OpeningStatusList } from "../../../../../pages/constant/constant";
import useReportData from "../../../../../../hooks/useReportData";

function RecruiterJobReport(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [chartStatus, setChartStatus] = useState("");

  const initialParams = {
    current_page: currentPage,
    per_page: "10",
    sort_order: "desc",
    filter_value: "",
    order: "created_at",
    dateRange: props.arrayDateRange,
    categories: [],
    status: props.statusLabal,
    company_id: "",
    bdm_id: props.bdm_id,
    recruiter_id: props.recruiter_id,
    id: props.recruiter_id,
  };

  const { data: openings, loading, error, updateParams } = useReportData(
    RECRUITER_REPORT_JOBS,
    initialParams
  );

  // useEffect(() => {
  //   props.setRecruiterTabSelected(true);
  //   return () => props.setRecruiterTabSelected(false);
  // }, []);

  useEffect(() => {
    updateParams({
      current_page: currentPage,
      status: chartStatus
        ? OpeningStatusList.find((status) => status.label === chartStatus)
            ?.value
        : props.statusLabal,
    });
  }, [currentPage, chartStatus, props.statusLabal]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const columns = [
    {
      title: "Opening Title",
      dataIndex: "opening_title",
      key: "opening_title",
    },
    {
      title: "Opening id",
      dataIndex: "opening_id",
      key: "opening_id",
    },
    {
      title: "Working Recruiter",
      dataIndex: "recruiterDetails",
      key: "recruiterDetails",
      render: (text, record) => {
        return record?.recruiterDetails
          ?.map((recruiter) => recruiter?.display_name)
          .join(", ");
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (Code) => {
        return OpeningStatusList.map((status, index) => {
          return status.value === Code ? status.label : "";
        });
      },
    },
  ];

  const newSeries = [];
  const newLabals = [];

  if (props && props.recruiterJobCount) {
    if (props.recruiterJobCount.Active) {
      newSeries.push(props.recruiterJobCount.Active);
      newLabals.push("Active");
    }
    if (props.recruiterJobCount.oh) {
      newSeries.push(props.recruiterJobCount.oh);
      newLabals.push("On Hold");
    }
    if (props.recruiterJobCount.I) {
      newSeries.push(props.recruiterJobCount.I);
      newLabals.push("Interview");
    }
    if (props.recruiterJobCount.rs) {
      newSeries.push(props.recruiterJobCount.rs);
      newLabals.push("Resume Screening");
    }
    if (props.recruiterJobCount.offer) {
      newSeries.push(props.recruiterJobCount.offer);
      newLabals.push("Offer");
    }
    if (props.recruiterJobCount.ri) {
      newSeries.push(props.recruiterJobCount.ri);
      newLabals.push("Closed");
    }
  }

  const data = {
    series: newSeries,
    options: {
      chart: {
        type: "donut",
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const submissionStatus = OpeningStatusList.find((status) => {
              return (
                status.label === config.w.config.labels[config.dataPointIndex]
              );
            });
            setChartStatus(
              submissionStatus
                ? submissionStatus.label
                : config.w.config.labels[config.dataPointIndex]
            );
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      labels: newLabals,
    },
  };

  const DoughnutChart = () => (
    <Spin spinning={loading}>
      <Card title={"All Jobs"}>
        <Row gutter={24}>
          <Col span={12}>
            <div id="chart">
              <Chart
                options={data.options}
                series={data.series}
                type="donut"
                width="380"
              />
            </div>
          </Col>
          <Col span={12}>
            <List
              dataSource={newLabals}
              itemLayout={"horizontal"}
              bordered={false}
              renderItem={(item, index) => (
                <List.Item
                  style={{ "border-bottom": 0, padding: "5px 0" }}
                  key={index.toString()}
                >
                  <Col span={18} style={{ textAlign: "right" }}>
                    {item} :
                  </Col>
                  <Col span={6}>{newSeries[index]}</Col>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Card>
      <br />
    </Spin>
  );

  const fields = {
    opening_id: {
      header: "Opening id",
      formatter: (_fieldValue, record) => {
        return record?.opening_id;
      },
    },
    opening_title: {
      header: "Opening Title",
      formatter: (_fieldValue, record) => {
        return record?.opening_title;
      },
    },
    status: {
      header: "Status",
      formatter: (_fieldValue, record) => {
        const status = OpeningStatusList.find((status) => {
          return status.value === record.status;
        });
        return status ? status.label : record.status;
      },
    },
  };

  const openingData = () => (
    <>
      <Card title="JOB Data" bordered={false} className="px-0 py-0">
        <Table
          loading={loading}
          pagination={{
            current: currentPage,
            total: openings?.totalRecords || 0,
            pageSize: 10,
            onChange: handlePageChange,
          }}
          dataSource={openings?.job_opening_listing || []}
          columns={columns}
          exportableProps={{ fields, fileName: "JOB Data" }}
          scroll={{
            x: "auto",
          }}
        />
      </Card>
    </>
  );
  if (error) return <div>Error: {error.message}</div>;
  if (loading) return <Spin spinning={loading}></Spin>;
  const jobsOpnings = openings.job_opening_listing;
  const totalRecords = openings.totalRecords;

  return (
    <>
      {DoughnutChart()} {openingData()}
    </>
  );
}

export default RecruiterJobReport;
