import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";

// Routing configuration based on role
const getRoutePaths = (role) => {
  const baseRoutes = {
    admin: {
      path1: "/admin/ClientsList",
      path2: "/current-openings",
      path3: "/user/list/all",
      path4: "/user/list/active",
    },
    company: {
      path1: "/company/job-openings",
      path2: "/company/job-openings",
      path3: "/company/job-openings",
      path4: "/company/job-openings",
    },
    candidate: {
      path1: "/candidate/assign-jobs",
      path2: "/candidate/userWiseCandidateSubmission/all",
      path3: "/candidate/userWiseCandidateSubmission/submit",
      path4: "/candidate/userWiseCandidateSubmission/interview",
    },
    bdm: {
      path1: "/bdm/assign-jobs",
      path2: "/bdm/userWiseCandidateSubmission/all",
      path3: "/bdm/userWiseCandidateSubmission/submit",
      path4: "/bdm/userWiseCandidateSubmission/interview",
    },
    recruiter: {
      path1: "/recruiter/assign-jobs",
      path2: "/recruiter/userWiseCandidateSubmission/all",
      path3: "/recruiter/userWiseCandidateSubmission/submit",
      path4: "/recruiter/userWiseCandidateSubmission/interview",
    },
  };

  return baseRoutes[role] || baseRoutes.admin;
};

export const MixedWidget16 = React.memo(
  ({ className = "", role, dashboard_statistics_count = [] }) => {
    return (
      <ul className="dashboard_statistics_count_container">
        {dashboard_statistics_count.map((stat, index) => {
          const iconUrl = `/media/svg/icons/Extra/${
            index === 0
              ? "clients"
              : index === 1
              ? "job-seeker-v2"
              : index === 2
              ? "user"
              : "user-employee"
          }.png`;

          const url = getRoutePaths(role)[`path${index + 1}`];

          //TODO: Remove hardcoded title from here; and handle it from backend
          const title =
            stat.title !== "Total Users"
              ? stat.title.startsWith("Total")
                ? stat.title.split("Total")[1]
                : stat.title
              : stat.title;
          return (
            <li key={index} className="dashboard_statistics_count">
              <div className="titleContainer">
                <img src={toAbsoluteUrl(iconUrl)} width="30px" />
                <h4>
                  <Link to={url} className="text-dark">
                    {title || "Stat"}
                  </Link>
                </h4>
              </div>
              <div className="counter_div">
                <h3 className="counter">{stat.count || "N/A"}</h3>
              </div>
            </li>
          );
        })}
      </ul>
    );
  }
);
