import {
  Card,
  Checkbox,
  Dropdown,
  Form,
  InputNumber,
  Menu,
  Modal,
  Select,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
  Input,
  Button,
  Space,
  Row,
  Col,
  Pagination,
  Radio,
  Spin,
} from "antd";
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import {
  SearchOutlined,
  FilterOutlined,
  PlusOutlined,
  ColumnHeightOutlined,
  EyeOutlined,
  MoreOutlined,
  FileTextOutlined,
  SendOutlined,
  MessageOutlined,
  TableOutlined,
  DeleteOutlined,
  EditOutlined,
  LinkedinOutlined,
  MailOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MailTwoTone,
  DownloadOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import axios from "axios";
import {
  CANDIDATE_NOTES,
  CANDIDATE_NOTE_DELETE,
  CANDIDATE_NOTE_UPDATE,
  CANDIDATE_UPDATE_PARTIALLY,
  CREATE_OR_UPDATE_PREFERENCE,
  EMAIL_TEMPLATES_CREATE,
  EMAIL_TEMPLATES_UPDATE,
  IMAGE_CANDIDATE_URL,
  JOB_ASSIGNMENT_DETAILS,
  JOB_DETAIL,
  PREFERENCE_LIST,
  SEARCH_CONTACTS,
  SEND_BULK_EMAIL,
  SENT_LINKEDIN_INVITATION,
  USER_EMAIL_TEMPLATES,
} from "../../../ApiUrl";
import { requestHeader } from "../../../others/header";
import { Link, NavLink, useLocation } from "react-router-dom";
import "../../../CSS/table.css";
import TextArea from "antd/lib/input/TextArea";
import { showError } from "../utils";
import PdfViewer from "../../modules/others/pdfViewer";
import { GlobalSettings } from "../../modules/DynamicList/Settings";
import { useCallAPI } from "../../../hooks/useCallAPI";
import Notes from "../../modules/Modals/Notes";
import { useDispatch, useSelector } from "react-redux";
import { CustomSnackbar } from "../../modules/others/Snackbar";
import { EmailOutlined, List, ReplyOutlined } from "@material-ui/icons";

import {
  applyFilters,
  fetchCandidates,
  setSearch,
  setSelectedCandidateIds,
  clearCandidateLabel,
  setCandidateLabel,
  setListView,
  setPage,
  setPageSize,
  setSelectedFilters,
  deleteCandidates,
  setShouldRefresh,
  setSortBy,
  setSortOrder,
  addCandidatesToList,
  createList,
  updateList,
  fetchLists,
  submitCandidates,
  setFilteredList,
  selectCandidateLabel,
} from "../../../redux/reducers/candidatesSlice";
import CandidateListManagement from "./components/CandidateListManagement";
import { CandidateCard } from "./components/CandidateCard";
import ExpandableContent from "../../../others/utils/ExpandableContent";

import CandidateSort from "./components/CandidateSort";
import JobListPagination from "./components/CandidatePagination";
import CandidateEmail from "./components/CandidateEmail";
import CandidateLinkedinInvitation from "./components/CandidateLinkedinInvitation";

export default function CandidateDynamicList2({ type = "admin" }) {
  const users = useSelector(({ users }) => users);
  const location = useLocation();
  const { record } = location.state || {};
  console.log("record", record);
  const rolePath =
    users.role === "recruitmentmanager"
      ? "recruitment-manager"
      : users.role === "bdmmanager"
      ? "bdm-manager"
      : users.role;
  const dispatch = useDispatch();
  const {
    list: candidateList,
    filteredList: filteredCandidates,
    loading,
    error,
    page,
    totalData,
    currentPage,
    pageSize: reduxPageSize,
    search,
    selectedCandidateIds,
    listView,
    bestCandidates,
    goodCandidates,
    qualifiedCandidates,
    selectedFilters,
    deleteStatus,
    deleteError,
    shouldRefresh,
    sortBy,
    sortOrder,
    lists,
    currentListId,
    submitting,
  } = useSelector((state) => state.candidates);
  const pageSize = reduxPageSize || 10;
  const [showCV, setShowCv] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [cvPath, setCVPath] = useState("");
  const [emailData, setEmailData] = useState({
    to: [],
    subject: "",
    body: "",
    candidateId: "",
  });
  const [openDltModal, setOpenDltModal] = useState(false);
  const [idsToDelete, setIdsToDelete] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEmailHistoryModalVisible, setIsEmailHistoryModalVisible] = useState(
    false
  );
  const [selectedEmailHistoryRecord, setSelectedEmailHistoryRecord] = useState(
    null
  );

  const handleChatClick = (emailHistory) => {
    setSelectedEmailHistoryRecord(emailHistory);
    setIsEmailHistoryModalVisible(true);
  };

  const handleModalClose = () => {
    setIsEmailHistoryModalVisible(false);
    setSelectedEmailHistoryRecord(null);
  };
  const [listName, setListName] = useState("");
  const [editingListId, setEditingListId] = useState(null);
  const candidateLabels = useMemo(() => {
    const labels = {};
    for (const candidate of filteredCandidates) {
      const id = candidate.candidate._id;
      if (bestCandidates.includes(id)) {
        labels[id] = "top";
      } else if (goodCandidates.includes(id)) {
        labels[id] = "good";
      } else if (qualifiedCandidates.includes(id)) {
        labels[id] = "qualified";
      } else {
        labels[id] = "";
      }
    }
    return labels;
  }, [filteredCandidates, bestCandidates, goodCandidates, qualifiedCandidates]);

  const handleLabelChange = useCallback(
    (candidateId, label) => {
      dispatch(
        clearCandidateLabel({ candidateId, labelType: "bestCandidates" })
      );
      dispatch(
        clearCandidateLabel({ candidateId, labelType: "goodCandidates" })
      );
      dispatch(
        clearCandidateLabel({ candidateId, labelType: "qualifiedCandidates" })
      );

      if (label === "top") {
        dispatch(
          setCandidateLabel({ candidateId, labelType: "bestCandidates" })
        );
      } else if (label === "good") {
        dispatch(
          setCandidateLabel({ candidateId, labelType: "goodCandidates" })
        );
      } else if (label === "qualified") {
        dispatch(
          setCandidateLabel({ candidateId, labelType: "qualifiedCandidates" })
        );
      }

      // Force a re-render of the table by updating the filteredList
      dispatch(setFilteredList([...filteredCandidates]));
    },
    [dispatch, filteredCandidates]
  );
  const allColumns = useMemo(
    () => [
      {
        // title: "Opening Title",
        dataIndex: ["candidate", "_id"],
        key: "notes",
        // show: columnVisibility.notes,
        // fixed: "left",
        mandatory: true,
        key: () => {
          this.dataIndex + 1;
        },
        render: (data, record) => {
          // return date ? getFormatDate(date) : "";
          // console.log('get notes in column', record,);
          return (
            <Button
              onClick={() => {
                getNotes(record);
              }}
            >
              N
            </Button>
          );
        },
        // width: " .4%",
      },
      {
        title: "Display Name",
        // show: columnVisibility.displayName,
        key: "displayName",
        mandatory: true,
        dataIndex: ["candidate", "first_name"],
        key: () => {
          this.dataIndex + 2;
        },
        render: (text, records) => {
          return (
            <Space style={{ width: "max-content" }}>
              <Space size="middle">
                <NavLink
                  to={{
                    pathname: `/${type}/candidateProfileDetail`,
                    state: {
                      item: {
                        ...records.candidate,
                        opening_id: record.opening_id,
                      },
                    },
                  }}
                >
                  <p>
                    {records.candidate.first_name} {records.candidate.last_name}
                  </p>
                </NavLink>
              </Space>

              {records?.candidate?.attachments ? (
                <Button
                  onClick={() => setShowCv(true)}
                  icon={<FileTextOutlined />}
                />
              ) : null}

              {setCVPath(
                `${process.env.REACT_APP_BASE_URL}/upload/candidate/${records.candidate.attachments}`
              )}
            </Space>
          );
        },
      },
      {
        title: "Email",
        // show: columnVisibility.email,
        key: "email",
        mandatory: true,

        dataIndex: ["candidate", "email"],
        key: () => {
          this.dataIndex + 19;
        },
        sorter: true,
        render: (text, records) => {
          // console.log(records.candidate);
          return (
            <span>
              <span style={{ marginRight: "10px" }}>
                {records?.candidate?.email?.split(",").map((email, index) => (
                  <Typography.Text key={index} copyable>
                    {email.trim()}
                  </Typography.Text>
                ))}
              </span>

              {isValidEmails(records.candidate.email) ? (
                <Button
                  onClick={() => {
                    onClickSendEmail(records);
                  }}
                  icon={<SendOutlined />}
                />
              ) : null}
            </span>
          );
        },
      },
      {
        title: "Email Status",
        // show: columnVisibility.emailStatus,
        key: "emailStatus",
        mandatory: true,
        dataIndex: ["candidate", "email"],
        key: () => {
          this.dataIndex + 3;
        },
        render: (text, records) => {
          const hasSent = records?.emailHistory?.some((email) => email.is_sent);
          // Check if emailHistory has any object with is_open as true
          const hasOpen = records?.emailHistory?.some((email) => email.is_open);
          // Check if emailHistory has any object with is_replied as true
          const hasReplied = records?.emailHistory?.some(
            (email) => email.is_replied
          );
          // Check if emailHistory has any object with is_bounced as true
          const hasBounced = records?.emailHistory?.some(
            (email) => email.is_bounced
          );

          return (
            <span>
              <span style={{ marginRight: "10px" }}>
                {hasSent ? (
                  <Typography.Text>
                    Send <CheckCircleOutlined style={{ color: "green" }} />
                  </Typography.Text>
                ) : (
                  <Typography.Text>
                    Send <CloseCircleOutlined style={{ color: "red" }} />
                  </Typography.Text>
                )}
              </span>
              <span style={{ marginRight: "10px" }}>
                {hasOpen ? (
                  <Typography.Text>
                    Opened <MailTwoTone twoToneColor="#52c41a" />
                  </Typography.Text>
                ) : null}
              </span>
              <span style={{ marginRight: "10px" }}>
                {hasReplied ? (
                  <Typography.Text>
                    Replied <ReplyOutlined style={{ color: "#52c41a" }} />
                  </Typography.Text>
                ) : null}
              </span>
              <span style={{ marginRight: "10px" }}>
                {hasBounced ? (
                  <Typography.Text>
                    Bounced <CheckCircleOutlined style={{ color: "#52c41a" }} />
                  </Typography.Text>
                ) : null}
              </span>
              {hasReplied && (
                <Button
                  onClick={() => {
                    handleChatClick(records.emailHistory);
                  }}
                  icon={<MessageOutlined />}
                />
              )}
            </span>
          );
        },
      },
      {
        title: "Mobile",
        // show: columnVisibility.mobile,
        key: "mobile",
        mandatory: true,
        dataIndex: ["candidate", "mobile"],
        key: () => {
          this.dataIndex + 4;
        },
        sorter: true,
        render: (text, records) => (
          <>
            {records.candidate?.mobile?.split(",").map((mobile, index) => (
              <Typography.Text key={index} copyable>
                {" "}
                {mobile}
              </Typography.Text>
            ))}
          </>
        ),
      },
      {
        title: "Experience (yrs)",
        // show: columnVisibility.experience,
        key: "experience",
        mandatory: false,
        dataIndex: ["candidate", "total_work_exp_year"],
        key: () => {
          this.dataIndex + 5;
        },
        render: (text, records) => (
          <span>
            {records.candidate.total_work_exp_year
              ? records.candidate.total_work_exp_year
              : "0"}{" "}
            years{" "}
            {records.candidate.total_work_exp_month
              ? records.candidate.total_work_exp_month
              : "0"}{" "}
            months
          </span>
        ),
      },
      {
        title: "Profile Summary",
        // show: columnVisibility.profileSummary,
        key: "profileSummary",
        mandatory: false,
        dataIndex: ["candidate", "profile_summary"],
        key: () => {
          this.dataIndex + 6;
        },
        render: (text, records) => (
          <ExpandableContent
            content={records.candidate.profile_summary}
            maxHeight={48}
            showLessText="Read Less"
            showMoreText="Read More"
          />
        ),
      },
      {
        title: "Gender",
        // show: columnVisibility.gender,
        key: "gender",
        mandatory: false,
        dataIndex: ["candidate", "gender"],
        key: () => {
          this.dataIndex + 7;
        },
      },
      {
        title: "Home Town",
        // show: columnVisibility.homeTown,
        key: "homeTown",
        mandatory: false,
        dataIndex: ["candidate", "home_town"],
        key: () => {
          this.dataIndex + 8;
        },
      },
      {
        title: "Permanent Address",
        // show: columnVisibility.permanentAddress,
        key: "permanentAddress",
        mandatory: false,
        dataIndex: ["candidate", "permanent_address"],
        key: () => {
          this.dataIndex + 9;
        },
      },
      {
        title: "Desired Job",
        // show: columnVisibility.desiredJob,
        key: "desiredJob",
        mandatory: false,
        dataIndex: ["candidate", "desired_job_type"],
        key: () => {
          this.dataIndex + 10;
        },
      },
      {
        title: "Location",
        // show: columnVisibility.location,
        key: "location",
        mandatory: false,
        sorter: true,
        dataIndex: ["candidate", "current_location"],
        key: () => {
          this.dataIndex + 11;
        },
      },
      {
        title: "Job Category",
        // show: columnVisibility.jobCategory,
        key: "jobCategory",
        mandatory: true,
        dataIndex: ["candidate", "job_category"],
        key: () => {
          this.dataIndex + 12;
        },
      },
      {
        title: "CTC",
        // show: columnVisibility.ctc,
        key: "ctc",
        mandatory: false,
        dataIndex: ["candidate", "current_ctc"],
        key: () => {
          this.dataIndex + 13;
        },
      },
      {
        title: "LinkedIn Invitation",
        // show: columnVisibility.linkedInInvitation,
        key: "linkedInInvitation",
        mandatory: false,
        dataIndex: ["candidate", "linkedIn_invitation_status"],
        key: () => {
          this.dataIndex + 14;
        },
        render: (text, records) => {
          const currentUserId = users.user._id;
          const invitationStatusArray =
            records.candidate.linkedIn_invitation_status || [];
          const invitationStatus = invitationStatusArray.find(
            (status) => status.sent_by === currentUserId
          );
          return invitationStatus
            ? invitationStatus.status
            : "No Invitation Sent";
        },
      },
      {
        title: "Tags",
        // show: columnVisibility.tags,
        key: "tags",
        mandatory: true,
        dataIndex: ["candidate", "tags"],
        key: () => {
          this.dataIndex + 15;
        },
        render: (text, records) => (
          <span>
            {records.candidate.tags
              ? records.candidate.tags.map((tag) => (
                  <Tag key={tag} color="blue">
                    {tag}
                  </Tag>
                ))
              : null}
          </span>
        ),
      },
      {
        title: "Date of Birth",
        // show: columnVisibility.dateOfBirth,
        mandatory: false,
        dataIndex: ["candidate", "date_of_birth"],
        key: () => {
          this.dataIndex + 16;
        },
      },
      {
        title: "LinkedIn",
        // show: columnVisibility.linkedIn,
        key: "linkedin",
        mandatory: false,
        dataIndex: ["candidate", "meta", "linkedin"],
        key: () => {
          this.dataIndex + 17;
        },
        sorter: true,
        render: (text, records) => (
          <span>
            {records.candidate.meta?.linkedin ? (
              <a href={records.candidate.meta?.linkedin} target="_blank">
                <Button type="primary" icon={<EyeOutlined />} />
              </a>
            ) : null}
          </span>
        ),
      },
      {
        title: "Resume",
        // show: columnVisibility.linkedIn,
        key: "attachments",
        mandatory: false,
        dataIndex: ["candidate", "attachments"],
        key: () => {
          this.dataIndex + 18;
        },
        sorter: true,
        render: (text, records) => (
          <Button
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.REACT_APP_BASE_URL}/upload/candidate/${records.candidate.attachments}`;
              link.setAttribute("download", "resume.pdf");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
            icon={<DownloadOutlined />}
            disabled={!records.candidate.attachments}
            type="primary"
            className="mr-5"
          >
            Download
          </Button>
        ),
      },
      {
        title: "Action",
        dataIndex: ["candidate", "_id", "action"],
        // show: columnVisibility.action,
        mandatory: true,
        key: "action",
        render: (text, records) => {
          const candidateLabel = candidateLabels[records.candidate._id];
          return (
            <Space direction="vertical">
              <Space size="middle">
                <NavLink
                  to={{
                    pathname: `/${rolePath}/edit-candidate`,
                    state: {
                      item: {
                        ...records.candidate,
                        opening_id: record.opening_id,
                      },
                    },
                  }}
                >
                  <Button type="primary" icon={<EditOutlined />} />
                </NavLink>
                <NavLink
                  to={{
                    pathname: `/${type}/candidateProfileDetail`,
                    state: {
                      item: {
                        ...records.candidate,
                        opening_id: record.opening_id,
                      },
                    },
                  }}
                >
                  <Button type="primary" icon={<EyeOutlined />} />
                </NavLink>
                <Button
                  type="danger"
                  icon={<DeleteOutlined />}
                  onClick={() => openDeleteModal(records.candidate._id)}
                />
              </Space>
              <Space>
                <Radio.Group
                  value={candidateLabel}
                  onChange={(e) =>
                    handleLabelChange(records.candidate._id, e.target.value)
                  }
                  size="small"
                >
                  <Radio.Button value="top">Best</Radio.Button>
                  <Radio.Button value="good">Good</Radio.Button>
                  <Radio.Button value="qualified">Qualified</Radio.Button>
                </Radio.Group>
              </Space>
            </Space>
          );
        },
      },
    ],
    [type, record, selectCandidateLabel, handleLabelChange, users.role]
  );
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const initialVisibility = {};
    allColumns.forEach((col) => {
      initialVisibility[col.key] = col.mandatory || false;
    });
    return initialVisibility;
  });
  const visibleColumns = useMemo(() => {
    return allColumns.filter(
      (column) => column.mandatory || columnVisibility[column.key]
    );
  }, [allColumns, columnVisibility]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isListModalVisible, setIsListModalVisible] = useState(false);
  const [showLinkedInInvitaion, setShowLinkedInInvitaion] = useState(false);

  const [selectionType, setSelectionType] = useState("checkbox");

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showNotes, setShowNotes] = useState(false);

  const NotesData = useCallAPI(CANDIDATE_NOTES);
  const UpdateCandidate = useCallAPI(CANDIDATE_UPDATE_PARTIALLY);
  const UpdateNotes = useCallAPI(CANDIDATE_NOTE_UPDATE);
  const DeleteNotes = useCallAPI(CANDIDATE_NOTE_DELETE);
  const SendBulkEmail = useCallAPI(SEND_BULK_EMAIL);
  const SearchContact = useCallAPI(SEARCH_CONTACTS);
  const [companyId, setCompanyId] = useState("");
  const [bdmId, setBdmId] = useState("");
  const [globalLoading, setGlobalLoading] = useState({
    linkedInInvitation: false,
  });
  const [globalSuccess, setGlobalSuccess] = useState({
    status: false,
    message: null,
  });
  const [globalError, setGlobalError] = useState({
    status: false,
    message: null,
  });

  const searchContact = () => {
    const params = selectedRows?.map((row) => ({
      candidateId: row.candidate._id,
      firstName: row.candidate.first_name,
      middleName: row.candidate.middle_name,
      lastName: row.candidate.last_name,
      address: row.candidate.current_location,
    }));
    SearchContact.post({ search: params });
  };

  const onClickSendEmail = (records) => {
    let emails = [];
    
    if (records?.candidate) {
        emails = records.candidate.email.split(",").map((email) => email.trim());
        setSelectedRows([records]); // Add this line to ensure selectedRows is set for single selection
    } else if (selectedRows && selectedRows.length > 0) {
        emails = selectedRows.flatMap((row) =>
            row.candidate.email.split(",").map((email) => email.trim())
        );
    }

    setEmailData({ ...emailData, to: emails });
    setShowEmailForm(true);
};

  const getNotes = (record) => {
    if (record?.candidate?._id) {
      NotesData.get(record?.candidate?._id);
      setSelectedRow(record?.candidate);
      setShowNotes(true);
    }
  };

  useEffect(() => {
    if (record) {
      getOpeningDetail();
    }
  }, [record]);

  const getListPreference = () => {
    axios
      .get(PREFERENCE_LIST, requestHeader())
      .then((response) => {
        const updatedColumns = columns?.map((column) => {
          const preference = response.data.data.candidate_preference.find(
            (pref) =>
              JSON.stringify(pref.title) === JSON.stringify(column.title)
          );
          if (preference) {
            return {
              ...column,
              show: column.mandatory ? true : preference.show,
            };
          }
          return column;
        });

        setColumns(updatedColumns);
      })
      .catch((error) => {});
  };
  const handleListPreference = () => {
    setIsModalVisible(false);
    const updatedPreference = allColumns?.map((col) => ({
      title: col.title,
      show: col.mandatory ? true : columnVisibility[col.key],
      dataIndex: col.dataIndex,
    }));

    axios
      .post(
        CREATE_OR_UPDATE_PREFERENCE,
        {
          candidate_preference: updatedPreference,
        },
        requestHeader()
      )
      .then((response) => {})
      .catch((error) => {});
  };
  const getOpeningDetail = () => {
    if (record) {
      axios
        .get(JOB_DETAIL + "/" + record._id, {
          headers: { Authorization: users.token },
        })
        .then((res) => {
          setCompanyId(res.data.data[0].account_name[0]._id);
          getJobAssignmentDetails(res.data.data[0].opening_id);
        })
        .catch((error) => {
          if (error?.response?.status == 401) {
            logoutHandler(history);
          }
        });
    }
  };
  const getJobAssignmentDetails = (id) => {
    axios
      .post(
        JOB_ASSIGNMENT_DETAILS,
        {
          recruiter_id: users.user._id,
          opening_id: id,
        },
        {
          headers: { Authorization: users.token },
        }
      )
      .then((res) => {
        setBdmId(res.data.data[0].created_by);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          logoutHandler(history);
        }
      });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleSwitchChange = (key, show) => {
    setColumnVisibility((prev) => {
      const newState = { ...prev, [key]: show };
      return newState;
    });
  };
  useEffect(() => {
    const initialVisibility = { ...columnVisibility };
    allColumns.forEach((col) => {
      initialVisibility[col.key] = col.mandatory || initialVisibility[col.key];
    });
    setColumnVisibility(initialVisibility);
  }, [allColumns]);

  const handleDeleteNotes = (id) => {
    DeleteNotes.deletee(`${selectedRow._id}/${id}`);
  };

  useEffect(() => {
    getListPreference();
  }, []);

  function isValidEmails(emails) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (typeof emails === "string") {
      return emails.split(",").every((email) => emailRegex.test(email.trim()));
    }
    return false;
  }

  const [form] = Form.useForm();

  const prevSearch = useRef(search);
  const handleSearch = (e) => {
    const value = e.target.value;
    dispatch(setSearch(value));

    // Reset to first page when searching
    dispatch(setPage(1));

    // Fetch candidates with new search term
    dispatch(
      fetchCandidates({
        jobId: record.opening_id,
        page: 1,
        pageSize,
        search: value,
        sortBy,
        sortOrder,
        shortlistId: currentListId,
      })
    );
  };

  const handleDeleteCandidate = async () => {
    setIsDeleting(true);
    try {
      const resultAction = dispatch(
        deleteCandidates({
          candidateIds: idsToDelete,
          jobId: record.opening_id,
        })
      );
      if (deleteCandidates.fulfilled.match(resultAction)) {
        message.success("Candidate(s) deleted successfully");
        setOpenDltModal(false);
        dispatch(
          fetchCandidates({ jobId: record.opening_id, page, pageSize, search })
        );
      } else if (deleteCandidates.rejected.match(resultAction)) {
        throw new Error(
          resultAction.error.message || "Failed to delete candidate(s)"
        );
      }
    } catch (error) {
      console.error("Error deleting candidate:", error);
      message.error(error.message);
    } finally {
      setIsDeleting(false);
    }
  };
  const handleRefresh = () => {
    dispatch(
      fetchCandidates({
        jobId: record.opening_id,
        page,
        pageSize,
        search,
        sortBy,
        sortOrder,
        shortlistId: currentListId,
      })
    )
      .unwrap()
      .then(() => {
        message.success("Candidate list refreshed successfully");
      })
      .catch((error) => {
        message.error("Failed to refresh candidate list: " + error.message);
      });
  };

  const handleDeleteMultipleCandidates = () => {
    openDeleteModal(selectedCandidateIds);
  };

  const openDeleteModal = (candidateIds) => {
    setOpenDltModal(true);
    setIdsToDelete(Array.isArray(candidateIds) ? candidateIds : [candidateIds]);
  };

  const handleCancelDeleteModal = () => {
    setOpenDltModal(false);
  };

  const handleCheckboxChange = (candidateId) => {
    const newSelectedIds = selectedCandidateIds.includes(candidateId)
      ? selectedCandidateIds.filter((id) => id !== candidateId)
      : [...selectedCandidateIds, candidateId];

    dispatch(setSelectedCandidateIds(newSelectedIds));

    const newSelectedRows = filteredCandidates.filter((candidate) =>
      newSelectedIds.includes(candidate.candidate._id)
    );
    setSelectedRows(newSelectedRows);
  };

  const handleSelectAll = () => {
    const allCandidateIds = filteredCandidates?.map(
      (candidate) => candidate.candidate._id
    );
    const newSelectedIds =
      selectedCandidateIds.length === allCandidateIds.length
        ? []
        : allCandidateIds;
    dispatch(setSelectedCandidateIds(newSelectedIds));

    const newSelectedRows =
      newSelectedIds.length === 0 ? [] : filteredCandidates;
    setSelectedRows(newSelectedRows);
  };

  const handleChangeLabel = useCallback(
    (selected) => {
      dispatch(setSelectedFilters(selected));
      dispatch(applyFilters());
    },
    [dispatch]
  );

  const handleView = useCallback(() => {
    dispatch(setListView(!listView));
  }, [dispatch, listView]);
const sendInvitation = async (values) => {
  try {
    setGlobalLoading({ ...globalLoading, linkedInInvitation: true });

    // Extract all selected candidates that have LinkedIn profiles
    const candidatesWithLinkedIn = selectedRows.filter(row => 
      row.candidate?.meta?.linkedin && 
      values.displayName.includes(row.candidate.first_name)
    );

    if (candidatesWithLinkedIn.length === 0) {
      throw new Error('No selected candidates have LinkedIn profiles');
    }

    // Prepare data for all selected candidates
    const data = candidatesWithLinkedIn.map(row => {
      let msg = values.message;
      // Replace template variables for each candidate
      msg = msg.replace(/{{name}}/g, row.candidate?.first_name || '');
      msg = msg.replace(/{{email}}/g, row.candidate?.email || '');
      msg = msg.replace(/{{phone}}/g, row.candidate?.mobile || '');

      return {
        linkedin_link: row.candidate.meta.linkedin,
        message: msg,
        candidate_id: row.candidate._id,
      };
    });

    const response = await axios.post(SENT_LINKEDIN_INVITATION, data, requestHeader());
    
    if (response.data.success) {
      setGlobalSuccess({
        status: true,
        message: `LinkedIn Invitation sent successfully to ${data.length} candidate(s)!`
      });
      setShowLinkedInInvitaion(false);
      form.resetFields();
    } else {
      throw new Error(response.data.message || 'Failed to send invitations');
    }

  } catch (error) {
    console.error('Send invitation error:', error);
    setGlobalError({
      status: true,
      message: error.message || "Failed to send LinkedIn invitations!"
    });
  } finally {
    setGlobalLoading({ ...globalLoading, linkedInInvitation: false });
    
    setTimeout(() => {
      setGlobalSuccess({ status: false, message: null });
      setGlobalError({ status: false, message: null });
    }, 3000);
  }
};

  const sendEmail = (emailBody) => {
    console.log('emailBody:', emailBody);
    const data = [];
    
    // Check if selectedRows exists and has items
    if (!selectedRows || selectedRows.length === 0) {
      message.error('No candidates selected');
      return;
    }

    // Loop through email recipients
    for (let email of emailBody.to) {
      // Find the corresponding candidate from selectedRows
      const row = selectedRows.find((row) =>
        row.candidate.email
          .split(",")
          .map((e) => e.trim())
          .includes(email)
      );

      console.log('Found candidate row:', row);

      if (row) {
        let msg = emailBody.message;
        let subject = emailBody.subject;
        // Replace template variables
        // msg = msg.replace(/{{name}}/g, row.candidate.first_name || '');
        // msg = msg.replace(/{{email}}/g, email || '');
        // msg = msg.replace(/{{phone}}/g, row.candidate.mobile || '');
        // subject = subject.replace(/{{name}}/g, row.candidate.first_name || '');


        const replaceVariables = (text, isSubject) => {
          const variables = isSubject ? {
            name: row.candidate.first_name || '',
            job_title: record.opening_title || '',
          } : {
            name: row.candidate.first_name || '',
            email: email || '',
            phone: row.candidate.mobile || '',
            job_title: record.opening_title || '',
            location: `${record.city}, ${record.state}, ${record.country}` || '',
            short_description: record.short_description || '',
            
          };
  
          return text.replace(/{{(\w+)}}/g, (match, p1) => variables[p1] || match);
        };
  
        msg = replaceVariables(msg, false);
        subject = replaceVariables(subject, true);

        data.push({
          to: email,
          message: msg,
          subject: subject,
          candidateId: row.candidate._id
        });
      }
    }

    console.log('Prepared email data:', data);

    if (data.length === 0) {
      message.error('No valid candidates found for sending emails');
      return;
    }

    // Send the email
    try {
      SendBulkEmail.post({
        email: data,
        pauseSecond: emailBody.pause || 2,
        jobId: record?.opening_id
      }).then(response => {
        if (response) {
          message.success('Emails sent successfully');
        }
      }).catch(error => {
        message.error('Failed to send emails: ' + error.message);
      });
    } catch (error) {
      console.error('Email sending error:', error);
      message.error('Failed to send emails');
    }
    finally{
      handleCloseEmailModal();
    }
  };

  const handleCloseEmailModal = useCallback(() => {
    setShowEmailForm(false);
    setEmailTemplates([]); // Clear templates
    form.resetFields();
    setEmailData({
      to: [],
      subject: "",
      body: "",
      candidateId: "",
    });
  }, [form]);

  const onFinishFailed = (errorInfo) => {};

  const handleLinkedInInviationModal = () => {
    if (selectedRows.length > 0) {
      setShowLinkedInInvitaion(true);
      form.setFieldsValue({
        displayName: selectedRows?.map((row) => row.candidate.first_name),
      });
    } else {
      message.warning(
        "Please select at least one candidate for LinkedIn invitation."
      );
    }
  };

  const textAreaRef = useRef(null);
  const [textValue, setTextValue] = useState("");

  const insertVariable = (value) => {
    form.setFieldsValue({
      message: form.getFieldValue("message") + ` {{${value}}}`,
    });
  };

  const maxChars = 300;

  const handleNotes = (value) => {
    UpdateCandidate.patch({ multipleNotes: value }, selectedRow._id);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      dispatch(setSortBy(sorter.field[1]));
      dispatch(setSortOrder(sorter.order === "ascend" ? "asc" : "desc"));
    } else {
      dispatch(setSortBy(null));
      dispatch(setSortOrder("asc"));
    }

    dispatch(setPage(pagination.current));
    dispatch(setPageSize(pagination.pageSize));
  };

  const LinkedInInvitationStatus = ({ candidate }) => {
    const currentUserId = users.user._id;
    const invitationStatusArray = candidate.linkedIn_invitation_status || [];
    const invitationStatus = invitationStatusArray.find(
      (status) => status.sent_by === currentUserId
    );

    const getStatusColor = (status) => {
      switch (status) {
        case "Sent":
          return "blue";
        case "Accepted":
          return "green";
        case "Rejected":
          return "red";
        default:
          return "default";
      }
    };
    return (
      <Space align="center">
        <LinkedinOutlined style={{ fontSize: "16px", color: "#0077B5" }} />
        <Typography.Text strong>LinkedIn Invitation:</Typography.Text>
        {invitationStatus ? (
          <Tag color={getStatusColor(invitationStatus.status)}>
            {invitationStatus.status}
          </Tag>
        ) : (
          <Tag>No Invitation Sent</Tag>
        )}
      </Space>
    );
  };
  const EmailStatus = ({ emailHistory }) => {
    const hasSent = emailHistory.some((email) => email.is_sent);
    // Check if emailHistory has any object with is_open as true
    const hasOpen = emailHistory.some((email) => email.is_open);
    // Check if emailHistory has any object with is_replied as true
    const hasReplied = emailHistory.some((email) => email.is_replied);
    // Check if emailHistory has any object with is_bounced as true
    const hasBounced = emailHistory.some((email) => email.is_bounced);
    return (
      <Space align="center">
        <MailOutlined style={{ fontSize: "16px", color: "#0077B5" }} />
        <Typography.Text strong>Email Status:</Typography.Text>
        <span style={{ marginRight: "10px" }}>
          {hasSent ? (
            <Typography.Text>
              Send <CheckCircleOutlined style={{ color: "green" }} />
            </Typography.Text>
          ) : (
            <Typography.Text>
              Send <CloseCircleOutlined style={{ color: "red" }} />
            </Typography.Text>
          )}
        </span>
        <span style={{ marginRight: "10px" }}>
          {hasOpen ? (
            <Typography.Text>
              Opened <MailTwoTone twoToneColor="#52c41a" />
            </Typography.Text>
          ) : null}
        </span>
        <span style={{ marginRight: "10px" }}>
          {hasReplied ? (
            <Typography.Text>
              Replied <ReplyOutlined style={{ color: "#52c41a" }} />
            </Typography.Text>
          ) : null}
        </span>
        <span style={{ marginRight: "10px" }}>
          {hasBounced ? (
            <Typography.Text>
              Bounced <CheckCircleOutlined style={{ color: "#52c41a" }} />
            </Typography.Text>
          ) : null}
        </span>
        {hasReplied && (
          <Button
            onClick={() => {
              handleChatClick(emailHistory);
            }}
            icon={<MessageOutlined />}
          />
        )}
      </Space>
    );
  };

  const handleListsCancel = () => {
    setIsListModalVisible(false);
    setListName("");
    setEditingListId(null);
  };

  const handleCreateOrUpdateList = () => {
    // console.log("clicked handleCreateOrUpdateList");
    if (editingListId) {
      // console.log("clicked update list");
      dispatch(updateList({ listId: editingListId, newName: listName }));
    } else {
      // console.log("clicked create list");

      dispatch(
        createList({
          listName: listName,
          user: users.user,
          jobId: record.opening_id,
        })
      );
    }
    handleListsCancel();
  };

  const handleSubmitCandidates = () => {
    if (selectedCandidateIds.length === 0) {
      message.warning("Please select candidates to submit");
      return;
    }
    try {
      const recruiterId = users.role === "recruiter" ? users.user._id : null;
      dispatch(
        submitCandidates({
          candidateIds: selectedCandidateIds,
          jobId: record.opening_id,
          userRole: users?.role,
          bdmId,
          recruiterId,
          companyId: companyId,
          isAutoSourced: true,
        })
      )
        .unwrap()
        .then(() => {
          message.success(
            `${selectedCandidateIds.length} candidate(s) submitted successfully`
          );
          dispatch(setSelectedCandidateIds([]));
          dispatch(setShouldRefresh(true));
        })
        .catch((error) => {
          message.error("Failed to submit candidates: " + error.message);
        });
    } catch (error) {
      console.log(error);
      message.error("An error occurred while submitting candidates");
    }
  };

  const handleAddToList = (listId) => {
    if (selectedCandidateIds.length > 0) {
      dispatch(
        addCandidatesToList({ listId, candidateIds: selectedCandidateIds })
      ).then(() => {
        dispatch(setFilteredList());
        message.success(
          `Added ${selectedCandidateIds.length} candidate(s) to the list.`
        );
      });
    } else {
      message.warning("Please select candidates to add to the list");
    }
  };
  // useEffect(() => {
  // 	getListPreference();
  // }, []);
  // useEffect(() => {
  // 	if (record) {
  // 		dispatch(
  // 			fetchCandidates({
  // 				jobId: record.opening_id,
  // 				page,
  // 				pageSize,
  // 				search,
  // 				sortBy,
  // 				sortOrder,
  // 				shortlistId: currentListId || null,
  // 			})
  // 		);
  // 	}
  // }, [
  // 	dispatch,
  // 	record,
  // 	page,
  // 	pageSize,
  // 	search,
  // 	sortBy,
  // 	sortOrder,
  // 	currentListId,
  // 	submitCandidates,
  // 	submitting

  // ]);

  // useEffect(() => {
  // 	dispatch(
  // 		fetchLists({ createdBy: users.user._id, jobId: record.opening_id })
  // 	);
  // }, [dispatch]);
  useEffect(() => {
    prevSearch.current = search;
  }, [search]);
  useEffect(() => {
    dispatch(applyFilters());
  }, [dispatch, selectedFilters, currentListId]);
  useEffect(() => {
    if (deleteStatus === "failed" && deleteError) {
      message.error(deleteError.message || "Failed to delete candidate(s)");
    }
  }, [deleteStatus, deleteError]);
  useEffect(() => {
    form.setFieldsValue(emailData);
  }, [emailData, form]);
  const handleUpdateNotes = (value, id) => {
    UpdateNotes.patch(value, `${selectedRow._id}/${id}`);
  };
  useEffect(() => {
    const newSelectedRows = filteredCandidates.filter((candidate) =>
      selectedCandidateIds.includes(candidate.candidate._id)
    );
    setSelectedRows(newSelectedRows);
  }, [selectedCandidateIds, filteredCandidates]);
  useEffect(() => {
    form.setFieldsValue({
      displayName: selectedRows?.map((row) => row.candidate.first_name),
    });
  }, [selectedRows]);
  useEffect(() => {
    if (
      record &&
      (candidateList.length === 0 ||
        search !== prevSearch.current ||
        shouldRefresh)
    ) {
      dispatch(
        fetchCandidates({
          jobId: record.opening_id,
          page,
          pageSize,
          search,
          sortBy,
          sortOrder,
          shortlistId: currentListId,
        })
      );
      if (shouldRefresh) {
        dispatch(setShouldRefresh(false));
      }
      prevSearch.current = search;
    }
  }, [
    dispatch,
    record,
    page,
    pageSize,
    search,
    candidateList.length,
    shouldRefresh,
    sortBy,
    sortOrder,
    currentListId,
  ]);

  // useEffect(() => {
  // 	if (shouldRefresh) {
  // 		dispatch(fetchCandidates({
  // 			jobId: record.opening_id,
  // 			page,
  // 			pageSize,
  // 			search,
  // 			sortBy,
  // 			sortOrder,
  // 			shortlistId: currentListId,
  // 		}));
  // 		dispatch(setShouldRefresh(false));
  // 	}
  // }, [shouldRefresh, dispatch, record.opening_id, page, pageSize, search, sortBy, sortOrder, currentListId]);

  useEffect(() => {
    return () => {
      dispatch(setSearch(""));
    };
  }, [dispatch]);


  //email templates
      const [emailTemplates, setEmailTemplates] = useState([]);
      const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
      const userEmailData = useCallAPI(USER_EMAIL_TEMPLATES);
         const createTemplate = useCallAPI(EMAIL_TEMPLATES_CREATE);
          const updateTemplate = useCallAPI(EMAIL_TEMPLATES_UPDATE);
const fetchEmailTemplates = async () => {
    try {
        setIsLoadingTemplates(true);
        await userEmailData.getList(); // We wait for this
        
        // Important: We access the data after the await
        if (userEmailData.data?.data) {
            setEmailTemplates(userEmailData.data.data);
        }
    } catch (error) {
        message.error('Failed to load email templates');
    } finally {
        setIsLoadingTemplates(false);
    }
};

// We trigger the fetch when the modal opens
useEffect(() => {
    if (showEmailForm) {  // Only fetch when modal is open
        fetchEmailTemplates();
    }
}, [showEmailForm]);

  const handleSaveTemplateSuccess = useCallback(async () => {
    try {
      setIsLoadingTemplates(true);
      await fetchEmailTemplates();
      message.success('Email templates refreshed');
    } catch (error) {
      message.error('Failed to refresh templates');
    } finally {
      setIsLoadingTemplates(false);
    }
  }, []);

  return (
    <>
      <Modal
        title={editingListId ? "Edit List" : "Create New List"}
        open={isListModalVisible}
        onOk={handleCreateOrUpdateList}
        onCancel={handleCancel}
      >
        <Input
          placeholder="Enter list name"
          value={listName}
          onChange={(e) => setListName(e.target.value)}
        />
      </Modal>
      <Modal
        title={editingListId ? "Edit List" : "Create New List"}
        open={isListModalVisible}
        onOk={handleCreateOrUpdateList}
        onCancel={handleListsCancel}
      >
        <Input
          placeholder="Enter list name"
          value={listName}
          onChange={(e) => setListName(e.target.value)}
        />
      </Modal>
      <Modal
        title="Select Columns"
        open={isModalVisible}
        onOk={handleListPreference}
        onCancel={handleCancel}
        style={{
          top: 0,
          right: 0,
          height: "100vh",
          position: "fixed",
          width: "300px",
          margin: 0,
          padding: 0,
          overflowY: "scroll",
        }}
        bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
      >
        {allColumns?.map((column) => (
          <div key={column.key} style={{ marginBottom: "10px" }}>
            <Switch
              checked={columnVisibility[column.key] || column.mandatory}
              onChange={(show) => handleSwitchChange(column.key, show)}
              style={{ marginLeft: "10px" }}
              disabled={column.mandatory}
            />{" "}
            <span>{column.title}</span>
            {column.mandatory && (
              <span style={{ color: "red" }}> (Mandatory)</span>
            )}
          </div>
        ))}
      </Modal>
      <Modal
        title="Send Invitaion"
        open={showLinkedInInvitaion}
        onOk={(e) => {
          setShowLinkedInInvitaion(false);
        }}
        onCancel={(e) => {
          setShowLinkedInInvitaion(false);
        }}
      >
        {showError(
          globalSuccess.status,
          globalSuccess.message,
          globalError.status,
          globalError.message
        )}
        <CandidateLinkedinInvitation 
  form={form}
  selectedRows={selectedRows}
  sendInvitation={sendInvitation}
  maxChars={300}
/>
        {/* <Form
          form={form}
          name="linkedin_message"
          onFinish={sendInvitation}
          layout="vertical"
          style={{ maxWidth: "700px", margin: "auto" }}
        >
          <Form.Item
            name="displayName"
            label="LinkedIn Username"
            rules={[
              { required: true, message: "Please enter the LinkedIn username" },
            ]}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="LinkedIn Users"
            >
              {selectedRows?.map((row) => (
                <Option key={row._id} value={row.candidate.first_name}>
                  <Tag color="blue">{row.candidate.first_name}</Tag>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                insertVariable("name");
              }}
              style={{ marginLeft: "10px" }}
            >
              Name
            </Button>{" "}
            <Button
              type="primary"
              onClick={() => {
                insertVariable("email");
              }}
              style={{ marginLeft: "10px" }}
            >
              Email
            </Button>{" "}
            <Button
              type="primary"
              onClick={() => {
                insertVariable("phone");
              }}
              style={{ marginLeft: "10px" }}
            >
              Phone
            </Button>{" "}
          </Form.Item>

          <Form.Item
            name="message"
            label="Message"
            // rules={[{ required: true, message: 'Please enter your message' }]}
            rules={[
              { required: true, message: "Please enter your message" },
              {
                validator: (_, value) =>
                  value && value.length <= maxChars
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          `Message must be at most ${maxChars} characters`
                        )
                      ),
              },
            ]}
          >
            <TextArea
              rows={8}
              placeholder="Type your message here"
              ref={textAreaRef}
              value={textValue}
              onChange={(e) => setTextValue(e.target.value)}
            />
          </Form.Item>

          <br />

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Send Message
            </Button>
          </Form.Item>
        </Form> */}
      </Modal>
      <Modal
        title="Send Email"
        open={showEmailForm}
        onCancel={handleCloseEmailModal}
        destroyOnClose={true}
        onOk={handleCloseEmailModal}
        width={800}
      >
        <CandidateEmail 
          templates={emailTemplates}
          isLoading={isLoadingTemplates}
          createTemplate={createTemplate}
          updateTemplate={updateTemplate}
          onTemplateChange={handleSaveTemplateSuccess}
          form={form} 
          emailData={emailData} 
          insertVariable={insertVariable} 
          onFinishFailed={onFinishFailed}
          sendEmail={sendEmail}
        /> 
      </Modal>
      <Modal
        title="Document Viewer"
        open={showCV}
        onOk={() => {
          setShowCv(false);
        }}
        onCancel={() => {
          setShowCv(false);
        }}
        style={{
          top: 0,
          right: 0,
          height: "100vh",
          position: "fixed",
          margin: 0,
          padding: 0,
        }}
        bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
        width={1000}
      >
        <PdfViewer fileUrl={cvPath} />
      </Modal>
      <Modal
        title={`Delete Candidate${idsToDelete.length > 1 ? "s" : ""}`}
        open={openDltModal}
        onOk={handleDeleteCandidate}
        okText="Delete"
        cancelText="Cancel"
        confirmLoading={isDeleting}
        onCancel={handleCancelDeleteModal}
      >
        <h5>
          Are you sure you want to delete{" "}
          {idsToDelete.length > 1 ? "these candidates" : "this candidate"}?
        </h5>
        <p style={{ color: "red" }}>This cannot be undone!</p>
      </Modal>
      {selectedEmailHistoryRecord && selectedEmailHistoryRecord.length > 0 && (
        <Modal
          title="Email Conversation"
          visible={isEmailHistoryModalVisible}
          onCancel={handleModalClose}
          footer={null}
        >
          {selectedEmailHistoryRecord?.map((record, index) => (
            <div key={index}>
              {/* Message Box */}
              <Card
                className="message-box"
                style={{ marginBottom: "20px", backgroundColor: "#f0f2f5" }}
              >
                <Typography.Text strong>Sent Message:</Typography.Text>
                <br />
                <Typography.Text>From: {record.from_email}</Typography.Text>
                <br />
                <Typography.Text>To: {record.to_email}</Typography.Text>
                <br />
                <Typography.Text>Subject: {record.subject}</Typography.Text>
                <br />
                <div
                  dangerouslySetInnerHTML={{ __html: record.message }}
                  style={{
                    padding: "10px",
                    whiteSpace: "pre-wrap",
                    borderRadius: "5px",
                    backgroundColor: "#fff",
                  }}
                />
                <Typography.Text type="secondary">
                  {new Date(record.created_at).toLocaleString()}
                </Typography.Text>
              </Card>

              {/* Reply Box */}
              {record.is_replied && (
                <Card
                  className="reply-box"
                  style={{ backgroundColor: "#e6fffb" }}
                >
                  <Typography.Text strong>Reply Message:</Typography.Text>
                  <br />
                  <Typography.Text>From: {record.to_email}</Typography.Text>
                  <br />
                  <div
                    dangerouslySetInnerHTML={{ __html: record.reply_message }}
                    style={{
                      padding: "10px",
                      whiteSpace: "pre-wrap",
                      borderRadius: "5px",
                      backgroundColor: "#fff",
                    }}
                  />
                  <br />
                  <Typography.Text type="secondary">
                    {new Date(
                      record.meta.reply_receivedDateTime
                    ).toLocaleString()}
                  </Typography.Text>
                </Card>
              )}
            </div>
          ))}
        </Modal>
      )}
      <Notes
        handleCancel={() => setShowNotes(false)}
        isVisible={showNotes}
        handleNotes={handleNotes}
        notes={NotesData}
        jobOpenings={selectedRow}
        user={users}
        updateNoteState={setShowNotes}
        handleUpdateNote={handleUpdateNotes}
        handleDeleteNote={handleDeleteNotes}
        notesField={"multipleNotes"}
      />
      <CustomSnackbar
        success={SendBulkEmail.success || SearchContact.success}
        error={SendBulkEmail.error || SearchContact.error}
      />
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100dvh",
          width: "100%",
        }}
      >
        <Card style={{ zIndex: 1 }}>
          <Row gutter={[16, 16]} align="middle" justify="space-between">
            <Col xs={24} sm={12} md={8} lg={6}>
              <Input
                placeholder="Search"
                prefix={<SearchOutlined />}
                style={{ width: "100%" }}
                onChange={handleSearch}
              />
            </Col>
            <Col xs={24} sm={12} md={16} lg={18}>
              <Row gutter={[8, 8]} justify="end">
                <Col>
                  <Button
                    onClick={handleRefresh}
                    icon={<ReloadOutlined />}
                    type="ghost"
                    size="middle"
                    loading={loading}
                    title="Refresh Candidates Data"
                  >
                    {/* Refresh Candidates */}
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => setIsModalVisible(true)}
                    icon={<ColumnHeightOutlined />}
                  >
                    Columns
                  </Button>
                </Col>
                <Col>
                  <GlobalSettings
                    // onClickSendEmail={onClickSendEmail}
                    onClickLinkedIn={handleLinkedInInviationModal}
                    searchContact={searchContact}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            gutter={[16, 16]}
            justify="space-between"
            align="middle"
            style={{ marginTop: "10px" }}
          >
            <Col xs={24} sm={12} md={8} lg={6}>
              <Typography.Text>
                Job ID:{" "}
                <Link
                  to={{
                    pathname: `/${rolePath}/opening-detail/${record.opening_id}`,
                    state: { record: record },
                  }}
                >
                  {record.opening_id}
                </Link>
              </Typography.Text>
            </Col>
            <Col xs={24} sm={12} md={16} lg={18}>
              <Row gutter={[8, 8]} justify="end" align="middle">
                <Col>
                  <Tooltip title="set page size">
                    <InputNumber
                      min={1}
                      value={pageSize}
                      onChange={(value) => setPageSize(value)}
                      style={{ width: 80, textAlign: "center" }}
                    />
                  </Tooltip>
                </Col>
                <Col>
                  <Select
                    placeholder="Filter Label"
                    onChange={handleChangeLabel}
                    optionLabelProp="label"
                    value={selectedFilters}
                    mode="multiple"
                    style={{ width: "100%", minWidth: 150, maxWidth: 250 }}
                  >
                    <Select.Option value="top" label="Best">
                      <div className="demo-option-label-item">
                        Best Candidates
                      </div>
                    </Select.Option>
                    <Select.Option value="good" label="Good">
                      <div className="demo-option-label-item">
                        Good Candidates
                      </div>
                    </Select.Option>
                    <Select.Option value="qualified" label="Qualified">
                      <div className="demo-option-label-item">
                        Qualified Candidates
                      </div>
                    </Select.Option>
                  </Select>
                </Col>
                <Col>
                  <CandidateSort jobId={record.opening_id} />
                </Col>
                <Col>
                  <CandidateListManagement jobId={record.opening_id} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>

        <br />

        <Card
          title={`Candidates List For ${record.opening_title}`}
          bordered={false}
          style={{
            flexGrow: 1,
            // overflowY: listView ? "hidden" : "scroll",
            // overflowY: "auto",
          }}
          extra={
            <Row
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <JobListPagination jobId={record.opening_id} />

              <Button
                icon={<EmailOutlined />}
                onClick={() => onClickSendEmail()}
              >
                Send Email ({selectedCandidateIds?.length}){" "}
              </Button>

              {selectedRows?.length > 0 && listView && (
                <Button
                  type="danger"
                  icon={<DeleteOutlined />}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() =>
                    handleDeleteMultipleCandidates(selectedCandidateIds)
                  }
                >
                  Selected ({selectedRows?.length})
                </Button>
              )}
              {!listView && (
                <Row justify="space-between" span={24} align="middle">
                  <Checkbox
                    indeterminate={
                      selectedCandidateIds.length > 0 &&
                      selectedCandidateIds.length < candidateList.length
                    }
                    checked={
                      selectedCandidateIds.length === candidateList.length
                    }
                    onChange={handleSelectAll}
                  >
                    {selectedCandidateIds.length === candidateList.length
                      ? "Unselect All"
                      : "Select All"}
                  </Checkbox>
                  <Space>
                    <Button
                      type="danger"
                      icon={<DeleteOutlined />}
                      onClick={() =>
                        handleDeleteMultipleCandidates(selectedCandidateIds)
                      }
                      disabled={selectedCandidateIds.length === 0}
                    />
                  </Space>
                </Row>
              )}
              <Button
                type="primary"
                onClick={handleSubmitCandidates}
                loading={submitting}
                disabled={selectedCandidateIds.length === 0}
                hidden={selectedCandidateIds.length === 0}
              >
                Submit Selected ({selectedCandidateIds.length})
              </Button>
              <span style={{ marginRight: "4px" }}>Change View</span>
              <Switch
                onChange={handleView}
                title="Change View Style"
                checkedChildren={<List />}
                unCheckedChildren={<TableOutlined />}
              />
            </Row>
          }
          actions={
            !listView
              ? [
                  <Pagination
                    total={totalData || 0}
                    pageSize={pageSize}
                    current={currentPage || 1}
                    showSizeChanger={true}
                    showQuickJumper={true}
                    showTotal={(total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`
                    }
                    onChange={(page, size) => {
                      dispatch(setPage(page));
                      dispatch(setPageSize(size));
                      dispatch(
                        fetchCandidates({
                          jobId: record.opening_id,
                          page,
                          pageSize: size,
                          search,
                        })
                      );
                    }}
                  />,
                ]
              : null
          }
        >
          {listView ? (
            <Table
              loading={loading}
              dataSource={filteredCandidates}
              key={JSON.stringify(filteredCandidates)}
              size="large"
              rowKey={(record) => record.candidate._id}
              onChange={handleTableChange}
              pagination={{
                position: ["bottomCenter"],
                total: totalData || 0,
                pageSize: pageSize,
                current: currentPage || 1,
                showSizeChanger: true,
                onChange: (page, size) => {
                  dispatch(setPage(page));
                  dispatch(setPageSize(size));
                  dispatch(
                    fetchCandidates({
                      jobId: record.opening_id,
                      page,
                      pageSize: size,
                      search,
                    })
                  );
                },
              }}
              rowSelection={{
                type: selectionType,
                selectedRowKeys: selectedCandidateIds,
                onChange: (selectedRowKeys, selectedRows) => {
                  dispatch(setSelectedCandidateIds(selectedRowKeys));
                  setSelectedRows(selectedRows);
                },
              }}
              columns={visibleColumns}
              style={{
                overflowX: "scroll",
                maxHeight: "600px",
              }}
            />
          ) : (
            <Spin spinning={loading} tip="Loading Candidates...">
              <div
                style={{
                  overflowY: "scroll",
                  maxHeight: "600px",
                  opacity: loading ? 0.5 : 1,
                }}
              >
                {filteredCandidates &&
                  filteredCandidates?.map((cndidate, index) => {
                    const {
                      candidate,
                      employment,
                      qualifications,
                      emailHistory,
                    } = cndidate;

                    const candidateLabel = bestCandidates.includes(
                      candidate._id
                    )
                      ? "top"
                      : goodCandidates.includes(candidate._id)
                      ? "good"
                      : qualifiedCandidates.includes(candidate._id)
                      ? "qualified"
                      : "";
                    return (
                      <CandidateCard
                        key={candidate._id + index}
                        candidate={candidate}
                        candidateLabel={candidateLabel}
                        getNotes={getNotes}
                        record={record}
                        currentListId={currentListId}
                        type={type}
                        rolePath={rolePath}
                        selectedCandidateIds={selectedCandidateIds}
                        employment={employment}
                        qualifications={qualifications}
                        emailHistory={emailHistory}
                        EmailStatus={EmailStatus}
                        lists={lists}
                        LinkedInInvitationStatus={LinkedInInvitationStatus}
                        isValidEmails={isValidEmails}
                        handleCheckboxChange={handleCheckboxChange}
                        handleLabelChange={handleLabelChange}
                        handleAddToList={handleAddToList}
                        onClickSendEmail={() => onClickSendEmail(cndidate)} // Modified this line
                        openDeleteModal={openDeleteModal}
                        IMAGE_CANDIDATE_URL={IMAGE_CANDIDATE_URL}
                        cndidate={cndidate}
                        attachments={candidate.attachments}
                      />
                    );
                  })}
              </div>
            </Spin>
          )}
        </Card>
      </section>
    </>
  );
}
