import { Modal, Switch } from "antd";
import React from "react";

export default function EditColumns({
  columns = [],
  handleColumn,
  save,
  handleCancel,
  isVisible,
}) {
  return (
    <Modal
      title="Select Columns"
      open={isVisible}
      onOk={save}
      onCancel={handleCancel}
      style={{
        top: 0,
        right: 0,
        height: "100vh",
        position: "fixed",
        width: "300px",
        margin: 0,
        padding: 0,
        overflowY: "scroll",
      }}
      bodyStyle={{ height: "calc(100% - 55px)" }}
    >
      {columns.map((column) => (
        <div key={column.key} style={{ marginBottom: "10px" }}>
          <Switch
            checked={column.show}
            onChange={(show) => handleColumn(column.key, show)}
            style={{ marginLeft: "10px" }}
          />{" "}
          <span>{column.title}</span>
        </div>
      ))}
    </Modal>
  );
}
