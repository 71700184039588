import React, { useState, useEffect } from 'react';
import { Tabs, Affix } from 'antd'; // Add Affix import
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import CandidateList from './CandidateList';
import CareerBuilderCandidates from './CareerBuilderCandidates';
import MonsterCandidates from './MonsterCandidates';
import LinkedInCandidates from './LinkedInCandidates';
import AutoSourceCandidates from './AutoSourceCandidates';

const { TabPane } = Tabs;

const generateTabKeys = (userType) => ({
  internal: `/${userType}/candidateList/internal`,
  careerBuilder: `/${userType}/candidateList/career-builder`,
  monster: `/${userType}/candidateList/monster`,
  linkedin: `/${userType}/candidateList/linkedin`,
  autoSource: `/${userType}/candidateList/auto-source`,
});

export default function CandidatesPlatforms({ props }) {
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('internal');
  const userType = props?.type || 'user';
  const tabKeys = generateTabKeys(userType);


  useEffect(() => {
    const path = location.pathname;
    const currentTab = Object.keys(tabKeys).find(key => tabKeys[key] === path);
    if (currentTab) {
      setActiveTab(currentTab);
    } else {
      history.replace(tabKeys.internal);
    }
  }, [location, history, tabKeys]);

  const handleTabChange = key => {
    setActiveTab(key);
    history.push(tabKeys[key]);
  };

  return (
    <div>
      <Affix offsetTop={0}>
        <div style={{ backgroundColor: 'white', padding: '8px 0', borderBottom: '1px solid #f0f0f0' }}>
          <Tabs
            activeKey={activeTab}
            onChange={handleTabChange}
            tabPosition="top"
            style={{ marginBottom: 0 }}
          >
            <TabPane tab="Candidates List" key="internal" />
            <TabPane tab="Career Builder" key="careerBuilder" />
            <TabPane tab="Monster Search" key="monster" />
            <TabPane tab="LinkedIn" key="linkedin" />
            <TabPane tab="Auto Source" key="autoSource" />
          </Tabs>
        </div>
      </Affix>
      
      <div style={{ marginTop: '16px', padding: '16px'}}>
        <Switch>
          <Route 
            path={tabKeys.internal} 
            render={() => <CandidateList props={props} />} 
          />
          <Route 
            path={tabKeys.monster} 
            render={() => <MonsterCandidates type={props?.type || 'admin'} />} 
          />
          <Route 
            path={tabKeys.autoSource} 
            render={() => <AutoSourceCandidates type={props?.type || 'admin'} />} 
          />
          <Route 
            path={tabKeys.careerBuilder} 
            component={CareerBuilderCandidates} 
          />
          <Route 
            path={tabKeys.linkedin} 
            component={LinkedInCandidates} 
          />
        </Switch>
      </div>
    </div>
  );
}

