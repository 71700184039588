import React, { useEffect, useState } from "react";
import { Form, Col, Row, Card, Typography, Select, Button, Tabs } from "antd";
import {
  ASSIGN_JOB,
  BDM_FREELANCER_LIST,
  JOB_BDM_LIST,
  JOB_RECRUITER_LIST,
} from "../../../ApiUrl";
import { useSelector } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { Text } = Typography;

const { Option } = Select;
const FormItem = Form.Item;

export default function AssignJob({
  openingDetails,
  setSuccess,
  setError,
  recruiters,
  freelancers,
  isNew = false,
  getSelectedRecruiter = () => (1),
  getSelectedFreelancer = () => (1),
  bdms,
  getSelectedBDM = () => (1),
}) {
  const [recruiterList, setAllRecruiterList] = useState([]);
  const [freelancerList, setAllFreelancerList] = useState([]);
  const [allBDM, setAllBDM] = useState([]);
  const [selectedRecruiter, setSelectedRecruiter] = useState([]);
  const [selectedFreelancer, setSelectedFreelancer] = useState([]);
  const [selectedBDM, setSelectedBDM] = useState([])
  const [loading, setLoading] = useState(false);
  const users = useSelector(({ users }) => users);
  const history = useHistory();

  // console.log(users)

  useEffect(() => {
    setSelectedRecruiter(recruiters);
  }, [recruiters]);



  useEffect(() => {
    setSelectedBDM(bdms);
  }, [bdms]);


  useEffect(() => {
    setSelectedFreelancer(freelancers);
  }, [freelancers]);


  const getAllBDMList = () => {
    axios
      .get(JOB_BDM_LIST, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        setAllBDM(res.data.data);
      })
      .catch((error) => { });
  };


  const getAllRecruterList = () => {
    axios
      .get(JOB_RECRUITER_LIST, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        setAllRecruiterList(res.data.data);
        // console.log(res.data.data);
        if (error?.response?.status == 401) {
          logoutHandler(history)
        }
      })
      .catch((error) => { });
  };

  const getAllFreelancerList = () => {
    axios
      .get(BDM_FREELANCER_LIST, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        setAllFreelancerList(res.data.data);
        if (error?.response?.status == 401) {
          logoutHandler(history)
        }
      })
      .catch((error) => { });
  };

  const handleAssignChange = (value) => {
    if (isNew) {
      getSelectedRecruiter(value)
      return;
    }
    setSelectedRecruiter(value);
  };

  const postAssignJob = (text, userId = users.user._id) => {
    const paramRecruter = {
      opening_id: openingDetails.opening_id,
      assigned_by_bdm: userId,
      recruiter_id: selectedRecruiter,
      freelance_id: selectedFreelancer,
      bdm_id: selectedBDM
    };

    const request = axios.put(ASSIGN_JOB, paramRecruter, {
      headers: { Authorization: users.token },
    });

    setLoading(true);

    request
      .then((res) => {
        setSuccess(true, res.data.message);
        setLoading(false);
        setSelectedRecruiter([]);
        setSelectedFreelancer([]);
      })
      .catch((error) => {
        setLoading(false);
        setError(true, error.message ? error.message : " ");
        if (error?.response?.status == 401) {
          logoutHandler(history)
        }
      });
  };

  const handleChangeFreelancer = (value) => {
    if (isNew) {
      getSelectedFreelancer(value)
      return;
    }
    setSelectedFreelancer(value);
  };


  const handleChangeBDM = (value) => {
    if (isNew) {
      getSelectedBDM(value)
      return;
    }
    setSelectedBDM(value);
  };

  useEffect(() => {
    getAllBDMList()
    getAllRecruterList();
    getAllFreelancerList();
    return () => {
      //   setLoading({ ...loading, recruiter: false });
    };
  }, []);

  return (
    <Card title="Assign Job" className="px-0 py-0">
      <Col span={20}>
        <Text>Assigned Job to Recruiter :</Text>
        <FormItem className="mt-2">
          <Row className="d-flex justify-content-between">
            <Col span={15}>
              <Select
                mode="multiple"
                value={selectedRecruiter}
                onChange={handleAssignChange}
                placeholder="Select"
              >
                {recruiterList.length > 0
                  ? recruiterList.map((user, index) => {
                    return (
                      <Option value={user._id} key={index.toString()}>
                        {user.display_name
                          ? user.display_name.charAt(0).toUpperCase() +
                          user.display_name.slice(1)
                          : ""}
                      </Option>
                    );
                  })
                  : null}
              </Select>
            </Col>

            {isNew ? null : (
              <Button
                className="d-flex align-items-center"
                type="primary"
                onClick={() => postAssignJob("Recruiter")}
              >
                Save
                {loading && (
                  <span className="mx-3 spinner spinner-white"> </span>
                )}
              </Button>
            )}
          </Row>
        </FormItem>
        <br />

        <Text>Assigned Job to Freelancer :</Text>
        <FormItem className="mt-2">
          <Row className="d-flex justify-content-between">
            <Col span={15}>
              <Select
                mode="multiple"
                value={selectedFreelancer}
                onChange={handleChangeFreelancer}
                placeholder="Select"
              >
                {freelancerList.length > 0
                  ? freelancerList.map((user, index) => {
                    return (
                      <Option value={user._id} key={index.toString()}>
                        {user.display_name
                          ? user.display_name.charAt(0).toUpperCase() +
                          user.display_name.slice(1)
                          : ""}
                      </Option>
                    );
                  })
                  : null}
              </Select>
            </Col>

            {isNew ? null : (
              <Button
                className="d-flex align-items-center"
                type="primary"
                onClick={() => postAssignJob("Freelancer")}
              >
                Save
                {loading && (
                  <span className="mx-3 spinner spinner-white"> </span>
                )}
              </Button>
            )}
          </Row>
        </FormItem>
        <br />
        {
          users.role != "bdm" && (
            <div>
              <Text>Assigned Job to BDM:</Text>
              <FormItem >
                <Row className="d-flex justify-content-between">
                  <Col span={15}>
                    <Select
                      mode="multiple"
                      value={selectedBDM}
                      onChange={handleChangeBDM}
                      placeholder="Select"
                    >
                      {allBDM !== undefined &&
                        allBDM.map((user, index) => {
                          return (
                            <Option value={user._id} key={index.toString()}>
                              {user.display_name.charAt(0).toUpperCase() +
                                user.display_name.slice(1)}
                            </Option>
                          );
                        })}
                    </Select>
                  </Col>
                  {!isNew && <Button
                    className="d-flex align-items-center"
                    type="primary"
                    onClick={postAssignJob}
                  >
                    Save
                    {loading && (
                      <span className="mx-3 spinner spinner-white"> </span>
                    )}
                  </Button>}
                </Row>


              </FormItem>
            </div>
          )
        }
      </Col>
    </Card>
  );
}
