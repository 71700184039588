// import { Button, Card, Table } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  ASSIGNED_BDM_LIST,
  ASSIGNED_RECRUITER_LIST,
  BDM_ALL_JOBS,
  CANDIDATE,
  CANDIDATE_LIST_AUTO_SOURCED,
  JOBS_LIST_BDM,
} from "../../../ApiUrl";
import { requestHeader } from "../../../others/header";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  Table,
  Card,
  Input,
  Select,
  Button,
  Popconfirm,
  Spin,
  Space,
  Col,
  Empty,
} from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { getFormatDate } from "../utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useCallAPI } from "../../../hooks/useCallAPI";
import { setShouldRefresh } from "../../../redux/reducers/candidatesSlice";
import { handleSearch } from "../../../others/handler/handleSearch";
import Search from "antd/lib/transfer/search";

export function JobOpenings({ type = "bdm" }) {
  const [page, setPage] = useState(1);
  const users = useSelector(({ users }) => users);
  const nativateTo = `/${type}/autosource-candidate`;
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const AssignedJobs = useCallAPI(ASSIGNED_RECRUITER_LIST);
  const BDMJobs = useCallAPI(BDM_ALL_JOBS);
  const [search, setSearch] = useState("");
  const timeoutRef = useRef(null);
  
  const columns = [
    {
      title: "Opening Title",
      dataIndex: "opening_title",
      key: "opening_title",
    },
    {
      title: "Opening id",
      dataIndex: "opening_id",
      key: "opening_id",
    },
    (type === "admin" || type === "bdm") && {
      title: "Recruiters",
      dataIndex: "recruiterDetails",
      key: () => {
        this.dataIndex;
      },
      render: (text, record) => {
        const { recruiterDetails, freelancerDetails } = record;
        const allRecruiter = recruiterDetails?.concat(freelancerDetails);
        return allRecruiter?.map((rec) => rec?.display_name).join(", ");
      },
    },
    type === "admin" && {
      title: "BDMs",
      dataIndex: "bdmDetails",
      key: () => {
        this.dataIndex;
      },
      render: (text, record) => {
        const { bdmDetails } = record;
        return bdmDetails?.map((bdm) => bdm?.display_name).join(", ");
      },
    },
    {
      title: "Auto sourced",
      dataIndex: "autosourced_candidates_count",
      key: "autosourced_candidates_count",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (text, record, index) => (
        <>
          <NavLink
            onClick={() => dispatch(setShouldRefresh(true))}
            to={{
              pathname: nativateTo,
              state: { record: record },
            }}
          >
            <Button type="text" icon={<EyeOutlined />} />
          </NavLink>
        </>
      ),
    },
  ].filter(Boolean);
  const getAssignedJobs = () => {
    let param = {
      recruiter_id: type === "recruiter" ? users.user._id : "",
      current_page: page,
      per_page: pageSize,
      order_direction: "desc",
      order: "created_at",
      search: search ? search.trim() : "",
      dateRange: [],
      categories: [],
      status: "",
      company_id: "",
      freelance_id: type === "freelancerecruiter" ? users.user._id : "",
    };
    AssignedJobs.post(param);
  };

  useEffect(() => {
    if (
      type == "recruiter" ||
      type === "freelancerecruiter" ||
      type == "admin"
    ) {
      getAssignedJobs();
    } else {
      BDMJobs.get(users.user._id, `current_page=${page}&per_page=${pageSize}`);
    }
  }, [page, search]);

  return (
    <Card
      title="Job Openings List"
      bordered={false}
      extra={
        <Col>
          <Search
            placeholder="Search..."
            onChange={(e) => handleSearch(e, timeoutRef, setSearch)}
            style={{ width: 200 }}
          />
        </Col>
      }
    >
      {AssignedJobs.data?.data?.job_opening_listing?.length > 0 ||
      BDMJobs.data?.data?.length > 0 ? (
        <Table
          pagination={{
            total:
              AssignedJobs.data?.data?.totalRecords ||
              BDMJobs.data?.data?.paginate?.totalData,
            pageSize: pageSize,
            showSizeChanger: false,
            onChange(current) {
              setPage(current);
            },
          }}
          dataSource={
            AssignedJobs.data?.data?.job_opening_listing || BDMJobs.data?.data
          }
          columns={columns}
          loading={AssignedJobs.loading || BDMJobs.loading}
          scroll={{ x: "auto" }}
        />
      ) : (
        <Empty />
      )}
    </Card>
  );
}

export function AutoSourceCandidate({ type = "bdm" }) {
  const [candidateList, setCandidateList] = useState([]);
  const location = useLocation();
  const { record } = location.state || {};
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const CandidateColumns = [
    {
      title: "Display Name",
      dataIndex: ["candidate", "first_name"],
      key: "candidate.first_name",
    },
    {
      title: "Job Category",
      dataIndex: ["candidate", "job_category"],
      key: "job_category",
    },
    {
      title: "Status",
      dataIndex: ["candidate", "status"],
      key: "status",
    },
    {
      title: "Created Date",
      dataIndex: ["candidate", "created_at"],
      key: "created_at",
      render: (date) => {
        return date ? getFormatDate(date) : "";
      },
    },
    {
      title: "Action",
      dataIndex: ["candidate", "_id"],
      key: "action",
      render: (text, records) => (
        <span>
          <Space size="middle">
            <NavLink
              to={{
                pathname: `/${type}/candidateProfileDetail`,
                state: {
                  item: { ...records.candidate, opening_id: record.opening_id },
                },
              }}
            >
              <Button type="primary" icon={<EyeOutlined />} />
            </NavLink>
          </Space>
        </span>
      ),
    },
  ];

  const getCandidates = (jobId) => {
    axios
      .get(
        `${CANDIDATE_LIST_AUTO_SOURCED}/${jobId}?&isAutoSourced=true&page=${page}&size=${pageSize}`,
        requestHeader()
      )
      .then((res) => {
        // console.log(res.data);
        setCandidateList(res.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (record) {
      getCandidates(record.opening_id);
    }
  }, [record, page]);

  return (
    <Card title="Candidates List" bordered={false}>
      <Table
        pagination={{
          total: candidateList?.paginate?.totalData,
          pageSize,
          showSizeChanger: false,
          onChange(current) {
            setPage(current);
          },
        }}
        dataSource={candidateList.data}
        columns={CandidateColumns}
        scroll={{ x: "auto" }}
      />
    </Card>
  );
}
