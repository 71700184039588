export const candidateChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      labels: {
        color: "#4a4a4a",
        font: {
          size: 14,
          weight: "bold",
        },
      },
    },
    tooltip: {
      mode: "index",
      intersect: false,
      backgroundColor: "rgba(0,0,0,0.7)",
      titleColor: "#ffffff",
      bodyColor: "#ffffff",
      borderColor: "#ddd",
      borderWidth: 1,
      cornerRadius: 4,
    },
  },
  scales: {
    x: {
      grid: {
        borderDash: [5],
        color: "#e9ecef",
      },
      ticks: {
        color: "#6c757d",
        font: {
          size: 12,
        },
      },
    },
    y: {
      grid: {
        display: false,
        borderDash: [5],
        color: "#e9ecef",
      },
      ticks: {
        color: "#6c757d",
        font: {
          size: 12,
        },
        beginAtZero: true,
      },
    },
  },
  elements: {
    line: {
      tension: 0.4,
    },
    point: {
      radius: 3,
      hoverRadius: 6,
      backgroundColor: "#ffffff",
      borderWidth: 2,
    },
  },
  interaction: {
    intersect: false,
    mode: "index",
  },
};

export const getDashboardChartData = (
  candidates = [],
  clients = [],
  jobs = []
) => {
  return {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Candidates",
        data: candidates,
        fill: true,
        backgroundColor: "rgba(105, 147, 255, 0.2)",
        borderColor: "#6993FF",
        borderWidth: 2,
      },
      {
        label: "Clients",
        data: clients,
        fill: true,
        backgroundColor: "rgba(137, 80, 252, 0.2)",
        borderColor: "#8950FC",
        borderWidth: 2,
      },
      {
        label: "Jobs",
        data: jobs,
        fill: true,
        backgroundColor: "rgba(246, 78, 96, 0.2)",
        borderColor: "#F64E60",
        borderWidth: 2,
      },
    ],
  };
};
