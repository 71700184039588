import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";

export const GlobalSettings = ({
  onClickLinkedIn,
  onClickSendEmail,
  searchContact,
}) => {
  const items = [];
  
  if (onClickLinkedIn) {
    items.push({
      key: 'linkedin',
      label: <Button onClick={onClickLinkedIn}>LinkedIn Invitation</Button>
    });
  }

  if (onClickSendEmail) {
    items.push({
      key: 'email',
      label: <Button onClick={onClickSendEmail}>Send Email</Button>
    });
  }

  if (searchContact) {
    items.push({
      key: 'contact',
      label: <Button onClick={searchContact}>Search Contact</Button>
    });
  }

  return (
    <Dropdown 
      menu={{ items }} 
      trigger={["hover"]}
      placement="bottomRight"
    >
      <Button type="text" icon={<MoreOutlined style={{ fontSize: "24px" }} />} />
    </Dropdown>
  );
};
