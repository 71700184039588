import React from "react";
import WebAccess from "./WebAccess";

const Configuration = () => {
  return (
    <div className="p-5">
      <WebAccess />
    </div>
  );
};

export default Configuration;
