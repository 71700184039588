import { Button, Form, Select, Tag, Space, Input, message, Typography } from 'antd';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import React, { useEffect, useState, useCallback } from 'react';
import { useCallAPI } from '../../../../hooks/useCallAPI';
import {
  CREATE_LINKEDIN_EMAIL_TEMPLATE,
  UPDATE_LINKEDIN_EMAIL_TEMPLATE,
  GET_LINKEDIN_EMAIL_TEMPLATE_BY_USER
} from '../../../../ApiUrl';

const { Option } = Select;
const { Text } = Typography;

export default function CandidateLinkedinInvitation({
  form,
  selectedRows,
  sendInvitation,
  maxChars = 300
}) {
  const [editor, setEditor] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [charCount, setCharCount] = useState(0);
  const [isSavingTemplate, setIsSavingTemplate] = useState(false);

  const templateAPI = useCallAPI(GET_LINKEDIN_EMAIL_TEMPLATE_BY_USER);
  const createTemplate = useCallAPI(CREATE_LINKEDIN_EMAIL_TEMPLATE);
  const updateTemplate = useCallAPI(UPDATE_LINKEDIN_EMAIL_TEMPLATE);

  const fetchTemplates = async () => {
    try {
      setIsLoadingTemplates(true);
      await templateAPI.getList();
      
      // Add a small delay to ensure state is updated
      setTimeout(() => {
        if (templateAPI.data?.data) {
          console.log('Setting templates:', templateAPI.data.data);
          setTemplates(templateAPI.data.data);
        }
      }, 100);
      
    } catch (error) {
      console.error('Template fetch error:', error);
      message.error('Failed to load templates');
    } finally {
      setIsLoadingTemplates(false);
    }
  };

  // Monitor templateAPI.data changes
  useEffect(() => {
    if (templateAPI.data?.data) {
      console.log('Template data changed:', templateAPI.data.data);
      setTemplates(templateAPI.data.data);
    }
  }, [templateAPI.data]);



  // Remove the loose console.logs and add them to a useEffect
  useEffect(() => {
    console.log('Templates state updated:', templates);
    console.log('Loading state:', isLoadingTemplates);
  }, [templates, isLoadingTemplates]);

  // Add dependency array to useEffect to prevent infinite loops
  useEffect(() => {
    fetchTemplates();
  }, []); // Empty dependency array
  
  // Add cleanup function
  useEffect(() => {
    return () => {
      setTemplates([]);
      setIsLoadingTemplates(false);
    };
  }, []);

  const handleTemplateSelect = useCallback((value) => {
    if (!value) {
      setSelectedTemplateId(null);
      resetForm();
      return;
    }

    const template = templates.find(t => t._id === value);
    if (template) {
      setSelectedTemplateId(value);
      form.setFieldsValue({
        template_name: template.template_name,
        message: template.content
      });
      if (editor) {
        editor.setData(template.content || '');
      }
    }
  }, [templates, editor, form]);

  const handleSaveTemplate = async () => {
    const values = form.getFieldsValue();
    const templateData = {
      template_name: values.template_name,
      email_type: 'linkedin_invitation',
      content: values.message
    };


    const tempId = `temp_${Date.now()}`;
    
    try {
    
      const optimisticTemplate = {
        _id: tempId,
        ...templateData,
        isOptimistic: true 
      };

      setTemplates(prev => [...prev, optimisticTemplate]);
      setSelectedTemplateId(tempId);

      if (selectedTemplateId) {
        // Update existing template
        const response = await updateTemplate.put(templateData, selectedTemplateId);
        
        // Update the templates list with actual data
        setTemplates(prev => prev.map(t => 
          t._id === selectedTemplateId ? { ...response.data.data, _id: selectedTemplateId } : t
        ));
        
        message.success('Template updated successfully');
      } else {
        // Create new template
        const response = await createTemplate.post(templateData);
        const newTemplate = response.data.data;
        
        // Replace optimistic entry with actual data
        setTemplates(prev => prev.map(t => 
          t._id === tempId ? newTemplate : t
        ));
        setSelectedTemplateId(newTemplate._id);
        
        message.success('Template saved successfully');
      }
    } catch (err) {
      // Remove optimistic entry on error
      setTemplates(prev => prev.filter(t => t._id !== tempId));
      setSelectedTemplateId(null);
      message.error('Failed to save template');
    }
  };

  const insertVariable = (variable) => {
    if (editor) {
      const content = editor.getData();
      const insertion = `{{${variable}}}`;
      editor.setData(content + insertion);
      form.setFieldsValue({ message: content + insertion });
    }
  };

  const resetForm = () => {
    form.setFieldsValue({
      template_name: '',
      message: ''
    });
    if (editor) {
      editor.setData('');
    }
  };

  // Modified renderTemplateOptions to handle loading and optimistic states
  const renderTemplateOptions = useCallback(() => {
    return [
      <Option key="select-placeholder" disabled value="">
        Select a template
      </Option>,
      ...templates.map(template => (
        <Option 
          key={template._id} 
          value={template._id}
          style={template.isOptimistic ? { fontStyle: 'italic' } : {}}
        >
          {template.template_name} {template.isOptimistic ? '(Saving...)' : ''}
        </Option>
      ))
    ];
  }, [templates]);

  const handleEditorChange = (event, editor) => {
    const content = editor.getData();
    const plainText = content.replace(/<[^>]*>/g, ''); // Strip HTML tags for accurate count
    setCharCount(plainText.length);
    
   
    form.setFieldsValue({ message: content });
  };

  const counterStyle = {
    position: 'absolute',
    bottom: '-20px',
    right: '0',
    fontSize: '12px',
    color: charCount > maxChars ? '#ff4d4f' : charCount > maxChars * 0.9 ? '#faad14' : '#8c8c8c'
  };

  const handleSubmit = async (values) => {
    try {
      // Remove template_name validation when sending invitation
      const { displayName, message } = values;
      await sendInvitation({ displayName, message });
      form.resetFields();
      if (editor) {
        editor.setData('');
      }
    } catch (error) {
      console.error('Error sending invitation:', error);
      message.error('Failed to send invitation');
    }
  };

  const templateNameRules = isSavingTemplate ? 
    [{ required: true, message: "Please enter template name" }] : 
    [];

  return (
    <Form
      form={form}
      name="linkedin_message"
      onFinish={handleSubmit}
      layout="vertical"
      style={{ maxWidth: "700px", margin: "auto" }}
    >
      <Form.Item
        name="displayName"
        label="LinkedIn Username"
        rules={[{ required: true, message: "Please select recipients" }]}
      >
        <Select
          mode="tags"
          style={{ width: "100%" }}
          placeholder="LinkedIn Users"
        >
          {selectedRows?.map((row) => (
            <Option key={row._id} value={row.candidate.first_name}>
              <Tag color="blue">{row.candidate.first_name}</Tag>
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Templates">
        <Select
          placeholder="Select a template"
          onChange={handleTemplateSelect}
          value={selectedTemplateId}
        //   loading={isLoadingTemplates}
        loading={templateAPI.loading}
          allowClear
          style={{ width: '100%' }}
        >
          {renderTemplateOptions()}
        </Select>
      </Form.Item>

      <Form.Item
        name="template_name"
        label="Template Name"
        rules={templateNameRules}
        style={{ display: isSavingTemplate ? 'block' : 'none' }}
      >
        <Input placeholder="Enter template name" />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" onClick={() => insertVariable("name")}>
            Name
          </Button>
          <Button type="primary" onClick={() => insertVariable("email")}>
            Email
          </Button>
          <Button type="primary" onClick={() => insertVariable("phone")}>
            Phone
          </Button>
        </Space>
      </Form.Item>

      <Form.Item
        name="message"
        label="Message"
        rules={[
          { required: true, message: "Please enter your message" },
          {
            validator: (_, value) => {
              const plainText = value?.replace(/<[^>]*>/g, '') || '';
              if (plainText.length > maxChars) {
                return Promise.reject(
                  `Message exceeds ${maxChars} characters limit`
                );
              }
              return Promise.resolve();
            }
          }
        ]}
        style={{ position: 'relative' }}
      >
        <div>
          <CKEditor
            editor={ClassicEditor}
            onReady={editor => {
              setEditor(editor);
              // Configure editor
              editor.editing.view.change(writer => {
                writer.setStyle(
                  'min-height',
                  '200px',
                  editor.editing.view.document.getRoot()
                );
              });
            }}
            onChange={handleEditorChange}
            config={{
              toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'undo', 'redo'],
              // Limit paste to plain text to prevent unwanted HTML
              clipboard: {
                defaultDataTransfer: 'text'
              }
            }}
          />
          <div style={counterStyle}>
            <Text 
              type={charCount > maxChars ? 'danger' : charCount > maxChars * 0.9 ? 'warning' : 'secondary'}
            >
              {charCount}/{maxChars} characters
            </Text>
          </div>
          {charCount > maxChars && (
            <Text type="danger" style={{ fontSize: '12px', marginTop: '4px', display: 'block' }}>
              Content exceeding {maxChars} characters will not be sent
            </Text>
          )}
        </div>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            Send Invitation
          </Button>
          <Button 
            onClick={handleSaveTemplate}
            loading={createTemplate.loading || updateTemplate.loading}
          >
            {selectedTemplateId ? 'Update Template' : 'Save as Template'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
