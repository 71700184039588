import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { NavLink, useLocation } from "react-router-dom";
import {
  ASSIGNED_BDM_LIST,
  ASSIGNED_RECRUITER_LIST,
  CREATE_OR_UPDATE_PREFERENCE,
  GET_JOB_OPENINGS,
  JOB_UPDATE,
  PREFERENCE_LIST,
} from "../../../ApiUrl";
import {
  Button,
  Card,
  Col,
  Input,
  InputNumber,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { connect, useSelector } from "react-redux";
import { OpeningStatusList } from "../constant/constant";
import {
  ColumnHeightOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import Filters from "../../modules/filter";
import { getFormatDate } from "../utils/helpers";
import { useCallAPI } from "../../../hooks/useCallAPI";
import EditColumns from "../../modules/Modals/EditColumns";
import { requestHeader } from "../../../others/header";
import Notes from "../../modules/Modals/Notes";
import { handleSearch } from "../../../others/handler/handleSearch";

const { Search } = Input;

export default function AssignedJobs_v2() {
  const users = useSelector(({ users }) => users);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: localStorage.getItem("recJobAssingingPageSize") || 10,
  });
  const [categoryList, setCategoryList] = useState([]);
  const [search, setSearch] = useState("");
  const [visibleColumns, setVisibleColumns] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  //call api
  const AssignedJobs = useCallAPI(ASSIGNED_RECRUITER_LIST);
  const AssignedBDMJobs = useCallAPI(ASSIGNED_BDM_LIST);
  const HandleColumnView = useCallAPI(CREATE_OR_UPDATE_PREFERENCE);
  const NotesData = useCallAPI(GET_JOB_OPENINGS);
  const UpdateJobOpenings = useCallAPI(JOB_UPDATE);
  const location = useLocation();
  const { candidateId } = location.state || {};
  // console.log("testing the candidate id :", candidateId);
  const callGetAssignedJobs = () => {
    let param = {
      current_page: pagination.page,
      per_page: pagination.pageSize,
      order_direction: "desc",
      search: search,
      order: "created_at",
      dateRange: "", //value.arrayDateRange,
      categories: "", //value.categories,
      status: "", //value.status,
      company_id: "", //value.selectedCompany,
      // recruiter_id: users.user._id,
      // freelance_id: users.user._id,
    };

    if (users.role == "recruiter") {
      param.recruiter_id = users.user._id;
      if (!AssignedJobs.loading) {
        AssignedJobs.post(param);
      }
    }

    if (users.role == "freelancerecruiter") {
      param.freelance_id = users.user._id;
      if (!AssignedJobs.loading) {
        AssignedJobs.post(param);
      }
    }

    if (users.role == "bdm") {
      if (!AssignedBDMJobs.loading) {
        AssignedBDMJobs.post({
          current_page: pagination.page,
          per_page: pagination.pageSize,
          sort_order: "desc",
          filter_value: "",
          order: "created_at",
          dateRange: "", //value.arrayDateRange,
          categories: "", //value.categories,
          status: "", //value.status,
          company_id: "",
          bdm_id: users.user._id,
          recruiter_id: "",
        });
      }
    }
  };

  const handleColumn = (key, show) => {
    setColumns(
      columns.map((column) =>
        column.key === key ? { ...column, show: show } : column
      )
    );
  };

  const saveViewPreference = () => {
    HandleColumnView.post({
      assing_jobs_preference: columns?.map((col) => ({
        title: col.title,
        show: col.show,
        dataIndex: col.dataIndex,
      })),
    });
    setVisibleColumns(false);
  };

  const getListPreference = () => {
    axios
      .get(`${PREFERENCE_LIST}?field="assing_jobs_preference"`, requestHeader())
      .then((response) => {
        const updatedColumns = columns.map((column) => {
          const preference = response.data.data.assing_jobs_preference.find(
            (pref) =>
              JSON.stringify(pref.title) === JSON.stringify(column.title)
          );
          if (preference) {
            return { ...column, show: preference.show };
          }
          return column;
        });

        setColumns(updatedColumns);
        // console.log(columns);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    callGetAssignedJobs();
  }, [search, pagination]);

  useEffect(() => {
    getListPreference();

    // BDMList.getList();
    // RecruiterList.getList();
  }, []);

  useEffect(() => {
    const initialPage = window.history.state?.page || 1;
    setPagination({ ...pagination, page: initialPage });

    const handlePopState = (event) => {
      const page = event.state?.page || 1;
      setPagination({ ...pagination, page: page });
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const getNotes = (record) => {
    NotesData.get(record.opening_id, "fields='notes,opening_id'");
    setSelectedRow(record);
    setShowNotes(true);
  };

  const handleNotes = (value) => {
    // setShowNotes(false);
    UpdateJobOpenings.put({ notes: value }, selectedRow._id);
  };

  const [columns, setColumns] = useState([
    {
      // title: "Opening Title",
      dataIndex: "_id",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (data, record) => {
        // return date ? getFormatDate(date) : "";
        return (
          <Button
            onClick={() => {
              getNotes(record);
            }}
          >
            N
          </Button>
        );
      },
    },
    {
      title: "Opening Title",
      dataIndex: "opening_title",
      show: true,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Opening id",
      dataIndex: "opening_id",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (text, record) => (
        <Typography.Text copyable>{record.opening_id}</Typography.Text>
      ),
    },
    {
      title: "Skills",
      dataIndex: "required_skills",
      show: true,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Visa",
      dataIndex: "visa_type",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Experience",
      dataIndex: "required_experience",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Pay By",
      dataIndex: "pay_currency",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Pay Type",
      dataIndex: "pay_type",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Address",
      // dataIndex: ["country"],
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (text, record) => {
        const { country, state, city, zip_code } = record;
        return `${country}, ${state}, ${city}, ${zip_code}`;
      },
    },
    {
      title: "Vacancy",
      dataIndex: "number_of_openings",
      show: true,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Candidates",
      dataIndex: "candidate_submission_count",
      show: true,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Autosourced",
      dataIndex: "autosourced_candidates_count",
      show: true,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Description",
      dataIndex: "short_description",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Type",
      dataIndex: "employment_type",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Experience Level",
      dataIndex: "experience_level",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Interview Type",
      dataIndex: "interview_type",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    // {
    //   title: "Notes",
    //   dataIndex: "notes",
    //   show: false,
    //   key: () => {
    //     this.dataIndex;
    //   },
    // },
    {
      title: "Salary",
      // dataIndex: "short_description",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (text, record) => {
        const {
          salary_range_from,
          salary_range_to,
          currency,
          salary_type,
        } = record;
        return `${salary_range_from}-${salary_range_to} ${currency}/${salary_type}`;
      },
    },
    {
      title: "Interviews",
      dataIndex: "short_description",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    // {
    //   title: "Recruiters",
    //   dataIndex: "recruiters",
    //   show: false,
    //   key: () => {
    //     this.dataIndex;
    //   },
    // },
    // {
    //   title: "BDMs",
    //   dataIndex: "assignments",
    //   show: true,
    //   key: () => {
    //     this.dataIndex;
    //   },
    //   render: (text, record) => {
    //     const { assignments } = record;
    //     // console.log("dami: ", assignments);
    //     // let bdms = assignments[0].assigned_bdm;
    //     // console.log("data: ", BDMList?.data)

    //     // let selectedBDM = BDMList.data?.data; //?.filter((bdm) => bdm._id in bdms);
    //     // console.log(BDMList);
    //     // return selectedBDM?.map((bdm) => bdm?.display_name);
    //     // return selectedBDM;
    //     return "bdms";
    //   },
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   show: true,
    //   key: () => {
    //     this.dataIndex;
    //   },
    //   render: (Code, record, index) => {
    //     return (
    //       <Select
    //         placeholder="Select Status Name"
    //         value={Code}
    //         onChange={async (status) => {
    //           await UpdateOpeningStatus.put({ status, status }, record._id);
    //           callJobOpenings();
    //         }}
    //         style={{ width: "100%" }}
    //       >
    //         <Option value={""}>{"Select"}</Option>
    //         {OpeningStatusList !== undefined &&
    //           OpeningStatusList.map((status, index) => (
    //             <Option key={index.toString()} value={status.value}>
    //               {status.label}
    //             </Option>
    //           ))}
    //       </Select>
    //     );
    //   },
    // },
    {
      title: "Posted Date",
      dataIndex: "created_at",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (date) => {
        return date ? getFormatDate(date) : "";
      },
    },
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   show: true,
    //   key: () => {
    //     this.dataIndex;
    //   },
    //   render: (text, record, index) => (
    //     <Space size="middle">
    //       <NavLink
    //         to={{
    //           pathname: `/${users.role}/opening-detail`,
    //           state: { record: record },
    //         }}
    //       >
    //         <Button type="primary" icon={<EyeOutlined />} />
    //       </NavLink>
    //       <NavLink
    //         to={{
    //           pathname: `/${users.role}/add-opening`,
    //           state: { record: record },
    //         }}
    //       >
    //         <Button
    //           // onClick={(e) => {
    //           //   this.onEdit(record._id, e);
    //           // }}
    //           type="primary"
    //           icon={<EditOutlined />}
    //         />
    //       </NavLink>
    //       <NavLink
    //         to={{
    //           state: { record: record },
    //         }}
    //       >
    //         <Popconfirm
    //           title="Are you sure you want to delete this opening ?"
    //           onConfirm={() => onDelete(record._id)}
    //           icon={<DeleteOutlined style={{ color: "red" }} />}
    //         >
    //           <Button type="danger" icon={<DeleteOutlined />} />
    //         </Popconfirm>
    //       </NavLink>
    //     </Space>
    //   ),
    // },
    {
      title: "Action",
      dataIndex: "",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (text, record, index) => {
        if (users.role == "bdm") {
          return (
            <NavLink
              to={{
                pathname: "/bdm/opening-detail",
                state: { record: record },
              }}
            >
              <Button type="primary" icon={<EyeOutlined />} />
            </NavLink>
          );
        } else if (users.role == "recruiter") {
          return (
            <NavLink
              to={{
                pathname: "/recruiter/opening-detail",
                state: { record: record, candidateId: candidateId },
              }}
            >
              <Button type="primary" icon={<EyeOutlined />} />
            </NavLink>
          );
        }
      },
    },
  ]);
  const timeoutRef = useRef(null);
  return (
    <div>
      {/* <Filters
        callBack={this.callBack}
        showCategory={false}
        showCompany={true}
        clearSearch={this.onSearch}
      /> */}

      <EditColumns
        columns={columns}
        isVisible={visibleColumns}
        handleCancel={() => {
          setVisibleColumns(false);
        }}
        handleColumn={handleColumn}
        save={saveViewPreference}
      />

      <Notes
        handleCancel={() => setShowNotes(false)}
        isVisible={showNotes}
        handleNotes={handleNotes}
        notes={NotesData}
        jobOpenings={selectedRow}
        user={users}
        updateNoteState={setShowNotes}
      />

      <div className="row">
        <div className="col-lg-12">
          <div className={`card card-custom card-stretch gutter-b`}>
            <div className="card-body py-3 px-3">
              <Card
                title="Assigned Job List"
                bordered={false}
                className="px-0 py-0"
              >
                <Row
                  style={{ width: "100%" }}
                  align="middle"
                  justify="space-between"
                >
                  <Col>
                    <Search
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e, timeoutRef, setSearch)}
                      style={{ width: 200 }}
                    />
                  </Col>

                  <Col>
                    <Space>
                      <Tooltip title="set page size">
                        <InputNumber
                          min={1}
                          value={pagination.pageSize}
                          onChange={(value) => {
                            setPagination({ ...pagination, pageSize: value });
                            localStorage.setItem("jobOpeningsPageSize", value);
                          }}
                          style={{ width: 100, textAlign: "center" }}
                        />
                      </Tooltip>

                      <Button
                        onClick={() => {
                          setVisibleColumns(true);
                        }}
                        icon={<ColumnHeightOutlined />}
                      >
                        Columns
                      </Button>

                      {/* <GlobalSettings
                        onClickLinkedIn={handleLinkedInInviationModal}
                      /> */}
                    </Space>
                  </Col>
                </Row>

                <br />

                <Table
                  pagination={{
                    total: AssignedJobs.data?.data?.totalRecords,
                    showSizeChanger: false,
                    pageSize: pagination.pageSize,
                    current: pagination.page,
                    onChange(current) {
                      setPagination({ ...pagination, page: current });
                      window.history.pushState(
                        { page: current },
                        "",
                        `?page=${current}`
                      );
                    },
                  }}
                  dataSource={
                    AssignedJobs.data?.data?.job_opening_listing ||
                    AssignedBDMJobs.data?.data.job_opening_listing
                  }
                  columns={columns.filter((column) => column.show)}
                  loading={
                    // JobOpenings.loading ||
                    // DeleteJob.loading ||
                    // UpdateOpeningStatus.loading ||
                    AssignedBDMJobs.loading || AssignedJobs.loading
                  }
                  // scroll={{ x: 2000 }}
                  style={{
                    overflowX: "scroll",
                    height: "500px",
                  }}
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
