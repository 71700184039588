import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Typography,
  Button,
  Space,
  Divider,
  Tag,
  message,
  Modal,
  Tooltip,
  Upload,
  Breadcrumb,
} from "antd";
import moment from "moment";
import axios from "axios";
import {
  CANDIDATE_APPLY_JOB,
  CANDIDATE_REMOVE_JOB,
  CANDIDATE_SAVE_JOB,
  JOB_DETAIL,
  JOB_ASSIGNMENT_DETAILS,
  JOB_DETAIL_PUBLIC,
  CANDIDATE_REGISTER,
  CANDIDATE_REGISTER_QUICK_APPLY,
} from "../../../ApiUrl";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import {
  SaveOutlined,
  CheckOutlined,
  EnvironmentOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  experienceRanges,
  optionEmploymentType,
  optionsexperienceLevel,
  optionsInterviewType,
} from "../constant/constant";
import { showError } from "../utils/helpers";
import { Helmet } from "react-helmet";
import { ChevronLeft } from "@material-ui/icons";

const { Text, Title } = Typography;

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;

export default function SearchJobDetail(props) {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [msgError, setmsgError] = useState("");
  const [openingDetails, setOpeningDetails] = useState({});
  const [CandiadteDetail, setCandiadteDetail] = useState(null);
  const { openingId } = useParams();
  const users = useSelector(({ users }) => users);
  const [quickApplyValues, setQuickApplyValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    resume: null,
  });
  let location = useLocation();
  const history = useHistory();

  const handleQuickApplyChange = (e) => {
    setQuickApplyValues({
      ...quickApplyValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleQuickApplyClose = () => {
    setIsQuickApplyVisible(false);
    setIsModalVisible(true);
  };

  const getOpeningDetail = () => {
    axios
      .get(
        openingId
          ? JOB_DETAIL_PUBLIC + "/" + openingId
          : JOB_DETAIL + "/" + location.state.item._id,
        {
          headers: { Authorization: users.token },
        }
      )
      .then((res) => setOpeningDetails(res.data.data[0]))
      .catch(() => {});
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isQuickApplyVisible, setIsQuickApplyVisible] = useState(false);
  const [quickApplyLoading, setQuickApplyLoading] = useState(false);

  const handleModalOk = () => {
    localStorage.setItem(
      "redirectAfterSignup",
      `/openings/${openingDetails.opening_id}`
    );
    setIsModalVisible(false);
    history.push("/auth/registration");
  };
  const handleModalSignIn = () => {
    localStorage.setItem(
      "redirectAfterSignup",
      `/openings/${openingDetails.opening_id}`
    );
    setIsModalVisible(false);
    history.push("/auth/login");
  };
  const handleQuickModalVisible = () => {
    setIsModalVisible(false);
    setIsQuickApplyVisible(true);
  };

  const handleModalCancel = () => setIsModalVisible(false);

  const onApplyJob = async (item) => {
    const tokenExist = localStorage.getItem("token");
    if (!tokenExist || !users.token) {
      setIsModalVisible(true);
      return;
    }
    if (!item || !item?.job_apply) {
      const res = await axios.post(
        JOB_ASSIGNMENT_DETAILS,
        { opening_id: openingDetails?.opening_id },
        { headers: { Authorization: users.token } }
      );

      let assignedRecruiters = new Set();
      res.data.data?.forEach((element) => {
        if (!element.assigned_bdm.length) {
          assignedRecruiters.add(...element.assigned_recruiter);
        }
      });

      const params = {
        candidate_id: users.user._id,
        job_opening_id: openingDetails?.opening_id,
        recruiter_id: Array.from(assignedRecruiters),
        profile_submit: "1",
      };

      axios
        .post(CANDIDATE_APPLY_JOB, params, {
          headers: { Authorization: users.token },
        })
        .then((res) => {
          if (!res.data.error) {
            setSuccess(true);
            setError(false);
            setMsgSuccess(res.data.message);
            // setTimeout(() => props.history.goBack(), 3000);
            setDefaultState();
          }
        })
        .catch((error) => {
          console.log(error);
          handleError(error);
        });
    }
  };

  const onSaveJob = (item) => {
    const endpoint = item.job_save
      ? CANDIDATE_REMOVE_JOB + "/" + item.job_save._id
      : CANDIDATE_SAVE_JOB;
    const method = item.job_save ? "delete" : "post";
    const params = item.job_save
      ? null
      : { candidate_id: users.user._id, job_opening_id: item.opening_id };

    axios[method](endpoint, params, { headers: { Authorization: users.token } })
      .then((res) => !res.data.error && props.history.goBack())
      .catch((error) => handleError(error));
  };

  const handleError = (error) => {
    if (error.response) {
      const errorMsg = Object.values(error.response.data.errors).join(", ");
      setError(true);
      setmsgError(errorMsg);
      setDefaultState();
    }
  };

  const setDefaultState = () => {
    setTimeout(() => {
      setSuccess(false);
      setError(false);
      setMsgSuccess("");
      setmsgError("");
    }, 3000);
  };

  const createMarkup = () => ({ __html: openingDetails?.job_description });

  const daysAgoText = () => {
    const given = moment(openingDetails?.created_at, "YYYY-MM-DD");
    const current = moment();

    const minutes = current.diff(given, "minutes");
    const hours = current.diff(given, "hours");
    const days = current.diff(given, "days");
    const months = current.diff(given, "months");
    const years = current.diff(given, "years");

    if (minutes < 60) return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    if (hours < 24) return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    if (days < 30) return `${days} day${days !== 1 ? "s" : ""} ago`;
    if (months < 12) return `${months} month${months !== 1 ? "s" : ""} ago`;
    return `${years} year${years !== 1 ? "s" : ""} ago`;
  };
  useEffect(() => {
    getOpeningDetail();
    setCandiadteDetail(() => (location.state ? location.state.item : null));
  }, []);

  //  auto apply job after signup or login
  useEffect(() => {
    if (!openingDetails?.opening_id) return;
    const redirectAfterSignup = localStorage.getItem("redirectAfterSignup");
    if (redirectAfterSignup) {
      const opeingID = redirectAfterSignup.split("/")[2];
      if (opeingID !== openingDetails?.opening_id) {
        localStorage.removeItem("redirectAfterSignup");
        return;
      }
      (async () => {
        const res = await axios.post(
          JOB_ASSIGNMENT_DETAILS,
          { opening_id: openingDetails?.opening_id },
          { headers: { Authorization: users.token } }
        );

        let assignedRecruiters = new Set();
        res.data.data.forEach((element) => {
          if (!element.assigned_bdm.length) {
            assignedRecruiters.add(...element.assigned_recruiter);
          }
        });

        const params = {
          candidate_id: users.user._id,
          job_opening_id: openingDetails?.opening_id,
          recruiter_id: Array.from(assignedRecruiters),
          profile_submit: "1",
        };

        axios
          .post(CANDIDATE_APPLY_JOB, params, {
            headers: { Authorization: users.token },
          })
          .then((res) => {
            if (!res.data.error) {
              setSuccess(true);
              setError(false);
              setMsgSuccess(res.data.message);
              // setTimeout(() => props.history.goBack(), 3000);
              setDefaultState();
            }
          })
          .catch((error) => {
            handleError(error);
          })
          .finally(() => {
            localStorage.removeItem("redirectAfterSignup");
          });
      })();
    }
  }, [
    openingDetails?.opening_id,
    users.token,
    users.user._id,
    users,
    location.state,
  ]);
  const handleQuickApply = async () => {
    if (
      !quickApplyValues.first_name ||
      !quickApplyValues.last_name ||
      !quickApplyValues.email ||
      !quickApplyValues.resume
    ) {
      message.error("All fields are required for quick apply.");
      return;
    }
    setQuickApplyLoading(true);
    const reader = new FileReader();

    reader.onload = async () => {
      const base64String = reader.result;
      try {
        const { data } = await axios.post(
          CANDIDATE_REGISTER_QUICK_APPLY,
          {
            first_name: quickApplyValues.first_name,
            last_name: quickApplyValues.last_name,
            email: quickApplyValues.email,
            mobile: quickApplyValues.phone,
            attachments: base64String,
            job_opening_id: openingDetails.opening_id,
            job_opening_title: openingDetails.opening_title,
            profile_submit: 1,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            },
          }
        );
        if (!data.error) {
          setSuccess(true);
          setError(false);
          setMsgSuccess(data.message);
          setQuickApplyValues({
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            resume: null,
          });
          setIsQuickApplyVisible(false);
          setDefaultState();
        } else {
          setSuccess(false);
          setError(true);
          setmsgError(data.message);
          setDefaultState();
        }
      } catch (error) {
        handleError(error);
      } finally {
        setQuickApplyLoading(false);
      }
    };

    reader.readAsDataURL(quickApplyValues.resume);
  };
  return (
    <div>
      <Helmet>
        <title>{openingDetails?.opening_title || "Job Details"} | Bix3</title>
        <meta
          name="description"
          content={
            openingDetails?.job_description
              ? openingDetails.job_description.slice(0, 150)
              : "Explore job opportunities on our platform."
          }
        />
        <meta
          name="keywords"
          content={
            openingDetails?.required_skills?.[0]?.split(",").join(", ") ||
            "jobs, hiring, careers"
          }
        />
        <meta
          property="og:title"
          content={openingDetails?.opening_title || "Job Details"}
        />
        <meta
          property="og:description"
          content={
            openingDetails?.job_description
              ? openingDetails.job_description.slice(0, 150)
              : "Job details and description."
          }
        />
        <meta
          property="og:url"
          content={`${DOMAIN_URL}/openings/${openingDetails?.opening_id}`}
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content={
            openingDetails?.company_logo ||
            `${DOMAIN_URL}/media/logos/bluebix-logo.png`
          }
        />
        <meta
          name="twitter:title"
          content={openingDetails?.opening_title || "Job Details"}
        />
        <meta
          name="twitter:description"
          content={
            openingDetails?.job_description
              ? openingDetails.job_description.slice(0, 150)
              : "Explore job opportunities at Bix3."
          }
        />
        {/* <meta
          name="twitter:image"
          content={
            openingDetails?.company_logo ||
            "https://bix3.app/default-twitter-image.jpg"
          }
        /> */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "JobPosting",
            title: openingDetails?.opening_title || "Job Title",
            description: openingDetails?.job_description,
            datePosted: openingDetails?.created_at,
            employmentType:
              optionEmploymentType.find(
                (type) => type.value === openingDetails?.employment_type
              )?.label || "Full-time",
            hiringOrganization: {
              "@type": "Organization",
              name: "BlueBix",
              sameAs: DOMAIN_URL,
            },
            jobLocation: {
              "@type": "Place",
              address: {
                "@type": "PostalAddress",
                addressCountry: openingDetails?.country || "Country",
              },
            },
            baseSalary: {
              "@type": "MonetaryAmount",
              currency: "USD",
              value: {
                "@type": "QuantitativeValue",
                minValue: openingDetails?.salary_range_from || 0,
                maxValue: openingDetails?.salary_range_to || 0,
                unitText: "YEAR",
              },
            },
            jobBenefits:
              openingDetails?.benefits || "Health insurance, Remote options",
          })}
        </script>
      </Helmet>

      {showError(success, msgSuccess, error, msgError)}
      <Row gutter={[24, 24]} style={{ padding: "20px" }}>
        <Col span={24}>
          <Card
            bordered={false}
            style={{
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Breadcrumb
              itemRender={(route, _, routes, paths) => {
                const last = routes.indexOf(route) === routes.length - 1;
                const path = route.path ? route.path : paths.join("/");
                return last ? (
                  <span>{route.breadcrumbName}</span>
                ) : (
                  <NavLink to={path}>{route.breadcrumbName}</NavLink>
                );
              }}
              routes={[
                { breadcrumbName: "Home", path: "/" },
                { breadcrumbName: "Openings", path: "/openings" },
                {
                  breadcrumbName: openingDetails?.opening_title || "Job Title",
                },
              ]}
            />

            <Row
              justify="space-between"
              align="middle"
              style={{
                marginTop: "20px",
              }}
            >
              <Col>
                <Title level={3}>
                  {openingDetails?.opening_title || "Job Title"}
                </Title>
                <Text type="secondary">{daysAgoText()}</Text>
              </Col>
              <Col>
                <Space>
                  {users.token &&
                    !["admin", "bdm", "recruiter"].includes(users?.role) && (
                      <Tooltip title="Save Job">
                        <Button
                          type={
                            CandiadteDetail?.job_save ? "primary" : "default"
                          }
                          icon={<SaveOutlined />}
                          onClick={() => onSaveJob(CandiadteDetail)}
                        >
                          {CandiadteDetail?.job_save ? "Saved" : "Save"}
                        </Button>
                      </Tooltip>
                    )}
                  {!["admin", "bdm", "recruiter"].includes(users?.role) && (
                    <Tooltip title="Apply for Job">
                      <Button
                        type={
                          CandiadteDetail?.job_apply ? "primary" : "default"
                        }
                        icon={<CheckOutlined />}
                        onClick={() => onApplyJob(CandiadteDetail)}
                      >
                        {CandiadteDetail?.job_apply ? "Applied" : "Apply"}
                      </Button>
                    </Tooltip>
                  )}
                </Space>
              </Col>
            </Row>

            <Divider />

            <Row gutter={[16, 16]}>
              {(openingDetails?.city?.[0]?.city ||
                openingDetails?.state?.[0]?.state) && (
                <Col span={8}>
                  <Text>
                    <EnvironmentOutlined />{" "}
                    {openingDetails?.city?.[0]?.city
                      ? `${openingDetails.city[0].city}${
                          openingDetails?.state?.[0]?.state
                            ? ", " + openingDetails.state[0].state
                            : ""
                        }`
                      : openingDetails?.state?.[0]?.state || ""}
                  </Text>
                </Col>
              )}
              <Col span={8}>
                <Text>
                  Experience: {openingDetails?.required_experience || "N/A"} Yrs
                </Text>
              </Col>
              <Col span={8}>
                <Text>
                  Salary:{" "}
                  {openingDetails?.salary_range_from &&
                  openingDetails?.salary_range_to
                    ? `$${openingDetails.salary_range_from} - $${openingDetails.salary_range_to}`
                    : "Not Disclosed"}
                </Text>
              </Col>
            </Row>

            <Divider />

            <Space wrap>
              <Tag color="blue">
                Interview Type:{" "}
                {optionsInterviewType.find(
                  (type) => type.value === openingDetails?.interview_type
                )?.label || "N/A"}
              </Tag>
              <Tag color="green">
                Employment Type:{" "}
                {optionEmploymentType.find(
                  (type) => type.value === openingDetails?.employment_type
                )?.label || "N/A"}
              </Tag>
              <Tag color="orange">
                Experience Level:{" "}
                {optionsexperienceLevel.find(
                  (type) => type.value === openingDetails?.experience_level
                )?.label || "N/A"}
              </Tag>
            </Space>

            <Divider />

            <Row>
              <Col span={24}>
                <div dangerouslySetInnerHTML={createMarkup()} />
              </Col>
            </Row>

            <Divider />

            <Row>
              <Col span={24}>
                <Title level={5}>Key Skills</Title>
                <Space wrap>
                  {openingDetails?.required_skills?.map((item, index) => (
                    <Tag key={index}>{item.trim()}</Tag>
                  ))}
                </Space>
              </Col>
            </Row>
          </Card>
        </Col>

        <Modal
          title="Apply for Job"
          open={isModalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          footer={[
            <Button key="cancel" onClick={handleModalCancel}>
              Cancel
            </Button>,
          ]}
        >
          <div>
            <h6>You're almost there!</h6>
            <p>How would you like to apply?</p>
          </div>
          <div className="d-flex flex-column mt-10">
            <Row>
              <Button
                style={{
                  width: "100%",
                }}
                type="primary"
                onClick={handleModalSignIn}
              >
                Sign into My Account
              </Button>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Button
                style={{
                  width: "100%",
                }}
                type="primary"
                onClick={handleModalOk}
              >
                Create a New Account
              </Button>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Button
                style={{
                  width: "100%",
                }}
                type="primary"
                onClick={handleQuickModalVisible}
              >
                Quick Apply (No Account Needed)
              </Button>
            </Row>
          </div>
        </Modal>
        <Modal
          title="Quick Apply"
          className="quick-apply-modal"
          open={isQuickApplyVisible}
          onCancel={handleQuickApplyClose}
          closeIcon={<ChevronLeft />}
          footer={[
            <Button key="cancel" onClick={handleQuickApplyClose}>
              Cancel
            </Button>,
          ]}
        >
          <div>
            {" "}
            <p>
              Kindly provide your basic details to proceed with the job
              application.
            </p>
          </div>
          <div className="d-flex flex-column mt-10">
            <Row>
              <label htmlFor="first_name">
                First Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                onChange={handleQuickApplyChange}
                value={quickApplyValues.first_name}
                name="first_name"
                id="first_name"
              />
            </Row>{" "}
            <Row style={{ marginTop: "10px" }}>
              <label htmlFor="last_name">
                Last Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Last Name"
                value={quickApplyValues.last_name}
                onChange={handleQuickApplyChange}
                name="last_name"
                id="last_name"
              />
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <label htmlFor="email">
                Email Address <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Email Address"
                onChange={handleQuickApplyChange}
                value={quickApplyValues.email}
                name="email"
                id="email"
              />
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <label htmlFor="phone">Phone Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Phone Number"
                value={quickApplyValues.phone}
                onChange={handleQuickApplyChange}
                name="phone"
                id="phone"
              />
            </Row>
            <Col style={{ marginTop: "10px", padding: 0 }}>
              <label htmlFor="upload">
                Resume (PDF, DOC, DOCX) <span style={{ color: "red" }}>*</span>
              </label>
              <div>
                <Upload
                  maxCount={1}
                  name="resume"
                  fileList={
                    quickApplyValues.resume ? [quickApplyValues.resume] : []
                  }
                  accept=".pdf,.doc,.docx"
                  beforeUpload={(file) => {
                    const allowedTypes = [
                      "application/pdf",
                      "application/msword",
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    ];
                    if (!allowedTypes.includes(file.type)) {
                      message.error("Only PDF, DOC, DOCX files are allowed.");
                      return false;
                    }
                    if (file.size > 10000000) {
                      message.error("File size should not exceed 10MB.");
                      return false;
                    }
                    handleQuickApplyChange({
                      target: { name: "resume", value: file },
                    });
                  }}
                  onRemove={() => {
                    handleQuickApplyChange({
                      target: { name: "resume", value: null },
                    });
                  }}
                >
                  <Button type="primary" icon={<UploadOutlined />}>
                    Upload
                  </Button>
                </Upload>
              </div>
            </Col>
            <Row style={{ marginTop: "20px" }}>
              <Button
                style={{
                  width: "100%",
                }}
                type="primary"
                onClick={handleQuickApply}
                loading={quickApplyLoading}
                disabled={quickApplyLoading}
              >
                Apply
              </Button>
            </Row>
          </div>
        </Modal>
      </Row>
    </div>
  );
}
